import React, {Component} from 'react'
import KpiDetail from "../../../components/OA/KpiDetail";
import {withRouter} from "react-router-dom";
class MyKpiDetail extends Component {
    state = {

    }
    componentDidMount(){
        console.log(this.props.history)
    }
    render() {
        return (
            <>
                <KpiDetail/>
            </>
        )
    }
}
export default withRouter(MyKpiDetail)