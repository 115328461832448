import React, {Component,useState} from 'react'
import {Modal, Avatar, Tooltip, message,Popover,Button,Form,Input,Select,Upload} from 'antd';
import {Redirect, Route, Switch, withRouter,useRouteMatch,useLocation} from 'react-router-dom'
import {envHttpUrl, getmd5} from '../../http/globalMethod'
import {getFeedbackDicList, saveFeedbackData, saveCollectData, loginOutRequest} from '../../request'
import './index.less'
import * as Icon from "@ant-design/icons";
import IconFun from '../../public/icon'
import SuggestModal from "../OA/suggestModal";

class PortalActions extends Component {
    formCollectRef = React.createRef()
    suggestModalRef = React.createRef()
    state = {
        collectModal:false,
        formCollectParam:{
            id:'',
            pagePath:window.location.href,
            pageTitle:'AMS系统',
        },
        form:null
    }
    handleOpenModal = ()=>{
        this.suggestModalRef.current.handleOpenModal()
    }
    handleOpenCollectModal = ()=>{
        this.setState({
            collectModal:true
        })

    }
    handleCloseModal = ()=>{
        this.formCollectRef.current.resetFields()
        this.setState({
            collectModal:false,
        })

    }
    handleSubmitCollect = (val)=>{
        console.log(val,this.state.formCollectParam)
        let param = this.state.formCollectParam
        param.pageTitle = val.pageTitle||'AMS首页'
        saveCollectData(param).then(res=>{

        })
        this.handleCloseModal()

    }

    componentDidMount(){
        console.log('current', this.props);
        this.props.history.listen((location,action,c)=>{
            console.log('location:', location);
            console.log('action:', action);
        })
    }
    render() {
        const {collectModal,formCollectParam} = this.state

        return (
            <>
                <div className="portal-actions">
                    <div className="portal-actions-wrap">
                        <Tooltip placement="left" title="收藏页面">
                            <Avatar onClick={ ()=>this.handleOpenCollectModal()} className="actions-icon" size={34} icon={<Icon.StarOutlined />} />
                        </Tooltip>
                        <Tooltip placement="left" title="反馈及建议">
                            <Avatar onClick={ ()=>this.handleOpenModal()} className="actions-icon" size={34} icon={<Icon.CommentOutlined />} />
                        </Tooltip>

                    </div>
                </div>
                <SuggestModal ref={this.suggestModalRef} />

                <Modal destroyOnClose forceRender title="页面收藏" open={collectModal} footer={false} onCancel={this.handleCloseModal}>
                    <Form
                        ref={this.formCollectRef}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        onFinish={this.handleSubmitCollect}
                    >
                        <Form.Item label="自定义收藏标题" name="pageTitle">
                            <Input placeholder="自定义收藏标题"/>
                        </Form.Item>
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>


        )
    }
}
export default withRouter(PortalActions)
