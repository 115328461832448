import React,{Component} from 'react'
import {Route,Switch,Redirect,withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {adminRouter, oaRouter} from '../../routes'
import {Frame, Header} from '../../components'
import {Layout} from "antd";
const { Content } = Layout;

const mapState = (state)=>{
    return({
        isLogin:state.users.isLogin
    })
}
@connect(mapState)
class OA extends Component {
    render(){
        return (
            <>
                <Frame title={'OA系统'} sys={'OA'} sysLink={'oa'}>
                    <Switch>
                        {
                            oaRouter.map(route =>{
                                return <Route location={{...route}} key={route.pathname} path={route.pathname} component={route.component} exact={route.exact||false}></Route>
                            })
                        }
                        <Redirect to={oaRouter[0].pathname} from="/oa" exact />

                    </Switch>

                </Frame>

            </>

        )
    }
}

export default OA