import React, {Component} from 'react'
import {getmd5} from '../../http/globalMethod'
import {updateKettlePassWord} from '../../request'
import {Button, Card, Form, Input, notification} from 'antd';
const locationQuery = new URLSearchParams(window.location.search)
class KettlePassword extends Component {
    formRef = React.createRef();
    state = {
        account:locationQuery.get('account')
    }

    submitPassword = (value)=>{
        updateKettlePassWord({
            account: this.state.account,
            oldPassword:value.oldPassword,
            newPassword:value.password,
            verifyPassword:value.verifyPassword,
        }).then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.formRef.current.resetFields();
        })
    }
    validatorConfirmPassword = (rule, value)=>{
        console.log(rule,value,this.formRef.current)
        return Promise.reject('eeee')
    }
    render() {
        return (
            <div style={{maxWidth:'1000px',margin:'0 auto',padding:'30px 20px'}}>
                <Card title={
                    <div style={{textAlign:'center'}}>您好，请为您的账号 「<span style={{color:'#1890ff',fontWeight:'bold'}}>{this.state.account}</span>」 修改密码</div>
                } style={{ boxShadow: '0 2px 12px 0 rgba(0, 0, 0, .1)'}}>
                    <Form labelCol={{span: 4}} ref={this.formRef} name="sys_user_search" onFinish={this.submitPassword}>
                        <Form.Item
                            label="原密码"
                            name="oldPassword"
                            rules={[{ required: true, message: '请输入原密码' }]}
                        >
                            <Input.Password placeholder="输入原始密码" allowClear/>
                        </Form.Item>
                        <Form.Item
                            label="新密码"
                            name="password"
                            rules={[{ required: true, message: '请输入新密码' }]}
                        >
                            <Input.Password
                                placeholder="输入新密码" allowClear/>
                        </Form.Item>
                        <Form.Item
                            label="确认新密码"
                            name="verifyPassword"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: '请确认新密码!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('两次输入的密码不一致!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                placeholder="请确认新密码" allowClear/>
                        </Form.Item>
                        <Form.Item shouldUpdate={true}>
                            <Button type="danger"  style={{margin:'20px auto',display:'block'}} htmlType="submit">提交修改</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        )
    }
}
export default KettlePassword