import React, {Component,useState} from 'react'
import {Modal, Button,Form,Input,Select} from 'antd';
import {getFeedbackDicList, saveFeedbackData} from '../../request'


class SuggestModal extends Component {
    formRef = React.createRef()
    state = {
        suggestModal:false,
        feedbackType:[],
        severityType:[],
        formParam:{
            id:'',
            pagePath:window.location.href,
            pageTitle:'AMS系统',
            status:1,
            replyComment:'',
            imageList:''
        },
        form:null
    }
    handleOpenModal = ()=>{
        this.setState({
            suggestModal:true
        })
        getFeedbackDicList().then(res=>{
            this.setState({
                feedbackType:res.feedbackType,
                severityType:res.severityType,
            })
        })
    }
    handleCloseModal = ()=>{
        console.log(this.formRef)
        this.formRef.current.resetFields()
        this.setState({
            suggestModal:false,
        })

    }
    handleSubmitSuggest = (val)=>{
        console.log(val,this.state.formParam)
        let param = {
            ...this.state.formParam,
            ...val
        }
        saveFeedbackData(param).then(res=>{

        })
        this.handleCloseModal()

    }

    componentDidMount(){

    }
    render() {
        const {suggestModal} = this.state

        return (
            <>
                <Modal destroyOnClose forceRender title="反馈及建议" open={suggestModal} footer={false} onCancel={this.handleCloseModal}>
                    <Form
                        ref={this.formRef}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        onFinish={this.handleSubmitSuggest}
                    >
                        <Form.Item label="标题" name="feedbackTitle">
                            <Input placeholder="标题"/>
                        </Form.Item>
                        <Form.Item label="内容" name="feedbackDescription">
                            <Input.TextArea placeholder="详细描述内容"/>
                        </Form.Item>
                        <Form.Item label="反馈类型" name="feedbackType">
                            <Select
                                allowClear
                                style={{minWidth: '200px'}}
                                showSearch
                                placeholder="反馈类型"
                                fieldNames={{label: 'name', value: 'value'}}
                                filterOption={(input, option) =>
                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.state.feedbackType}
                            />
                        </Form.Item>
                        <Form.Item label="反馈等级" name="severity">
                            <Select
                                allowClear
                                style={{minWidth: '200px'}}
                                showSearch
                                placeholder="反馈等级"
                                fieldNames={{label: 'name', value: 'value'}}
                                filterOption={(input, option) =>
                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.state.severityType}
                            />
                        </Form.Item>
                        {/*<Form.Item label="上传" valuePropName="fileList">*/}
                        {/*    <Upload action="/upload.do" listType="picture-card">*/}
                        {/*        <div>*/}
                        {/*            <Icon.PlusOutlined />*/}
                        {/*            <div style={{ marginTop: 8 }}>上传图片/视频</div>*/}
                        {/*        </div>*/}
                        {/*    </Upload>*/}
                        {/*</Form.Item>*/}
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>


        )
    }
}
export default SuggestModal
