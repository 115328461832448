import React, {Component} from 'react'
import {
    Card,
    Table,
    Button,
    message,
    Tag, Space,
    Drawer,
    Row, Col, Form, Menu, Select, Modal, Input, InputNumber
} from 'antd';
import {
    get360DicList,
    save360ClassList,
    del360ClassUser,
    get360ClassList,
    get360SurveyQaList,
    send360survey,
    getEvalPeriodList,getEvalPeriodLogList
} from '../../request'
import TransferModal from '../../components/OA/TransferModal'
import ConnectSurveyModal from '../../components/OA/ConnectSurveyModal'
import ClassLog from '../../components/OA/ClassLog'
import {withRouter} from "react-router-dom";
let columsKey = {
    index:"序号",
    name: '分类名',
    periodType: '期次类型',
    periodName: '当前期次',
    addType: '员工类型',
    selfEvalEnable: '自评是否计分',
    distributeType: '问卷下发',
    expireDays: '问卷有效天数',
    // linkScoreQuestion: '关联计分问题',
    status:'状态',
    actions: '操作',
}
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};
class Class360 extends Component {
    employeeModal = React.createRef()
    surveyModal = React.createRef()
    classLogModal = React.createRef()
    formRef = React.createRef()
    state = {
        dataSource:[],
        dicList:{},
        surveyQaList:[],
        periodList:[],
        columns:[],
        loading:false,
        visible:false,
        systemItem:{},
        formParam:{
            id:null,
            name:null
        },
        total:0,
        formDataParam:{
            name:''
        },
        detailDrawerVisible:false,
        sendDialogVisible:false,
        editParam:{},
        selClassId:null
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='index'){
                return {
                    ...initObj,
                    align:'center',
                    width:50,
                    render:(text,record,index)=>{
                        return index+1
                    }
                }
            }else if(item=='selfEvalEnable'||item=='addType'||item=='periodType') {
                return {
                    ...initObj,
                    align: 'center',
                    render: (text, record, index) => {
                        return (
                            <>
                                {this.showDicLabel(text,item)}
                            </>

                        )
                    }
                }
            }else if(item=='status'){
                return {
                    ...initObj,
                    align: 'center',
                    render:(text,record,index)=>{
                        return(
                            text==1?
                            <Tag color="cyan">有效</Tag>
                                :
                            <Tag color="red">无效</Tag>
                        )
                    }
                }
            }else if(item=='distributeType'){
                return {
                    ...initObj,
                    align: 'center',
                    render:(text,record,index)=> {
                        return (
                            <>
                                {/*{this.showDicLabel(text, item)}*/}
                                {
                                    text == 1 ?
                                        <>
                                            手动发送
                                            <div><Tag color={'pink'} style={{cursor: 'pointer'}}
                                                      onClick={() => (this.sendSurvey(record))}>点击手工发</Tag></div>
                                        </>
                                        :
                                        '自动发送'
                                }
                            </>
                        )
                    }
                }
            }else if(item=='linkScoreQuestion'){
                return {
                    ...initObj,
                    align: 'center',
                    render:(text,record,index)=>{
                        console.log(text)
                        return(
                            text?text.join(','):''
                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 200,
                    render: (text, record, index) => {
                        return (
                            <>

                                <Button type="link" size="small" onClick={() => (this.editItem(record))}>编辑</Button>
                                <Button type="link" size="small" onClick={() => (this.openSetting(record))}>设置人员</Button>
                                <Button type="link" size="small" onClick={() => (this.openSurvey(record))}>问卷</Button>
                                <Button type="link" size="small" onClick={() => (this.openLog(record))}>日志</Button>

                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        console.log(this.state.formParam)
        get360ClassList(this.state.formParam).then(res=>{
            this.setState({
                loading: false,
                dataSource:res.map(item=>{
                    item.linkScoreQuestion = item.linkScoreQuestion?item.linkScoreQuestion?.split(','):undefined
                    return item
                }),
            })
        })
    }
    initDicDataList(){
        get360DicList({}).then(res=>{
            this.initTableDataList()
            this.setState({
                dicList:res
            })
        })
    }
    initSurveyQaDataList(){
        get360SurveyQaList({}).then(res=>{
            this.setState({
                surveyQaList:res
            })
        })
    }
    initEvalPeriodList = (values)=>{
        getEvalPeriodList({type:0,classId:values.id}).
        then(res=>{
            this.setState({
                periodList:[...res]
            })
        })
    }
    showDicLabel = (id,type)=>{
        let dicList = this.state.dicList
        let showLabel = '';
        if(type=='selfEvalEnable') {
            type = 'yesNoType'
        }
        (dicList[type]||[]).map(item=>{
            if(id == item.id){
                showLabel = item.name
            }
        })
        return showLabel||''
    }
    editItem = (item)=>{
        this.setState({
            detailDrawerVisible:true,
            editParam:item
        })
    }
    openSetting = (values,tab)=>{
        console.log(values,this.employeeModal)
        this.employeeModal.current.handleModalOpen({...values,departmentShow:values.addType==1?false:true})
    }
    openSurvey = (values,tab)=>{
        console.log(values,this.employeeModal)
        this.surveyModal.current.handleModalOpen(values)
    }
    openLog = (values,tab)=>{
        console.log(values,this.classLogModal)
        this.classLogModal.current.openClassLog(values)
        // this.surveyModal.current.handleModalOpen(values)

    }

    delItem = (record)=>(
        Modal.confirm({
            title:'提示',
            content:'确定要删除这条数据么',
            onOk:()=>{
                del360ClassUser({id:record.id}).then(res=>{
                    message.success('成功')
                    this.initTableDataList()
                })
            },
            onCancel:()=>{

            }

        })

    )
    handleValueChange =(changedValues, allValues)=>{
        console.log(changedValues,allValues)
        let param = this.state.editParam
        let changeKey = Object.keys(changedValues)[0]
        param[changeKey] = allValues[changeKey]
        this.setState({
            editParam:{
                ...param
            }
        })
    }
    submitItem = ()=>{
        let param = this.state.editParam
        param.linkScoreQuestion = param.linkScoreQuestion?param.linkScoreQuestion.join(','):null
        save360ClassList({...param}).then(res=>{
            message.success('保存成功')
            this.initTableDataList()
            this.onDrawerClose()
        })
    }
    onDrawerClose = ()=>{
        this.setState({
            editParam:{},
            detailDrawerVisible:false,
            sendDialogVisible:false,
        })
    }
    sendSurvey = (values,tab)=>{
        console.log(values,this.employeeModal)
        this.initEvalPeriodList(values)
        this.setState({
            selClassId:values.id,
            sendDialogVisible:true,
        })

    }
    handleSendSubmit = (val)=>{
        console.log('123',val,this.state.selClassId)
        let periodId = val.periodId
        if(periodId){
            send360survey({classId:this.state.selClassId,periodId:periodId}).then(res=>{
                message.success(res||'发送成功')
                this.setState({
                    selClassId:null,
                    sendDialogVisible:false,
                })
            })
        }else{
            message.warning('期次不能为空')
        }

    }
    componentDidMount(){
        console.log(this.props.history)
        this.createColumns(columsKey);
        this.initDicDataList()
        this.initSurveyQaDataList()
    }
    render() {
        const  locationQuery = new URLSearchParams(this.props.location.search)
        const defaultName = locationQuery.get('name')?locationQuery.get('name'):'';
        const { dataSource,columns, loading,detailDrawerVisible,dicList,editParam,sendDialogVisible} = this.state;

        return (
            <>
                <Card title={'360分类管理'} bordered={false} className={'card-fixed-head-wrap'}>
                    {/*<div style={{display:"flex",justifyContent:'flex-start'}}>*/}
                    {/*    <Button onClick={()=>{*/}
                    {/*        this.props.history.push({*/}
                    {/*            pathname:`/oa/setscore`,*/}
                    {/*        })*/}
                    {/*    }} type="primary" style={{marginBottom:'20px'}} danger>360评估配置</Button>*/}
                    {/*</div>*/}
                    <Table
                        bordered={true}
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}/>
                </Card>
                <TransferModal ref={this.employeeModal}  handleCloseModal={(val)=>console.log(val)}/>
                <ConnectSurveyModal ref={this.surveyModal}/>
                <ClassLog ref={this.classLogModal}/>

                <Modal destroyOnClose
                       forceRender
                       title={"选择发送的期次"}
                       open={sendDialogVisible} footer={false}
                       onCancel={this.onDrawerClose}>
                    <Form
                        ref={this.formRef}
                        layout="horizontal"
                        onFinish={this.handleSendSubmit}
                    >
                        <Form.Item name="periodId">
                            <Select
                                style={{minWidth: '200px'}}
                                showSearch
                                optionFilterProp="children"
                                fieldNames={{
                                    label: 'periodName',
                                    value: 'periodId'
                                }}
                                filterOption={(input, option) => {
                                    let searchText = (option?.periodName ?? '') + (option?.pinyin ?? '')
                                    return searchText.toLowerCase().includes(input.toLowerCase())
                                }
                                }
                                allowClear
                                placeholder="请选择期次"
                                options={this.state.periodList}
                                onChange={this.handleUserChange}
                            />
                        </Form.Item>
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                            <Button type="primary" htmlType="submit">
                                发送
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <Drawer
                    destroyOnClose
                    title="新增/编辑"
                    width={720}
                    onClose={this.onDrawerClose}
                    open={detailDrawerVisible}
                    extra={
                        <Space>
                            <Button onClick={this.onDrawerClose}>取消</Button>
                            <Button onClick={this.submitItem} type="primary">
                                保存
                            </Button>
                        </Space>
                    }
                >
                    <Form {...layout}
                          initialValues={editParam}
                          onValuesChange={this.handleValueChange}
                    >
                                <Form.Item
                                    name="name"
                                    label="分类名"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入分类名',
                                        },
                                    ]}
                                >
                                    <Input placeholder="分类名" />
                                </Form.Item>
                                <Form.Item
                                    name="periodType"
                                    label="期次类型"
                                    rules={[
                                        {
                                            required: true,
                                            message: '期次类型',
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{width:'100%'}}
                                        allowClear
                                        showSearch
                                        placeholder="期次类型"
                                        optionFilterProp="children"
                                        fieldNames={{label: 'name', value: 'id'}}
                                        filterOption={(input, option) =>
                                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={dicList['periodType']}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="addType"
                                    label="员工类型"
                                    rules={[
                                        {
                                            required: true,
                                            message: '员工类型',
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{width:'100%'}}
                                        allowClear
                                        showSearch
                                        placeholder="员工类型"
                                        optionFilterProp="children"
                                        fieldNames={{label: 'name', value: 'id'}}
                                        filterOption={(input, option) =>
                                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={dicList['addType']}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="distributeType"
                                    label="问卷下发"
                                    rules={[
                                        {
                                            required: true,
                                            message: '问卷下发',
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{width:'100%'}}
                                        allowClear
                                        showSearch
                                        placeholder="问卷下发"
                                        optionFilterProp="children"
                                        fieldNames={{label: 'name', value: 'id'}}
                                        filterOption={(input, option) =>
                                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={dicList['distributeType']}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="selfEvalEnable"
                                    label="自评是否计分"
                                    rules={[
                                        {
                                            required: true,
                                            message: '自评是否计分',
                                        },
                                    ]}
                                    extra={<span style={{fontSize:'10px',color:'#e50011'}}>* 自己给自己评分时是否计入总分，如果是【否】评分汇总表显示【自评无效】</span>}
                                >
                                    <Select
                                        style={{width:'100%'}}
                                        allowClear
                                        showSearch
                                        placeholder="自评是否计分"
                                        optionFilterProp="children"
                                        fieldNames={{label: 'name', value: 'id'}}
                                        filterOption={(input, option) =>
                                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={dicList['yesNoType']}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="linkScoreQuestion"
                                    label="关联计分问题"
                                    extra={<span style={{fontSize:'10px',color:'#e50011'}}>* 当一个员工是新人同时又是中后台的时候，360评分只发放新人问卷，中后台的得分自动取新人问卷的得分，但是有些题是不取的，这里需要配置哪些题要取分数</span>}

                                >
                                    <Select
                                        mode={'multiple'}
                                        style={{width:'100%'}}
                                        allowClear
                                        showSearch
                                        placeholder="选择问卷"
                                        optionFilterProp="children"
                                        fieldNames={{label: 'name', value: 'id'}}
                                        filterOption={(input, option) =>
                                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={this.state.surveyQaList}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="expireDays"
                                    label="问卷有效天数"
                                    rules={[
                                        {
                                            required: true,
                                            message: '问卷有效天数',
                                        },
                                    ]}
                                >
                                    <InputNumber />
                                </Form.Item>
                    </Form>
                </Drawer>
            </>

        )
    }
}
export default withRouter(Class360)