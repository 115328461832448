import React, {Component} from 'react'
import {Result,Button} from 'antd'
import {envHttpUrl} from '../../http/globalMethod'

export default class NotFound extends Component {
    render() {
        return (
            <div>
                <Result
                    status="404"
                    title="404"
                    subTitle="抱歉，没有找到相关页面"
                    extra={<Button type="primary" onClick={()=>{
                        window.location.href = `${window.location.protocol}${envHttpUrl().portalUrl}`
                    }}>返回首页</Button>}
                />
            </div>
        )
    }
}