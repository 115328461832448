import React, {Component} from 'react'
import {
    Card,
    Table,
    Button,
    message,
    Tag, Dropdown,
    Pagination, Form,  Modal, Input
} from 'antd';
import {getSurveyProjectList, saveSurveyProject, delSurveyProject, copySurveyProject} from '../../request'
import IconFont from '../../public/iconfont/iconFont'
import {withRouter} from "react-router-dom";
let columsKey = {
    name: '项目名称',
    status:'项目状态',
    surveyName:'问卷名称',
    updateAt:'更新时间',
    actions: '操作',
}
class SurveyList extends Component {
    formRef = React.createRef()
    state = {
        dataSource:[],
        folderList:[],
        columns:[],
        loading:false,
        visible:false,
        systemItem:{},
        formParam:{
            currentPage:1,
            pageSize:20,
            parentId:this.props.match.params.id?this.props.match.params.id:0
        },
        total:0,
        formDataParam:{
            name:''
        },
        folderDialogVisible:false
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='name'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            <div style={{display:'flex',cursor:'pointer'}} onClick={()=>(this.openFolder(record))}>
                                {record.mode == 'folder' ?
                                    <IconFont style={{marginRight:'5px',fontSize:'20px'}} type={'icon-Openedfolder'} />
                                    : ''}
                                <span style={{textDecoration:'underline'}}>{text}</span>
                            </div>

                        )
                    }
                }
            }else if(item=='status'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            text==1?
                            <Tag color="cyan">已发布</Tag>
                                :
                            <Tag color="red">未发布</Tag>
                        )
                    }
                }
            }else if(item=='surveyName'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            record.survey?record.survey.title:''
                        )
                    }
                }
            }else if(item=='updateAt'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            text||record.createAt
                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 180,
                    render: (text, record, index) => {
                        return (
                            <>
                                {
                                    record.mode == 'folder' ?
                                        <>
                                            {/*<Button type="link" size="small" onClick={()=>(this.openFolder(record))}>打开</Button>*/}
                                            <Button type="link" size="small"
                                                    onClick={() => (this.editNameDialog(record))}>重命名</Button>
                                            <Button type="link" size="small"
                                                    onClick={() => (this.delSurveyData(record))}>删除</Button>
                                        </>
                                        :
                                        <>
                                            <Button type="link" size="small"
                                                    onClick={() => (this.openFolder(record))}>查看</Button>
                                            <Button type="link" size="small"
                                                    onClick={() => (this.editNameDialog(record))}>重命名</Button>
                                            {/*<Button type="link" size="small"*/}
                                            {/*        onClick={() => (this.openFolder(record))}>移动到</Button>*/}
                                            <Dropdown
                                                // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                                menu={
                                                    {
                                                        items: [
                                                            {
                                                                key: 1,
                                                                label: '预览',
                                                            },
                                                            {
                                                                key: 4,
                                                                label: record.status==0?'发布':'停止',
                                                            },{
                                                                key: 5,
                                                                label: '复制问卷',
                                                            },{
                                                                key: 2, label: '移动到', children: this.state.folderList
                                                            },
                                                            {
                                                                key: 3,
                                                                label: '删除',
                                                            },

                                                        ],
                                                        onClick: (val) => this.handleAction(val,record)
                                                    }
                                                } trigger={'click'}>
                                                <Button type="link" size="small">...</Button>
                                            </Dropdown>

                                        </>

                                }
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        console.log(this.state.formParam)
        getSurveyProjectList(this.state.formParam).then(res=>{
            this.setState({
                loading: false,
                dataSource:res.list,
                total:res.total
            })
        })
        //获取文件列表
        getSurveyProjectList({mode:'folder'}).then(res=>{
            let folderList = [{key:'0',label:'根目录'}]
            let folderData = res.list.map(item=>{
                item.key = item.id
                item.label = item.name
                return {key:item.id,label:item.name}
            })
            this.setState({
                folderList:[...folderList,...folderData]
            })
        })
    }
    editItemParam = (values)=>{
        console.log(values)
        this.props.history.push({
            pathname:`/oa/surveylist/${values.id||0}`,
            search:'name='+(values.name||'新建问卷模板')
        })
    }
    playgroundItemParam = (values)=>{
        console.log(values)
        this.props.history.push({
            pathname:`/oa/formplayground/${values.id||0}`
        })
    }
    openFolder = (values)=>{
        console.log(values)
        if(values.mode=='folder'){
            this.props.history.push({
                pathname:`/oa/surveylist/${values.id}`,
                search:'name='+values.name
            })
        }else{
            this.props.history.push({
                pathname:`/oa/surveylist/${values.parentId||this.state.formParam.parentId||0}/${values.id||0}`,
                // search:'name='+(values.name||'新建问卷模板')
            })
        }

    }
    editNameDialog = (val)=>{
        this.formRef.current.setFieldsValue({...val})
        this.setState({
            folderDialogVisible:true,
            formDataParam:{...val}
        })

    }
    delSurveyData = (record)=> {
        Modal.confirm({
            title: '提示',
            content: '确定要删除这条数据么',
            onOk: () => {
                delSurveyProject({id: record.id}).then(res => {
                    message.success('成功')
                    this.initTableDataList()
                })
            },
            onCancel: () => {

            }

        })

    }
    handleAction = ({ key, keyPath },record)=> {
        console.log(key, keyPath,record)
        if(key==1){//预览
            this.playgroundItemParam(record)
        }else if(key==3){//删除
            this.delSurveyData(record)

        }else if(key==4){//发布or禁止
            this.handleSubmitFolder({...record,status:record.status==1?0:1})
        }else if(key==5){//复制问卷

            this.copySurvey({...record})
        }else{//移动文件
            this.moveSurvey(key,record)
        }
    }
    moveSurvey = (pId,record)=>{
        this.handleSubmitFolder({...record,parentId:pId})
    }

    copySurvey = (record)=>{
        copySurveyProject({ projectId:record.id,name:record.name+'_复制'}).then(res=>{
            message.success('复制成功')
            this.initTableDataList()
        })
    }

    handleSubmitFolder = (val)=>{
        console.log(val)
        let param = {...this.state.formDataParam,...val}
        console.log(param)
        param.parentId = this.state.formParam.parentId
        saveSurveyProject(param).then(res=>{
            message.success('成功')
            this.handleCloseModal()

            if(param.mode=='survey'&&!param.id){
                this.props.history.push({
                    pathname:`/oa/surveylist/${res.parentId||this.state.formParam.parentId||0}/${res.id||0}`,
                    // search:'name='+(values.name||'新建问卷模板')
                })
            }else{
                this.initTableDataList()
            }

        })

    }
    handleCloseModal = ()=>{
        console.log(this.formRef)
        this.formRef.current.resetFields()
        this.setState({
            folderDialogVisible:false,
            formDataParam:{}
        })
    }
    componentDidMount(){
        console.log(this.props.history)
        this.createColumns(columsKey);
        this.initTableDataList()
    }
    // componentDidUpdate(prevProps) {
    //     if (this.props.location !== prevProps.location) {
    //         console.log('路由变化了！', this.props,this.props.match.params.id);
    //         this.createColumns(columsKey);
    //         this.initTableDataList()
    //     }
    // }
    render() {
        const  locationQuery = new URLSearchParams(this.props.location.search)
        const defaultName = locationQuery.get('name')?locationQuery.get('name'):'';
        const { dataSource,columns, loading,folderDialogVisible,systemItem,formParam} = this.state;

        return (
            <>
                <Card title={
                    <>
                        {
                            defaultName?
                                <>
                                    <span style={{color:'#999999',cursor:'pointer'}} onClick={()=>this.props.history.go(-1)}>问卷模板</span>
                                    <span> / {defaultName}</span>
                                </>
                                :<span>问卷模板</span>
                        }
                    </>
                } bordered={false} className={'card-fixed-head-wrap'}>
                    <div style={{display:"flex",justifyContent:'flex-end'}}>
                        <Dropdown getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        menu={
                            {
                                items:[
                                    {key:1,label:'新建文件夹',onClick:()=>this.editNameDialog({mode:'folder',name:'',id:null}),disabled:defaultName?true:false},
                                    {key:2,label:'新建项目',onClick:()=>this.editNameDialog({mode:'survey',name:'',id:null})},
                                ]
                            }
                        } trigger={'click'}>
                            <Button type="primary" style={{marginBottom:'20px'}}>+ 新建</Button>
                        </Dropdown>
                    </div>
                    <Table
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}/>
                    <div style={{display:'flex',justifyContent:'flex-end',margin:'20px 0'}}>
                        <Pagination size="small"
                                    hideOnSinglePage={true}
                                    current={this.state.formParam.currentPage}
                                    pageSize={this.state.formParam.pageSize}
                                    total={this.state.total}
                                    showSizeChanger
                                    onChange={(page, pageSize)=>{
                                        console.log(page, pageSize)
                                        let param = this.state.formParam
                                        param.pageSize = pageSize
                                        param.currentPage = page
                                        this.setState({
                                            formParam:{...param}
                                        })
                                        this.initTableDataList()
                                    }}
                        />

                    </div>
                </Card>
                <Modal destroyOnClose
                       forceRender
                       title={"新建/重命名 名称"}
                       open={folderDialogVisible} footer={false}
                       onCancel={this.handleCloseModal}>
                    <Form
                        ref={this.formRef}
                        initialValues={this.state.formDataParam}
                        layout="horizontal"
                        onFinish={this.handleSubmitFolder}
                    >
                        <Form.Item name="name">
                            <Input placeholder="请输入名称"/>
                        </Form.Item>
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </div>
                    </Form>
                </Modal>

            </>

        )
    }
}
export default withRouter(SurveyList)