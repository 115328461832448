import React, {Component} from 'react'
import {
    Card,
    Tooltip,
    Table,
    Button,
    message,
    notification,
    Select,
    Tag,
    Form,
    Input,Popover, Pagination
} from 'antd';
import {getSystemFeedbackList, saveFeedbackData} from '../../request'
import * as Icon from '@ant-design/icons';

let columsKey = {
    severity: '问题等级',
    feedbackTitle: '问题',
    feedbackDescription: '具体描述',
    replyComment: '解决方案',
    feedbackType:'反馈类型',
    pagePath:'反馈来源',
    userName: '反馈人',
    createTime: '反馈时间',
    status: '状态',
    action: '操作',
}
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 6,
        span: 18,
    },
};
export default class SetReply extends Component {
    state = {
        dataSource:[],
        columns:[],
        loading:false,
        visible:false,
        systemItem:{},
        formParam:{
            type:0,//0 查询所有 1 仅查询我的
            pageSize:20,
            currentPage:1
        }
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'center'
            }
            if(item=='pagePath'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            <Tooltip title={text} color={"pink"}>
                                <Button size={"small"} type={"link"} onClick={()=>this.copyTestCode(record.urlParam)}>{record.systemName}</Button>
                            </Tooltip>

                        )
                    }
                }
            }else if(item=='severity'){
                return {
                    ...initObj,
                    render:(text,record)=>{
                        let tagColor = {
                            1:'green',
                            2:'gold',
                            3:'red',
                        }
                        return(
                            <Tag color={tagColor[text]}>{record.severityDesc}</Tag>
                        )
                    }
                }

            }else if(item=='feedbackType'){
                return {
                    ...initObj,
                    render:(text,record)=>{
                        let tagType = {
                            1:'#cd201f',
                            2:'#55acee',
                            3:'red',
                        }
                        return(
                            <Tag color={tagType[text]}>{record.feedbackTypeDesc}</Tag>
                        )
                    }
                }

            }else if(item=='status'){
                return {
                    ...initObj,
                    width:120,
                    render:(text, record, index)=>{
                        return (
                            <>
                                {
                                    text == 2?
                                        <Tag color={'green'}>已处理</Tag>
                                        :''


                                }
                            </>
                        )
                    }
                }
            }else if(item=='action'){
                return {
                    ...initObj,
                    fixed:'right',
                    width:120,
                    render:(text, record, index)=>{
                        return (
                            <>
                                {
                                    record.status == 1?
                                        <Popover placement="left" content={
                                            <>
                                                <Form
                                                    initialValues={record}
                                                    onFinish={(value)=>this.handleSubmit(value,record)}
                                                    onValuesChange={this.formValueChange}>
                                                    <Form.Item name='replyComment' style={{marginBottom:'5px'}}>
                                                        <Input.TextArea placeholder="请输入回复文案"/>
                                                    </Form.Item>
                                                    <Form.Item style={{display:'flex',justifyContent:'flex-end',marginBottom:'5px'}}>
                                                        <Button type="primary" danger size="small" htmlType="submit">
                                                            提交
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </>
                                        } title="回复解决方案" trigger="click">
                                            <Button type="link" size="small">处理反馈</Button>
                                        </Popover>
                                        :''



                                }
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        getSystemFeedbackList(this.state.formParam).
        then(res=>{
            this.setState({
                loading: false,
                dataSource:res.list,
                total:res.total
            })
        })
    }
    handleSubmit = (values,allValues)=>{
        console.log(values,allValues)
        let param = {...allValues,...values,status:2}
        console.log(param)
        saveFeedbackData(param).then(res=>{
            message.success('成功')
            this.initTableDataList()
        })
    }
    handleCloseModel = ()=>{
        this.setState({
            visible:false,
        })
    }
    copyTestCode = (text)=>{
        console.log(text)
        var input = document.createElement("textarea"); // 创建input对象
        input.value = text; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        message.success('已复制到剪贴板');
    }
    componentDidMount(){
        this.createColumns(columsKey);
        this.initTableDataList()
    }
    render() {
        const { dataSource,columns, loading,visible,systemItem} = this.state;
        return (
            <div>
                <Card title="处理反馈/问题" bordered={false}>
                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}/>
                    <div style={{display:'flex',justifyContent:'flex-end',margin:'20px 0'}}>
                        <Pagination size="small"
                                    hideOnSinglePage={true}
                                    current={this.state.formParam.currentPage}
                                    pageSize={this.state.formParam.pageSize}
                                    total={this.state.total}
                                    showSizeChanger
                                    onChange={(page, pageSize)=>{
                                        console.log(page, pageSize)
                                        let param = this.state.formParam
                                        param.pageSize = pageSize
                                        param.currentPage = page
                                        this.setState({
                                            formParam:{...param}
                                        })
                                        this.initTableDataList()
                                    }}
                        />

                    </div>
                </Card>
            </div>

        )
    }
}