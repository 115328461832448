import React, {Component,useState} from 'react'
import {Calendar, PageHeader, Badge, Tooltip, Popover, message} from 'antd';
import './DingOpen.less'
export default class DingOpen extends Component {
    state = {
        url:window.location.href,
        showDing:false
    }
    handleCopy = (text)=>{
        console.log(text)
        var input = document.createElement("textarea"); // 创建input对象
        input.value = text; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        message.success('已复制到剪贴板');
    }
    isInDingTalk = ()=>{
        return /DingTalk|DingTalkBrowser/.test(navigator.userAgent);
    }
    componentDidMount(){
        console.log(window.location.pathname)
        let pathname = window.location.pathname
        if(this.isInDingTalk()&&pathname.indexOf('/oa/formplayground')<0){
            this.setState({
                showDing:true
            })
        }else{
            this.setState({
                showDing:false
            })
        }
    }
    render() {
        return (
            this.state.showDing?
            <div className={'ding-open-wrap'}>
                <span>复制链接到浏览器打开</span>
                <span className={'btn'} onClick={()=>this.handleCopy(this.state.url)}>复制</span>
            </div>:<></>

        )
    }
}
