import React, {Component} from 'react'
import {
    Card,
    Table,
    Button, Tabs, Spin, Pagination
} from 'antd';
import {getUserRefUsersList,get360ClassList} from '../../request'
import TransferModal from '../../components/OA/TransferModal'

import {withRouter} from "react-router-dom";

let columnsKey = {
    index:'序号',
    userName: '姓名',
    leader: 'leader',
    peers:'peers',
    subordinate:'下级',
    actions: '操作',
}
class EmployeeSys extends Component {
    employeeModal = React.createRef()

    state = {
        dataSource:[],
        columns:[],
        classList:[],
        formParam:{
            classId:1,

        },
        pagination:{
            pageSize:20,
            pageNum:1,
            total:0
        },
        loading:false
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='index'){
                return {
                    ...initObj,
                    align:'center',
                    width:50,
                    render:(text,record,index)=>{
                        return index+1
                    }
                }
            }else if(item=='leader'||item=='subordinate'||item=='peers'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            text?text.join('，'):""
                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            <>
                                {
                                    <>
                                        <Button type="link" size="small"
                                                onClick={() => (this.setPeers(record))}>设置peers</Button>

                                    </>

                                }
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        getUserRefUsersList({...this.state.formParam,...this.state.pagination}).then(res=>{
            let param = this.state.classList
            let pagination = this.state.pagination
            let setList = res.list.map(item=>{
                item.leader = item['领导']
                item.subordinate = item['下级']
                item.peers = item['协作者']
                return item
            })
            let classList = param.map(item=>{
                if(item.id==this.state.formParam.classId){
                    item.children = setList
                }
                return item
            })
            pagination.total = res.total
            this.setState({
                loading: false,
                classList,
            })
        })
    }
    initTableClassDataList(){
        this.setState({ loading: true });
        get360ClassList({}).then(res=>{
            this.setState({
                loading: false,
                classList:res
            })
        })
    }
    setPeers = (values)=>{
        console.log(values)
        this.employeeModal.current.handleModalOpen({id:this.state.formParam.classId,userId:values.userId,departmentShow:false})
    }
    handleChange = (value)=>{
        console.log(value)
        let param = this.state.formParam
        param.classId = value
        this.setState({
            formParam:param,
            pagination:{
                pageSize:20,
                pageNum:1,
                total:0
            }
        })
        this.initTableDataList()
    }
    handleCloseModal = ()=>{
        this.initTableDataList()
    }
    handlePageChange = (current,pageSize)=>{
        console.log(current,pageSize)
        let pagination = this.state.pagination
        pagination.pageSize = pageSize
        pagination.pageNum = current
        this.setState({
            pagination
        })
        this.initTableDataList()
    }
    componentDidMount(){
        console.log(this.props.history)
        this.createColumns(columnsKey);
        this.initTableClassDataList()
        this.initTableDataList()
    }
    render() {

        const { dataSource,columns, loading,pagination} = this.state;

        return (
            <>
                <Card title={<span>员工管理</span>} bordered={false} className={'card-fixed-head-wrap'}>
                    <Spin spinning={loading}>
                        <Tabs
                            defaultActiveKey="1"
                            type="card"
                            onChange={this.handleChange}
                            items={
                                this.state.classList.map(item=>{
                                    return {
                                        label:item.name,
                                        key:item.id,
                                        children:
                                        <>
                                            <Table
                                                bordered
                                                size="small"
                                                pagination={false}
                                                dataSource={item.children}
                                                columns={columns}
                                                rowKey={record=>record.userId}
                                            />
                                            <Pagination style={{float:'right',margin:'16px 0'}} hideOnSinglePage={true} size="small" current={pagination.pageNum} total={pagination.total} pageSize={pagination.pageSize} onChange={this.handlePageChange}/>

                                        </>

                                }
                                })
                            }
                        />
                    </Spin>


                    <TransferModal ref={this.employeeModal} from={'employee'} handleCloseModal={this.handleCloseModal}/>

                </Card>
            </>

        )
    }
}
export default withRouter(EmployeeSys)