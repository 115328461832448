import React, {Component} from 'react'
import {
    Card,
    Table,
    Button,
    message,
    Tag, Tooltip,
    Drawer,
    Row, Col, Form, Menu, Select, Modal, Input, Collapse, PageHeader, InputNumber, Spin, Empty, Alert, Badge
} from 'antd';
import {
    getSubordinateKpiDetail,
    saveSubordinateKpi,
    ensureSubordinateKpi,
    publishSubordinateKpi,
    delSurveyProject, clearSelfPerformanceRate,
} from '../../request'
import * as Icon from '@ant-design/icons';
import {withRouter} from "react-router-dom";
import TransferModal from "./TransferModal";
import ProcessSteps from "./processSteps"
import './kpi.less'
const { Panel } = Collapse;

class KpiDetail extends Component {
    employeeModal = React.createRef()
    surveyModal = React.createRef()
    state = {
        dataSource: [],
        dicList: {},
        SurveyQaList: [],
        columns: [],
        loading: false,
        visible: false,
        systemItem: {},
        formParam: {
            userId: this.props.id != 0 ? this.props.id : null,
        },
        total: 0,
        formDataParam: {
            name: ''
        },
        detailDrawerVisible: false,
        submitParam: {},
        userName: '',
        ensure: false
    };

    initTableDataList() {
        this.setState({loading: true});
        console.log(this.state.formParam)
        getSubordinateKpiDetail(this.state.formParam).then(res => {
            this.setState({
                loading: false,
                dataSource: res.list,
                defaultActive: res.list.map(item => item.id),
                userName: res.currentUserName || '',
                ensure: res.ensure
            })
        })
    }

    handlePushFilterValueChange = (changedValues, allValues, index) => {
        let param = this.state.dataSource
        param[index].content = allValues.content
        console.log(changedValues, allValues)
        this.setState({
            dataSource: param
        })
    }
    handleBlur = (val,index)=>{
        console.log('blur',val,index,this.state.dataSource[index])
        this.saveKpi(index)
    }
    clearSelfScore = (item,index)=>{
        let modalTitle = '确定要重新指定KPI吗？如果重新制定KPI，员工需要重新进行KPI确认及自评，请谨慎选择'
        Modal.confirm({
            title:'提示',
            content:modalTitle,
            onOk:()=>{
                clearSelfPerformanceRate({ userId:this.state.formParam.userId,periodId:item.periodId}).then(res=>{
                    message.success('成功')
                    this.initTableDataList()
                })
            },
            onCancel:()=>{

            }

        })

    }
    saveKpi = (index) => {
        saveSubordinateKpi([this.state.dataSource[index]]).then(res => {
            message.success('草稿保存成功')
            this.initTableDataList()
        })
    }
    publishKpi = (index) => {
        Modal.confirm({
            title: '提示',
            content: '确认要发布KPI吗？发布之后员工就可以开始自评，且员工自评之后无法修改KPI内容，请谨慎选择！',
            onOk: () => {
                publishSubordinateKpi({id: this.state.dataSource[index].id}).then(res => {
                    message.success('发布成功')
                    this.initTableDataList()
                })
            },
            onCancel: () => {

            }

        })


    }
    toPerformance = (index, type) => {
        let periodId = this.state.dataSource[index].periodId
        let url = type == 'self' ? '/oa/myperformance' : `/oa/subordinate/performancedetail/${this.state.formParam.userId}`
        this.props.history.push({
            pathname: url,
            search: `?periodId=${periodId}`
        })
    }
    ensureKpi = () => {
        ensureSubordinateKpi({}).then(res => {
            message.success('确认成功')
            this.initTableDataList()
        })
    }
    setPeers = () => {
        this.employeeModal.current.handleModalOpen({userId: this.state.formParam.userId, departmentShow: false})

    }

    componentDidMount() {
        this.initTableDataList()
    }

    render() {
        const locationQuery = new URLSearchParams(window.location.search)
        const periodId = locationQuery.get('periodId')
        const {dataSource, columns, loading, detailDrawerVisible, dicList, editParam} = this.state;

        return (
            <>
                <Card title={
                    this.props.id ?
                        <>
                            <span style={{marginRight: '10px', fontWeight: 'bolder', cursor: 'pointer'}}
                                  onClick={() => this.props.history.go(-1)}><Icon.ArrowLeftOutlined/></span>
                            {
                                'KPI设置：' + this.state.userName
                            }
                        </> :
                        'KPI设置：我的'
                } bordered={false}
                      className={'card-fixed-head-wrap kpi-detail-wrap'}
                      extra={
                          <>
                              {this.props.id ?
                                  <Button type={'primary'} style={{marginRight: '10px'}} danger
                                          onClick={this.setPeers}>设置360环评人员</Button>
                                  : ''
                              }
                              {
                                  this.state.ensure ?
                                      <Button type={'primary'} onClick={this.ensureKpi}>确认KPI</Button> : ''
                              }
                          </>

                      }
                >
                    <Spin spinning={loading}>
                        {
                            this.state.dataSource.length > 0 ?
                                <Collapse collapsible="header" defaultActiveKey={[periodId]} expandIconPosition={'end'}>
                                    {
                                        this.state.dataSource.map((item, listIndex) => {
                                            return (
                                                <Panel forceRender header={
                                                    <Tooltip title={'当前周期的进度为：' + item.stage} placement={'topLeft'}>
                                                        <div style={{paddingLeft: '90px'}}>

                                                            <Badge.Ribbon placement={'start'} text={item.stage}
                                                                          color="#54a6f1"/>

                                                            {item.periodName}

                                                        </div>
                                                    </Tooltip>
                                                }
                                                       extra={<ProcessSteps steps={item.stageVO}/>}
                                                       key={item.periodId}>

                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: 'flex-end',
                                                        marginBottom: "20px"
                                                    }}>
                                                        {/*{*/}
                                                        {/*    this.props.id ?*/}
                                                        {/*        <Alert message={'Leader制定完KPI之后，只有点击「发布KPI」员工才能收到。'}*/}
                                                        {/*               type="info" showIcon/>*/}
                                                        {/*        : <div></div>*/}
                                                        {/*}*/}
                                                        <div>
                                                            {
                                                                item.edit ?
                                                                    <>
                                                                        {/*<Tag style={{cursor: 'pointer'}}*/}
                                                                        {/*     onClick={() => this.saveKpi(listIndex)}*/}
                                                                        {/*     color={'pink'}>保存草稿</Tag>*/}
                                                                        <Tag style={{cursor: 'pointer'}}
                                                                             onClick={() => this.publishKpi(listIndex)}
                                                                             color={'red'}>发布KPI</Tag>
                                                                    </> : ''
                                                            }
                                                            {item.showClearSub ?
                                                                <Tag style={{cursor: 'pointer'}}
                                                                     onClick={() => this.clearSelfScore(item, listIndex)}
                                                                     color={'pink'}>重新制定</Tag>
                                                                : ''}
                                                            {
                                                                item.leaderEvaluate ?
                                                                    <Tag style={{cursor: 'pointer'}}
                                                                         color={'green'}
                                                                         onClick={() => this.toPerformance(listIndex, 'leader')}>评分</Tag> : ''
                                                            }
                                                            {
                                                                item.selfEvaluate ? <Tag color={'blue'}
                                                                                         style={{cursor: 'pointer'}}
                                                                                         onClick={() => this.toPerformance(listIndex, 'self')}>自评</Tag> : ''
                                                            }
                                                        </div>

                                                    </div>
                                                    <Form initialValues={item}
                                                          disabled={!item.edit}
                                                          onValuesChange={(changedValues, allValues) => this.handlePushFilterValueChange(changedValues, allValues, listIndex)}
                                                    >
                                                        <Form.Item hidden name='id'>
                                                            <input/>
                                                        </Form.Item>
                                                        <Form.List name='content'>
                                                            {
                                                                (fields, operation) => {
                                                                    return (
                                                                        <div>
                                                                            {(fields || []).map((field, index) => {
                                                                                return (
                                                                                    <div key={field.key}>
                                                                                        <Row align={'center'}
                                                                                             gutter={10}>
                                                                                            <Col span={17}>
                                                                                                <Form.Item
                                                                                                    labelCol={{flex: '56px'}}
                                                                                                    label={'kpi' + (index + 1)}
                                                                                                    name={[field.name, 'content']}>
                                                                                                    <Input
                                                                                                        onBlur={(val) => this.handleBlur(val, listIndex)}/>
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                            <Col span={6}>
                                                                                                <Form.Item label="权重(%)"
                                                                                                           labelCol={{flex: '58px'}}
                                                                                                           name={[field.name, 'rate']}>
                                                                                                    <InputNumber min={1}
                                                                                                                 max={100}
                                                                                                                 step={1}
                                                                                                                 onBlur={(val) => this.handleBlur(val, listIndex)}
                                                                                                    />
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                            <Col span={1}>
                                                                                                {
                                                                                                    item.edit ?
                                                                                                        <div
                                                                                                            className="close-filter"
                                                                                                            onClick={() => {
                                                                                                                operation.remove(index)
                                                                                                                this.handleBlur({}, listIndex)
                                                                                                            }}>
                                                                                                            <Icon.MinusCircleOutlined/>
                                                                                                        </div>
                                                                                                        : ''
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                            <Form.Item>
                                                                                <Button type="dashed"
                                                                                        onClick={() => operation.add()}
                                                                                        block
                                                                                        icon={<Icon.PlusOutlined/>}>
                                                                                    添加KPI
                                                                                </Button>
                                                                            </Form.Item>
                                                                        </div>
                                                                    )
                                                                }
                                                            }
                                                        </Form.List>
                                                    </Form>

                                                </Panel>
                                            )
                                        })
                                    }
                                </Collapse>
                                :
                                <Empty/>
                        }

                    </Spin>
                </Card>
                <TransferModal ref={this.employeeModal} from={'employee'} handleCloseModal={(val) => console.log(val)}/>

            </>

        )
    }
}
export default withRouter(KpiDetail)