import React, {Component} from 'react'
import {envHttpUrl} from '../../http/globalMethod'

export default class ErpSystem extends Component {
    state = {
        erpUrl:`${window.location.protocol}${envHttpUrl().erpUrl}`
    }
    componentDidMount(){
    }
    render() {
        return (
            <div style={{boxSizing:'border-box',height:'100%',paddingBottom:'10px'}} id='erp-container'>
                <iframe style={{width:'100%',height:'100%',border:'none'}} src={this.state.erpUrl}></iframe>
            </div>

        )
    }
}
