import React, {Component,useState} from 'react'
import {Space, Avatar, Tooltip, message,Popover,Button,Badge} from 'antd';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import {envHttpUrl, getmd5} from '../../http/globalMethod'
import {getBaseSystem, getNotificationsCount, getUserDetail, loginOutRequest} from '../../request'
import './index.less'
import * as Icon from "@ant-design/icons";
import IconFun from '../../public/icon'
class PortalAside extends Component {
    state = {
        count:0,
        systemData:[],
        userNick:'',
        userName:'',
        avatar:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg',
        asideMenu:[
            {
                name:'工作台',
                icon:'AppstoreOutlined',
                desc:'工作台',
                key:'/',
                routeUrl:'/',
            },{
                name:'系统',
                icon:'BlockOutlined',
                desc:'系统中心',
                key:'system',
                routeUrl:'',
            },{
                name:'消息',
                icon:'NotificationOutlined',
                desc:'消息中心',
                key:'/portal/notifications',
                routeUrl:'/portal/notifications',
            },{
                name:'日历',
                icon:'CalendarOutlined',
                desc:'日历',
                key:'/portal/calendar',
                routeUrl:'/portal/calendar',
            },
        ]
    }
    toPage = (item)=>{
        let url = item.routeUrl
        if(url){
            this.setState({
                routeUrl:url
            })
            this.props.history.push({pathname:url,state:{hh:123}})
        }else{
            if(item.key=='system'){

            }else{
                message.warning('更新中...')
            }

        }

    }
    toSystem = (item)=>{
        window.open(item.resourceHtmlUrl,'_blank')
    }
    loginOut = ()=>{
        loginOutRequest().then(res=>{
            let returnPage = encodeURIComponent(window.location.href)
            window.location.href = `${window.location.protocol}${envHttpUrl().webUrl}/login?auth=1&returnPage=${returnPage}`
        })
    };
    getSystemData = ()=>{
        getBaseSystem().then(res=>{
            console.log(res)
            this.setState({
                systemData:res
            })
        })
        getNotificationsCount().then(res=>{
            this.setState({
                count:res.message.unReadCount
            })
        })
    }

    componentDidMount(){
        console.log(this.store)
        getUserDetail().then(res=>{
            this.setState({
                userNick:res.userNick,
                userName:res.userName,
                avatar:res.avatar||'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg'
            })
        })
        this.getSystemData()
    }
    render() {
        const selUrl = this.props.location.pathname.indexOf('/admin') > -1 ? '/' : this.props.location.pathname;
        return (
            <div className="portal-left">
                <div className="portal-left-wrap">
                    <div>
                        <Space direction="vertical" size="middle">
                            <Avatar src={this.state.avatar} className="head-img" shape="square" size={38}
                                    icon={<Icon.UserOutlined/>}/>
                            {
                                this.state.asideMenu.map(item => {
                                    return (
                                        <Tooltip placement="rightTop" title={
                                            item.key == 'system' ?
                                                (
                                                    <div style={{padding: '10px 20px'}}>
                                                        {
                                                            this.state.systemData.map(item1 => {
                                                                return <p key={item1.resourceId}
                                                                          onClick={() => this.toSystem(item1)} style={{
                                                                    borderBottom: '1px solid #5c5c5c',
                                                                    cursor: 'pointer'
                                                                }}>{item1.resourceName}</p>
                                                            })
                                                        }
                                                    </div>

                                                ) : item.desc
                                        } key={item.key}>
                                            <div
                                                className={'portal-aside-item ' + (selUrl == item.key ? 'select-portal-aside' : '')}
                                                onClick={() => this.toPage(item)} style={{cursor: 'pointer'}}>
                                                {
                                                    item.key == '/portal/notifications' && this.state.count > 0 ?
                                                        <span
                                                            className='message-count'>{this.state.count > 99 ? '99+' : this.state.count}</span> : ''
                                                }
                                                <IconFun icon={item.icon}/>
                                                <span style={{
                                                    display: 'block',
                                                    fontSize: '10px',
                                                    fontWeight: 'normal'
                                                }}>{item.name}</span>
                                            </div>
                                        </Tooltip>

                                    )
                                })
                            }
                        </Space>
                    </div>
                    <div>
                        <Space direction="vertical" size="small">
                            <Tooltip placement="right" title={this.state.userNick}>
                                <div className='portal-aside-item'>
                                    <Icon.UserOutlined/>
                                    <span style={{
                                        display: 'block',
                                        fontSize: '10px',
                                        fontWeight: 'normal'
                                    }}>{this.state.userNick}</span>
                                </div>
                            </Tooltip>
                            <Tooltip placement="right" title="修改密码">
                                <div
                                    className={'portal-aside-item ' + (selUrl == '/portal/password' ? 'select-portal-aside' : '')}
                                    onClick={() => this.toPage({routeUrl: '/portal/password'})}>
                                    <Icon.LockOutlined/>
                                </div>
                            </Tooltip>
                            <Tooltip placement="right" title="退出登录">
                                <div onClick={() => this.loginOut()} className='portal-aside-item'>
                                    <Icon.LogoutOutlined/>
                                </div>
                            </Tooltip>
                        </Space>

                    </div>
                </div>
            </div>

        )
    }
}
export default withRouter(PortalAside)
