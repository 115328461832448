import React, {Component} from 'react'
import {
    Tooltip,
    Table,
    Button,
    message,
    notification,
    Select,
    Tag,
    Form,
    Radio,
    Input,
    Tabs, Badge,Cascader, PageHeader, Row, Col, Pagination
} from 'antd';
import {
    getConfigureMessageList,
    getConfigureClassMessage,
    getInvokeMapKey,
    getNotificationsOptions,
    getUserListForDropbox,
    saveNotificationData,getCronTimeData
} from '../../request'
import './detail.less'
import * as Icon from '@ant-design/icons';
import {envHttpUrl} from '../../http/globalMethod'

let columsKey = {
    messageTitle:'消息Title',
    // messageHead:'消息内容',
    createTime:'创建时间',
    id:'查看数据',
    readTime:'消息接收详情',
}

const layout = {
    labelCol: {
        flex: '100px',
    },
    wrapperCol: {
        span: 22,
    },
};
export default class SetNotificationDetail extends Component {
    formRef = React.createRef()
    buttonFormRef = React.createRef()
    state = {
        defaultTab:1,
        dataSource:[],
        columns:[],
        loading:false,
        visible:false,
        systemItem:{},
        messageClassMap:[],
        messageActionMap:[],
        messageClassFilter:[],
        mergeSendList:[],
        appList:[],
        messageTypeList:[],
        messageLevelList:[],
        messageSourceList:[],
        invokeTypeList:[],
        pushDingdingList:[],
        buttonActionList:[],
        userList:[],
        formParam:{
            id:this.props.match.params.id!=0?this.props.match.params.id:null,
            parentId:this.props.match.params.pid,

        },
        tableParam:{
            pageSize:10,
            pageNum:1
        },
        insertPos:'',
        insertVar:'',
        cronTime:'',
        total:0
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            console.log('key',item)
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'center'
            }
            if(item=='readTime'){
                return {
                    ...initObj,
                    align:'left',
                    render:(text,record,index)=>{
                        console.log(record.users)
                        return(
                            <>
                                {record.users.map(item=>{
                                    return <div style={{marginBottom:'5px'}}>
                                        <Badge color={item.readTime?'green':'red'} text={
                                            item.toUserName+(item.readTime?` 于${item.readTime}读取`:' 未读')
                                        } />
                                        {
                                            item.status==1?
                                                <Tag color='green' style={{margin:'0 10px'}}>已处理</Tag>:''
                                        }
                                        {
                                                <Tooltip title={item.dingError} placement="right">
                                                    <Tag style={{margin:'0 10px'}} icon={item.dingCode==200?<Icon.CheckCircleOutlined />:<Icon.CloseCircleOutlined />} color={item.dingCode==200?"cyan":"red"}>{item.pushDingding}</Tag>
                                                </Tooltip>
                                        }
                                        <Button onClick={()=>this.handleDetail(record.id,item.id)} size='small' style={{fontSize:'12px'}} ghost type='primary'>查看数据</Button>


                                    </div>
                                })}
                            </>

                        )
                    }
                }
            }else if(item=='id'){
                return {
                    ...initObj,
                    align: 'left',
                    render: (text, record, index) => {
                        return (
                            <>
                                <Button onClick={()=>this.handleDetail(record.id)} size='small' style={{fontSize:'12px'}} ghost type='primary'>查看数据</Button>
                            </>
                        )
                    }
                }

            }else{
                return initObj
            }
        })
        this.setState({
            columns:columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        getConfigureClassMessage(this.state.formParam).then(res=>{
            let resultData = res
            resultData['pushVos'] = resultData['pushVos'].map(item=>{
                item.filters = item.filters.map(item1=>{
                    item1.filterArr = [item1.filterName,item1.filter]
                    return item1
                })
                item.userArr = item.users.map(item1=>{
                    return item1.toUserId
                })
                return item
            })
            resultData.usersArr = res.users.map(item=>{
                return item.toUserId
            })
            this.setState({
                formParam:{
                    ...resultData,
                }
            })
            this.formRef.current.setFieldsValue({...res})
            this.initClassMapKey(res.invokeUrl)
        })


    }
    initMessageTableList = ()=>{
        getConfigureMessageList({messageClassId:this.state.formParam.id,...this.state.tableParam}).
        then(res=>{
            this.setState({
                loading: false,
                dataSource:res.list,
                total:res.total
            })
        })
    }
    initUserList = ()=>{
        getUserListForDropbox({type:0}).
        then(res=>{

            this.setState({
                loading: false,
                userList:[...res]
            })
        })
    }
    initClassMapKey = ()=>{
        let {invokeUrl,invokeType,id,appId} = this.state.formParam
        getInvokeMapKey({url:invokeUrl,invokeType,messageClassId: id,appId}).then(res1=>{
            this.setState({
                messageClassMap:res1.messageClass||[],
                messageActionMap:res1.messageAction||[],
                messageClassFilter:(res1.messageClassFilter||[]).map(item=>{
                    item.value = item.filterName
                    item.label = item.filterName
                    item.children = item.filters.map(item1=>{
                        item1.value = item1.code
                        item1.label = item1.name
                        return item1
                    })
                    return item
                }),
            })
            this.addUserFilterItem()
        })
    }
    initCronTimeData = (cron)=>{
        getCronTimeData({cron:cron}).then(res=>{
            this.setState({
                cronTime:res.message||''
            })
        })
    }
    initNotificationsOptions = ()=>{
        getNotificationsOptions({}).then(res=>{
            console.log(res)
            this.setState({
                messageSourceList:res.messageSourceList||[],
                messageLevelList:(res.messageLevelList||[]).map(item=>{
                    return { label:item.name,value:item.code}
                }),
                messageTypeList:(res.messageTypeList||[]).map(item=>{
                    return { label:item.name,value:item.code}
                }),
                invokeTypeList:(res.invokeTypeList||[]).map(item=>{
                    return { label:item.name,value:item.code}
                }),
                pushDingdingList:(res.pushDingdingList||[]).map(item=>{
                    return { label:item.name,value:item.code}
                }),
                buttonActionList:(res.buttonActionList||[]).map(item=>{
                    return { label:item.name,value:item.code}
                }),
                mergeSendList:res.isReadList||[],
                appList:res.appList||[],

            })
        })
    }
    handleChangeValue = (item,index,index1)=>{
        let param = this.state.formParam
        param.pushVos[index].filters[index1].inputNum = item.inputNum

        this.setState({
            formParam:{
                ...param
            }
        })
    }
    validateMessages = {
        required: '${label} 为必填项!',
    }
    handleSubmit = (values)=>{
        const {formParam} = this.state;
        console.log(formParam,values)
        let setParam = formParam
        setParam.pushVos = (formParam.pushVos||[]).filter(item=>{
            return item.pushDingding!==''
        })
        saveNotificationData(setParam).
        then(res=>{
            notification.success({
                message: '保存成功',
                duration:1,
                onClose:()=>{
                    this.props.history.go(-1)
                    // this.props.history.push('/admin/setnotification')
                }
            });


        })
    }
    addUserFilterItem = ()=>{
        let addUser = this.state.messageClassMap.indexOf('hidden_接收人')>-1?[
            {
                USER_ID: "${hidden_接收人}",
                USER_NAME: "${hidden_接收人}",
                USER_NICK: "${hidden_接收人}",
                pinyin: "${hidden_接收人}"
            }]:[]
        console.log(123,addUser,this.state.userList)
        let setUser = this.state.userList.indexOf(addUser)>-1?this.state.userList:[
            ...addUser,
            ...this.state.userList
        ]
        this.setState({
            userList :setUser
        })
    }
    addPushFilter = ()=>{

        let filterArr = this.state.formParam.pushVos||[]
        filterArr.push({temId:new Date().getTime(),pushDingding:'',messageClassId:this.state.formParam.id,filters:[]})
        this.setState({
            formParam:{
                ...this.state.formParam,
                pushVos:filterArr,
            }
        })
    }
    addFilterItem = (item,index)=>{
        console.log(item)
         let param = this.state.formParam
        param['pushVos'][index].filters.push({ temId:new Date().getTime(),filterName:'',filter:'',filterValue:'',filterValue1:'',inputNum:1})
        this.setState({
            formParam:{
                ...param
            }
        })
    }
    addActionButton = ()=>{
        let param = this.state.formParam
        param.actions = param.actions||[]
        param.actions.push({ temId:new Date().getTime(),messageClassId:this.state.formParam.id,buttonText:'',buttonIcon:'',buttonAction:'',buttonUrl:''})
        this.setState({
            formParam:{
                ...param
            }
        })
        this.formRef.current.setFieldValue('actions',param.actions)
    }
    removeListItem = (state,index,index1)=>{
        console.log(state,index,index1)
        let param = this.state.formParam
        let setList = [...param[state]]
        if(index1!=undefined){
            setList[index].filters.splice(index1,1)
        }else{
            setList.splice(index,1)
        }
        console.log('param',param,setList)
        param[state] = setList
        this.setState({
            formParam:{
                ...param
            }
        })
        this.formRef.current.setFieldValue(state,setList)
    }
    getMapKey = ()=>{
        console.log(this.state.formParam.invokeUrl)
        this.initClassMapKey()
    }
    formValueChange = (changedValues, allValues)=>{
        console.log(changedValues,allValues)
        let param = this.state.formParam
        let changeKey = Object.keys(changedValues)[0]
        param[changeKey] = allValues[changeKey]
        this.setState({
            formParam:{
                ...param
            }
        })
    }
    handleActionsValueChange = (changedValues, index)=>{
        let param = this.state.formParam
        let changeKey = Object.keys(changedValues)[0]
        let changeValue = Object.values(changedValues)[0]
        param.actions[index][changeKey] = changeValue

        this.setState({
            formParam:{
                ...param
            }
        })

    }
    handlePushFilterValueChange = (changedValues, index,index1)=>{
        let changeKey = Object.keys(changedValues)[0]
        let changeValue = Object.values(changedValues)[0]
        let param = this.state.formParam
        console.log(changeKey,changeValue,changedValues,index,index1)

        if(index1!=undefined){
            param.pushVos[index].filters[index1][changeKey] = changeValue
            if(changeKey == 'filterArr'){
                param.pushVos[index].filters[index1].filterName = changeValue[0]
                param.pushVos[index].filters[index1].filter = changeValue[1]
            }
        }else{
            param.pushVos[index][changeKey] = changeValue
        }
        this.setState({
            formParam:{
                ...param
            }
        })
    }
    copyTestCode = (text)=>{
        console.log(text)
        var input = document.createElement("textarea"); // 创建input对象
        input.value = text; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        message.success('已复制到剪贴板');
    }
    inputInsertVar = (pos,text,index)=>{
        console.log(pos,text)
        this.setState({
            insertPos:pos,
            insertVar:text,
            insertButtonPos:index,
        })
    }
    handleInsertVarItem = (item,type)=>{
        console.log(item)
        let insertText = '${'+item+'}'
        let param = this.state.formParam
        let pos = this.state.insertPos
        let setObj = this.state.insertVar
        if(type){
            let insertItemText = param[setObj][this.state.insertButtonPos].buttonUrl||''
            let setValue = insertItemText.slice(0, pos) + insertText + insertItemText.slice(pos)
            param[setObj][this.state.insertButtonPos].buttonUrl = setValue;
            this.formRef.current.setFieldValue(setObj,param[setObj])
        }else{
            let insertItemText = param[setObj]||''
            let setValue = insertItemText.slice(0, pos) + insertText + insertItemText.slice(pos)
            param[setObj] = setValue;
            this.formRef.current.setFieldValue(setObj,setValue)
        }
        console.log(param[setObj])
        this.setState({
            formParam:{
                ...param
            }
        })
    }
    handleDetail = (mid,uid)=>{
        console.log(mid,uid)
        if(uid){
            this.props.history.push({
                pathname:`/portal/notificationresult/${uid||0}`,
            })
        }else{
            this.props.history.push({
                pathname:`/portal/notificationresult/${mid||0}`,
                search:'?from=message'
            })
        }
    }
    componentDidMount(){

        console.log(this.props.location,this.props.match,this.state.formParam)
        this.setState({
            defaultTab:2
        })
        this.createColumns(columsKey);
        this.initNotificationsOptions()
        this.initUserList()
        if(this.state.formParam.id){
            this.initTableDataList()
            this.initMessageTableList()
        }else{
            let param = {
                ...this.state.formParam,
                messageLevel:3,
                subscribe:0,
                mergeSend:1,
                invokeType:1,
                messageType:1,
                actions:[{
                    buttonAction:1,
                    buttonText:'查看详情',
                    buttonIcon:'',
                    buttonUrl:window.location.protocol+envHttpUrl().portalUrl+'/portal/notificationresult/${messageId}'
                }]
            }
            this.setState({
                formParam:{...param}
            })
            this.initClassMapKey('')
            this.formRef.current.setFieldsValue({...param})

        }

    }
    render() {
        const  locationQuery = new URLSearchParams(this.props.location.search)
        const defaultTab = locationQuery.get('tab')?locationQuery.get('tab'):1;
        console.log(defaultTab)
        const { dataSource,columns, loading,visible,formParam,systemItem} = this.state;
        return (
            <div>
                <PageHeader
                    className="site-page-header site-page-detail-header"
                    title="配置消息详情"
                    onBack={() => this.props.history.go(-1)}
                    subTitle=""
                />
                <div className="set-notification-detail" style={{padding:'10px 20px'}}>
                    <Tabs
                        defaultActiveKey={defaultTab}
                        items={[
                            {
                                label: `基本配置`,
                                key: '1',
                                forceRender:true,
                                children: (
                                    <>
                                        <Form
                                            {...layout}
                                            ref={this.formRef}
                                            name="basic"
                                            labelAlign="right"
                                            labelWrap={true}
                                            preserve={false}
                                            initialValues={formParam}
                                            onFinish={this.handleSubmit}
                                            onValuesChange={this.formValueChange}
                                            validateMessages={this.validateMessages}
                                        >
                                            <Form.Item
                                                shouldUpdate
                                                label="类型名称"
                                                name="name"
                                                rules={[{ required: true}]}

                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                label="消息来源"
                                                name="messageSource"
                                                rules={[{ required: true}]}

                                            >
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    placeholder="消息来源"
                                                    optionFilterProp="children"
                                                    fieldNames={{label: 'name', value: 'code'}}
                                                    filterOption={(input, option) =>
                                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={this.state.messageSourceList}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="消息类型"
                                                name="messageType"
                                                rules={[{ required: true}]}

                                            >
                                                <Radio.Group
                                                    optionType={"button"}
                                                    options={ this.state.messageTypeList}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="消息类别"
                                                name="invokeType"
                                                rules={[{ required: true}]}

                                            >
                                                <Radio.Group
                                                    options={ this.state.invokeTypeList}
                                                />
                                            </Form.Item>
                                            {
                                                this.state.formParam.invokeType!=1?
                                                    <Form.Item
                                                        label="调度cron"
                                                        name="cron"
                                                        rules={[{ required: true }]}
                                                        help={
                                                            <span className={'form-tips'}>*【cron表达式格式：0 0 12 * * ?  生成格式参考：https://www.pppet.net】</span>}
                                                    >
                                                        <Input.Search placeholder="请输入调度cron" style={{ width: 'calc(100% - 0px)' }} onSearch={this.initCronTimeData} enterButton={this.state.cronTime?'下次执行时间：'+this.state.cronTime:'执行时间'} />
                                                    </Form.Item>
                                                    :''
                                            }
                                            {
                                                this.state.formParam.invokeType==3?
                                                <Form.Item
                                                    label="应用模板"
                                                    name="appId"
                                                    help={
                                                        <span className={'form-tips'}>*消息类别为【执行sql】时选择【应用】，用来维护该sql执行所需的数据库链接密码等信息，【应用】在【EXCEL工具】系统中添加</span>}

                                                >
                                                    <Select
                                                        allowClear
                                                        showSearch
                                                        placeholder="应用模板"
                                                        optionFilterProp="children"
                                                        fieldNames={{label: 'name', value: 'code'}}
                                                        filterOption={(input, option) =>
                                                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={this.state.appList}
                                                    />
                                                </Form.Item>:''
                                            }
                                            {
                                                this.state.formParam.invokeType==3?

                                                    <div style={{display:'flex'}}>
                                                        <Form.Item
                                                            label="SQL语句"
                                                            name="invokeUrl"
                                                            style={{ flex:1}}
                                                        >
                                                            <Input.TextArea autoSize={{maxRows:10}} placeholder="请输入sql语句"/>
                                                        </Form.Item>
                                                        <Button type='primary' style={{ width: 90 }} onClick={this.getMapKey}>
                                                            执行SQL
                                                        </Button>
                                                    </div>
                                                    :
                                                    <Form.Item
                                                        label="获取变量"
                                                        name="invokeUrl"
                                                    >
                                                        <Input.Search placeholder="请输入接口链接获取参数" style={{ width: 'calc(100% - 0px)' }} onSearch={this.getMapKey} enterButton={'获取变量'} />

                                                    </Form.Item>
                                            }


                                            <div style={{paddingLeft:'100px'}}>
                                                {
                                                    this.state.messageClassMap.map(item=>{
                                                        return (
                                                            <Tag key={item} onClick={()=>this.handleInsertVarItem(item)} color={item.indexOf('sys_')>-1?'cyan':(item.indexOf('hidden_')>-1?'orange':'pink')} style={{cursor:'pointer',marginBottom:'10px'}}>{item}</Tag>
                                                        )
                                                    })
                                                }
                                                <div className={'form-tips'}>注： sys_ 为系统变量；hidden_ 为隐藏变量；推送内容显示列表的表达式：{'$sys_list{{|${字段1}|${字段2}|${字段3}|'}&#92;n}};${'{hidden_接收人}'}为接收人变量;</div>
                                            </div>
                                            <div style={{border:'2px dashed #efefef',background:'#efefef',borderRadius:'10px',padding:'20px 0 10px',margin:'10px 0 20px' }}>
                                                <Form.Item
                                                    shouldUpdate
                                                    label="消息主键"
                                                    name="messagePrimaryKey"
                                                    rules={[{ required: true }]}

                                                >
                                                    <Input.TextArea onBlur={(e)=>{
                                                        let posInsert = e.currentTarget.selectionStart
                                                        this.inputInsertVar(posInsert,'messagePrimaryKey')
                                                    }} />
                                                </Form.Item>
                                                <Form.Item
                                                    label="消息title"
                                                    name="messageTitle"
                                                    rules={[{ required: true }]}

                                                >
                                                    <Input.TextArea onBlur={(e)=>{
                                                        let posInsert = e.currentTarget.selectionStart
                                                        this.inputInsertVar(posInsert,'messageTitle')
                                                    }} />
                                                </Form.Item>
                                                <Form.Item
                                                    label="消息head"
                                                    name="messageHead"
                                                    rules={[{ required: true }]}


                                                >
                                                    <Input.TextArea onBlur={(e)=>{
                                                        let posInsert = e.currentTarget.selectionStart
                                                        this.inputInsertVar(posInsert,'messageHead')
                                                    }} />

                                                </Form.Item>
                                                <Form.Item
                                                    label="消息body"
                                                    name="messageBody"
                                                >
                                                    <Input.TextArea onBlur={(e)=>{
                                                        let posInsert = e.currentTarget.selectionStart
                                                        this.inputInsertVar(posInsert,'messageBody')
                                                    }} />

                                                </Form.Item>
                                                <Form.Item
                                                    label="消息尾"
                                                    name="messageTail"
                                                >
                                                    <Input.TextArea onBlur={(e)=>{
                                                        let posInsert = e.currentTarget.selectionStart
                                                        this.inputInsertVar(posInsert,'messageTail')
                                                    }} />

                                                </Form.Item>
                                                <Form.Item
                                                    label="消息备注"
                                                    name="messageRemark"
                                                >
                                                    <Input.TextArea onBlur={(e)=>{
                                                        let posInsert = e.currentTarget.selectionStart
                                                        this.inputInsertVar(posInsert,'messageRemark')
                                                    }} />

                                                </Form.Item>
                                                <Form.Item
                                                    label="消息来源用户"
                                                    name="messageFromUserId"
                                                >
                                                    <Input.TextArea onBlur={(e)=>{
                                                        let posInsert = e.currentTarget.selectionStart
                                                        this.inputInsertVar(posInsert,'messageFromUserId')
                                                    }} />

                                                </Form.Item>
                                            </div>
                                            <Form.Item
                                                label="消息级别"
                                                name="messageLevel"
                                                rules={[{ required: true }]}
                                            >
                                                <Radio.Group
                                                    options={ this.state.messageLevelList}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label="是否可订阅"
                                                name="subscribe"
                                                rules={[{ required: true }]}
                                            >
                                                <Radio.Group
                                                    options={ [
                                                        {
                                                            label: '是',
                                                            value: 1,
                                                        },
                                                        {
                                                            label: '否',
                                                            value: 0,
                                                        }
                                                    ]}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="是否合并"
                                                name="mergeSend"
                                                rules={[{ required: true }]}
                                            >
                                                <Radio.Group
                                                    options={ [
                                                        {
                                                            label: '是',
                                                            value: 1,
                                                        },
                                                        {
                                                            label: '否',
                                                            value: 0,
                                                        }
                                                    ]}
                                                />

                                            </Form.Item>
                                            {/*<Form.Item*/}
                                            {/*    label="接收人"*/}
                                            {/*    name="usersArr"*/}
                                            {/*    rules={[{ required: true }]}*/}

                                            {/*>*/}
                                            {/*    <Select*/}
                                            {/*        mode="multiple"*/}
                                            {/*        style={{minWidth:'200px'}}*/}
                                            {/*        showSearch*/}
                                            {/*        optionFilterProp="children"*/}
                                            {/*        fieldNames={{label:'USER_NICK',value:'USER_ID'}}*/}
                                            {/*        filterOption={(input, option) =>{*/}
                                            {/*            let searchText = (option?.USER_NICK ?? '')+(option?.pinyin ?? '')*/}
                                            {/*            return  searchText.toLowerCase().includes(input.toLowerCase())*/}
                                            {/*        }*/}
                                            {/*        }*/}
                                            {/*        allowClear*/}
                                            {/*        placeholder="推送条件"*/}
                                            {/*        options={this.state.userList}*/}
                                            {/*        onChange={(value,option)=>{*/}
                                            {/*            console.log(value,option)*/}
                                            {/*            let param = this.state.formParam*/}
                                            {/*            param.users = option.map(opt=>{*/}
                                            {/*                return { toUserId:opt.USER_ID, toUserName:opt.USER_NICK}*/}
                                            {/*            })*/}
                                            {/*            this.setState({*/}
                                            {/*                formParam: {...param}*/}
                                            {/*            })*/}
                                            {/*        }}*/}
                                            {/*    />*/}
                                            {/*</Form.Item>*/}
                                            <Form.Item
                                                label="消息推送"
                                                name="pushVos"
                                                className={'form-item-no-margin'}
                                            >
                                                <Button type="primary" onClick={this.addPushFilter}>+添加推送方式</Button>
                                                {
                                                    (this.state.formParam.pushVos||[]).map((item,index)=>{
                                                        return (
                                                            <div style={{margin:'10px 0'}} key={item.id||item.temId}>
                                                                <Form
                                                                    initialValues={item}
                                                                    onValuesChange={(event)=>this.handlePushFilterValueChange(event,index)}
                                                                >
                                                                    <div style={{display:'flex',alignItems:'center'}}>
                                                                        <Form.Item label={"推送方式"+(index+1)} name="pushDingding">
                                                                            <Select
                                                                                style={{minWidth:'200px'}}
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                                }
                                                                                allowClear
                                                                                placeholder="推送条件"
                                                                                options={this.state.pushDingdingList}
                                                                            />
                                                                        </Form.Item>
                                                                        <Form.Item style={{margin:'0 10px'}} label={"推送人"} name="userArr">
                                                                            <Select
                                                                                mode="multiple"
                                                                                style={{minWidth:'200px'}}
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                fieldNames={{label:'USER_NICK',value:'USER_ID'}}
                                                                                filterOption={(input, option) =>{
                                                                                    let searchText = (option?.USER_NICK ?? '')+(option?.pinyin ?? '')
                                                                                    return  searchText.toLowerCase().includes(input.toLowerCase())
                                                                                }
                                                                                }
                                                                                allowClear
                                                                                placeholder="推送人"
                                                                                options={this.state.userList}
                                                                                onChange={(value,option)=>{
                                                                                    console.log(value,option)
                                                                                    let param = this.state.formParam
                                                                                    param.pushVos[index].users = option.map(opt=>{
                                                                                        return { toUserId:opt.USER_ID, toUserName:opt.USER_NICK}
                                                                                    })
                                                                                    this.setState({
                                                                                        formParam: {...param}
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </Form.Item>
                                                                        <div className="add-filter" onClick={()=>this.addFilterItem(item,index)}>+ 添加筛选条件</div>
                                                                        <div className="close-filter" onClick={()=>this.removeListItem('pushVos',index)}><Icon.CloseOutlined /></div>

                                                                    </div>

                                                                    <div style={{paddingLeft:'150px'}}>
                                                                        {
                                                                            item.filters.map((item1,index1)=>{
                                                                                return (

                                                                                    <div className='push-item-filter' key={item1.id||item1.temId}>
                                                                                        <Form
                                                                                            initialValues={item1}
                                                                                            onValuesChange={(event)=>this.handlePushFilterValueChange(event,index,index1)}
                                                                                        >
                                                                                            <div style={{display:'flex',alignItems:'center'}}>
                                                                                            <Form.Item

                                                                                                label={"条件"+(index1+1)}
                                                                                                name="filterArr"
                                                                                            >
                                                                                                <Cascader
                                                                                                    style={{minWidth:'180px'}}
                                                                                                    allowClear
                                                                                                    placeholder="推送条件"
                                                                                                    onChange={(v,v1)=>{
                                                                                                        let selItem = v1[1]
                                                                                                        this.handleChangeValue(selItem,index,index1)
                                                                                                    }}
                                                                                                    options={this.state.messageClassFilter}
                                                                                                />
                                                                                            </Form.Item>
                                                                                                {
                                                                                                    item1.filterValue!=''||item1.inputNum==1?
                                                                                                        <Form.Item
                                                                                                            style={{margin:'0 10px'}}
                                                                                                            name="filterValue"
                                                                                                        >
                                                                                                            <Input/>
                                                                                                        </Form.Item>:''
                                                                                                }
                                                                                                {
                                                                                                    item1.filterValue1!=''||item1.inputNum==2?
                                                                                                        <Form.Item
                                                                                                            name="filterValue1"
                                                                                                        >
                                                                                                            <Input/>
                                                                                                        </Form.Item>:''
                                                                                                }

                                                                                            <div className="close-filter"  onClick={()=>this.removeListItem('pushVos',index,index1)}><Icon.CloseOutlined /></div></div>
                                                                                        </Form>

                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Form.Item>
                                            <Form.Item label="消息按钮配置">
                                                <>
                                                    <div>
                                                        <Button type="primary" onClick={this.addActionButton} size="middle">+添加按钮</Button>
                                                        <span style={{fontSize:'10px',color:'#e50011',marginLeft:'10px'}}>* 点击以下标签，可将变量插入链接中</span>
                                                    </div>
                                                    <div style={{paddingLeft:'56px',marginTop:'20px'}}>
                                                        {
                                                            (this.state.messageActionMap||[]).map(item=>{
                                                                return (
                                                                    <Tag key={item} onClick={()=>this.handleInsertVarItem(item,'actions')} color={item.indexOf('sys_')>-1?'cyan':(item.indexOf('hidden_')>-1?'orange':'pink')} style={{cursor:'pointer',marginBottom:'10px'}}>{item}</Tag>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </>

                                            </Form.Item>

                                            <Form.List name='actions' initialValue={this.state.formParam.actions}>
                                                {
                                                    (fields, operation) => {
                                                        return(
                                                            <div style={{marginLeft:'100px'}}>
                                                                {(fields||[]).map((field,index)=>{
                                                                    console.log(this.state.formParam.actions[index])
                                                                    return (
                                                                    <div key={field.key}>
                                                                            <Row align={'center'} gutter={10}>
                                                                                <Col span={4}>
                                                                                    <Form.Item labelCol={{flex:'56px'}} label={index==0?'默认':'按钮'+(index)}  name={[field.name,'buttonText']}>
                                                                                        <Input  />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={4}>
                                                                                    <Form.Item labelCol={{flex:'56px'}}  label="类型"  name={[field.name,'buttonAction']}>
                                                                                        <Select
                                                                                            disabled={index==0}
                                                                                            allowClear
                                                                                            placeholder="类型"
                                                                                            options={this.state.buttonActionList}
                                                                                        />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={15}>
                                                                                    <Form.Item label="链接" labelCol={{flex:'56px'}}  name={[field.name,'buttonUrl']}>
                                                                                        <Input  onBlur={(e)=>{
                                                                                            let posInsert = e.currentTarget.selectionStart
                                                                                            this.inputInsertVar(posInsert,'actions',index)
                                                                                        }} />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={1} >
                                                                                    {
                                                                                        index==0?'': <div className="close-filter"  onClick={()=>operation.remove(index)}><Icon.CloseOutlined /></div>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                    </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        )
                                                    }
                                                }
                                            </Form.List>
                                            <Form.Item style={{display:'flex',justifyContent:'flex-end',marginRight:'50px'}}>
                                                <Button type="primary" danger size="large" htmlType="submit">
                                                    保存
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </>
                                ),
                            },
                            {
                                label: `消息列表`,
                                key: '2',
                                children: (
                                    <>
                                        <Table
                                            bordered
                                            size="small"
                                            pagination={false}
                                            dataSource={dataSource}
                                            columns={columns}
                                            rowKey={record=>record.id}
                                            loading={loading}/>
                                        <div style={{display:'flex',justifyContent:'flex-end',margin:'20px 0'}}>
                                            <Pagination size="small"
                                                        hideOnSinglePage={true}
                                                        current={this.state.tableParam.pageNum}
                                                        pageSize={this.state.tableParam.pageSize}
                                                        total={this.state.total}
                                                        showSizeChanger
                                                        onChange={(page, pageSize)=>{
                                                            console.log(page, pageSize)
                                                            let param = this.state.tableParam
                                                            param.pageSize = pageSize
                                                            param.pageNum = page
                                                            this.setState({
                                                                tableParam:{...param}
                                                            })
                                                            this.initMessageTableList()
                                                        }}
                                            />

                                        </div>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>

        )
    }
}