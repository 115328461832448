import React, {Component} from 'react'
import PerformanceDetail from "../../../components/OA/PerformanceDetail";
import {withRouter} from "react-router-dom";
const  locationQuery = new URLSearchParams(window.location.search)

class SubordinatePerformanceDetail extends Component {
    state = {
        userId:this.props.match.params.id!=0?this.props.match.params.id:null,

    }
    componentDidMount(){
        console.log(this.props.history)
    }
    render() {
        const periodId = locationQuery.get('periodId')||undefined
        return (
            <>
                <PerformanceDetail id={this.state.userId} periodId={periodId}/>
            </>
        )
    }
}
export default withRouter(SubordinatePerformanceDetail)