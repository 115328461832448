import React,{Component} from 'react'
import {Route,Switch,Redirect,withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {portalRouter} from '../../routes'

const mapState = (state)=>{
    return({
        isLogin:state.users.isLogin
    })
}
@connect(mapState)
class Portal extends Component {
    render(){
        return (
            <>
                <div className={'portal-wrap'}>
                    <Switch>
                        {
                            portalRouter.map(route =>{
                                return <Route location={{...route}} key={route.pathname} path={route.pathname} component={route.component} />
                            })
                        }
                    </Switch>
                </div>

            </>

        )
    }
}

export default Portal