import React, {Component} from 'react'
import {getmd5} from '../../http/globalMethod'
import {updatePassWord} from '../../request'
import {Button, Card, Form, Input, notification} from 'antd';

class Dashboard extends Component {
    // constructor() {
    //     super();
    // }
    formRef = React.createRef();

    submitPassword = (value)=>{
        updatePassWord({
            oldPassword:getmd5(value.oldPassword),
            newPassword:getmd5(value.newPassword)
        }).then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.formRef.current.resetFields();
        })
    }
    render() {
        return (
            <>
                <Card title={'密码管理'}>
                    <Form ref={this.formRef} name="sys_user_search" onFinish={this.submitPassword}>
                        <Form.Item
                            label="原密码"
                            name="oldPassword"
                            rules={[{ required: true, message: '请输入原密码' }]}
                        >
                            <Input.Password placeholder="输入原始密码" allowClear/>
                        </Form.Item>
                        <Form.Item
                            label="新密码"
                            name="newPassword"
                            rules={[{ required: true, message: '请输入新密码' }]}
                        >
                            <Input.Password
                                placeholder="输入新密码" allowClear/>
                        </Form.Item>
                        <Form.Item shouldUpdate={true}>
                            <Button type="primary" style={{margin:'20px auto',display:'block'}} htmlType="submit">保存</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
        )
    }
}
export default Dashboard