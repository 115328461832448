import React, {Component} from 'react'
import {
    Card,
    Tooltip,
    Table,
    Button,
    message,
    notification,
    Space,
    Tag,
    Form,
    Radio,
    Input,
    TreeSelect,
    InputNumber, Modal, Switch, Popconfirm, Popover
} from 'antd';
import {envHttpUrl, getmd5} from '../../http/globalMethod'

import {getSsoDataList, delSsoDataList, saveSsoDataList} from '../../request'
import * as Icon from '@ant-design/icons';

let columsKey = {
    name: '名称',
    type: '类型',
    loginType:'登录类型',
    url: 'sso链接信息',
    action: '操作',
}
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 6,
        span: 18,
    },
};
export default class ssoSystem extends Component {
    state = {
        dataSource:[],
        columns:[],
        loading:false,
        visible:false,
        systemItem:{},
        formParam:{

        }
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            console.log('key',item)
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'center'
            }
            if(item=='type'){
                return {
                    ...initObj,
                    render:(text,record)=>{

                        return(
                                text=='report'?<Tag color={'#f50'}>页面</Tag>:<Tag color={'#87d068'}>报表</Tag>
                        )
                    }
                }

            }else if(item=='loginType'){
                return {
                    ...initObj,
                    render: (text, record) => {
                        return (
                            text == '1' ?
                                <Tag color={'pink'}>统一授权({record.userMail})</Tag>
                                :
                                <Tag color={'cyan'}>当前用户登录</Tag>
                        )
                    }
                }

            }else if(item=='url'){
                return {
                    ...initObj,
                    render:(text,record)=> {
                        let ssoLink = `${window.location.protocol}${envHttpUrl().portalUrl}/portal/guanyuanpage?id=${record.id}`
                        return (
                            <Space direction={'vertical'}>
                                <Tooltip title={text}>
                                    源链接：
                                    <Button type={'link'} size={'small'} onClick={()=>this.handleCopy(text)}><Icon.CopyOutlined /></Button>
                                </Tooltip>
                                <Tooltip title={ssoLink}>
                                    sso链接：
                                    <Button type={'link'} size={'small'} onClick={()=>this.handleCopy(ssoLink)}><Icon.CopyTwoTone /></Button>
                                </Tooltip>
                            </Space>

                        )
                    }
                }

            }else if(item=='action'){
                return {
                    ...initObj,
                    fixed:'right',
                    width:180,
                    render:(text, record, index)=>{
                        return (
                            <>
                                <Button type="link" size="small" onClick={()=>(this.editItemParam(record))}>编辑</Button>
                                <Button type="link" size="small" onClick={()=>(this.openSsoPage(record))}>打开</Button>
                                <Popconfirm
                                    title="确定要删除该条数据吗？"
                                    onConfirm={()=>this.deleteItem(record)}
                                    okText="确认"
                                    cancelText="取消"
                                >
                                    <Button type="link" size="small">删除</Button>
                                </Popconfirm>
                            </>

                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        getSsoDataList().
        then(res=>{
            this.setState({
                loading: false,
                dataSource:res
            })
        })
    }
    editItemParam = (values)=>{
        console.log(values)
        this.setState({
            visible:true,
            formParam:{
                ...values
            }
        })
    }
    handleCopy = (text)=>{
        console.log(text)
        var input = document.createElement("textarea"); // 创建input对象
        input.value = text; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        message.success('已复制到剪贴板');
    }
    openSsoPage = (values)=>{
        console.log(values)
        window.open(`${window.location.protocol}${envHttpUrl().portalUrl}/portal/guanyuanpage?id=${values.id}`)
    }
    deleteItem = (values)=>{
        delSsoDataList({id:values.id}).then(res=>{
            message.success('删除成功')
            this.initTableDataList()
        })
    }
    handleCloseModel = ()=>{
        this.setState({
            visible:false,
        })
    }
    formValueChange = (changedValues, allValues)=>{
        console.log(changedValues,allValues)
        let param = this.state.formParam
        let changeKey = Object.keys(changedValues)[0]
        param[changeKey] = allValues[changeKey]
        this.setState({
            formParam:{
                ...param
            }
        })
    }
    handleSubmit = (values)=>{
        const {systemItem} = this.state;
        console.log(systemItem,values)
        saveSsoDataList(values).
        then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.setState({
                visible:false,
            })
            this.initTableDataList()

        })
    }
    componentDidMount(){
        this.createColumns(columsKey);
        this.initTableDataList()
    }
    render() {
        const { dataSource,columns, loading,visible,formParam,systemItem} = this.state;
        return (
            <div>
                <Card title="sso配置" bordered={false}>
                    <Button type="primary" style={{marginBottom:'20px'}} onClick={()=>this.editItemParam({id:''})}>新增</Button>

                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}/>
                </Card>
                <Modal
                    width={600}
                    title="修改"
                    open={visible}
                    destroyOnClose={true}
                    onCancel={this.handleCloseModel}
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        labelAlign="right"
                        preserve={false}
                        initialValues={formParam}
                        onFinish={this.handleSubmit}
                        onValuesChange={this.formValueChange}
                    >
                        <Form.Item
                            hidden={true}
                            name={'id'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="名称"
                            name="name"
                            rules={[{ required: true, message: '请输入编码' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="报表链接"
                            name="url"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="类型"
                            name="type"
                            extra={<span style={{fontSize:'10px',color:'#e50011'}}>* 链接上含有「page」选择页面，含有「form-data」 选择报表</span>}
                        >
                            <Radio.Group
                                options={[
                                    {label:'报表',value:'form'},
                                    {label:'页面',value:'report'},
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label="内容展示区域"
                            name="showType"
                        >
                            <Radio.Group>
                                <Space direction="vertical" size={14}>
                                    <Radio value={0}>都隐藏,只展示内容(iframe2)</Radio>
                                    <Radio value={1}>展示标题和左侧(iframe3)</Radio>
                                    <Radio value={2}>展示标题(iframe)</Radio>
                                    <Radio value={3}>都展示</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="登录类型"
                            name="loginType"
                        >
                            <Radio.Group
                                options={[
                                    {label:'统一授权登录',value:1},
                                    {label:'当前用户登录',value:2},
                                ]}
                            />
                        </Form.Item>
                        {
                            this.state.formParam.loginType==1?
                                <Form.Item
                                    label="登录邮箱"
                                    name="userMail"
                                    extra={<span style={{fontSize:'10px',color:'#e50011'}}>* 登录类型为「统一授权登录」必填,默认值为  sso@lechun.cc</span>}

                                >
                                    <Input/>
                                </Form.Item>
                                :''
                        }
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>

        )
    }
}