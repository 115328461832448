import React, {Component,useState} from 'react'
import {Button, DatePicker, Drawer, Form, Input, message, Tag, Tree, Radio, Modal} from 'antd';
import {getSurveyProject,saveSurveyProject,getSurveyProjectTreeList,saveInsertSurveyQuestion} from '../../request'
import {nanoidSet} from '../../public/nanoidSet'
import * as Icon from '@ant-design/icons';

import Generator, {
    defaultCommonSettings,
    defaultGlobalSettings,
    defaultSettings,
}  from 'fr-generator';
import './oa.less'
import {commonSetting,defaultCommonSetting,resetCommonSetting,resetGlobalSettings} from './commonSetting'
import moment from "moment";
import * as ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
const { Provider, Sidebar, Canvas, Settings,render } = Generator;

const MyReactQuill = (props)=>{
    const [defaultVal, setDefaultVal] = useState(props.value);
    const [defaultModalVal, setDefaultModalVal] = useState(defaultVal);
    const modules = {
        toolbar: [
            [{ 'header': [ 1,2,3,4,5, false] }],
            [{ 'color': [] }, { 'background': [] },'bold', 'italic', 'underline'],
            [
                {'list': 'ordered'}, {'list': 'bullet'},
                'link', 'image'],
            ['clean']
        ]
    }
    const simpleModules = {
        toolbar: [
            [ 'bold', 'italic']
        ],
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        console.log(1,defaultVal)
        setDefaultModalVal(defaultVal)
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        console.log(6,defaultModalVal)
        setDefaultVal(defaultModalVal)
        props.addons.setValue( props.addons.dataPath,defaultModalVal)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleChange = (val) => {
        console.log(123,val,props.addons.dataPath)
        setDefaultVal(val)
        props.addons.setValue(props.addons.dataPath,val)
    };
    const handleModalChange = (val) => {
        console.log(3,val)
        setDefaultModalVal(val)

    };

    return <div style={{width:'100%',position:'relative'}}>
        <Button type={'link'} size={'small'} style={{position:'absolute',right:0,top:"8px"}} onClick={showModal}>More</Button>
            <ReactQuill theme="snow" modules={simpleModules} {...props} defaultValue={props.value} value={defaultVal} onChange={handleChange} />
            <Modal
                forceRender={true}
            title="高级编辑"
            open={isModalOpen}
            footer={[
                <Button type={'primary'} onClick={handleOk}>确定</Button>
            ]}
            onCancel={handleCancel}>
            <ReactQuill theme="snow" modules={modules} {...props} defaultValue={defaultVal} value={defaultModalVal} onChange={handleModalChange} />
        </Modal>
    </div>

}
const MyInput = (props)=>{

    return <Input {...props} />
}
class SurveyRender extends Component {
    genRef = React.createRef()
    formRef = React.createRef()
    settingRef = React.createRef()
    formRefFooter = React.createRef()
    state = {
        formParam:{
            title:'问卷标题',
            description:'感谢您能抽出几分钟时间来参加本次答题，现在我们就马上开始吧！',
            attribute:{
                suffix:'您已完成本次问卷，感谢您的帮助与支持',
                submitButton:'提交',
            }


        },
        surveyId:this.props.match.params.id!=0?this.props.match.params.id:null,
        parentId:this.props.match.params.pid,
        defaultValue: {
            type: 'object',
            displayType:'column',
        },
        preview:false,
        surveyInfo:{},
        settingReset:resetCommonSetting(nanoidSet(4)),
        schemaParam:{},
        dialogVisible:false,
        drawerVisible:false,
        settingParam:{},
        surveyTreeData:[],
        checkedSurveyKeysData:[],
    }
    getSurveyData = ()=>{
        getSurveyProject({id:this.state.surveyId}).then(res=>{
            let {title,description,attribute,children} = res.survey||{}
            let param = res.survey?{
                title:title,
                description,
                attribute
            }:this.state.formParam
            let defaultJsonProperties = {};
            (children||[]).map(item=>{
                let jsonItem = JSON.parse(item.formJson||'{}')
                defaultJsonProperties = {...defaultJsonProperties,...jsonItem}
            })
            console.log(defaultJsonProperties)
            res.setting.answerSetting.endTime = res.setting.answerSetting.endTime?moment(res.setting.answerSetting.endTime):null
            this.setState({
                formParam:param,
                defaultValue: {
                    type: 'object',
                    displayType:'column',
                    properties:defaultJsonProperties
                },
                surveyInfo:res,
                settingParam:res.setting
            })
            this.formRef.current.setFieldsValue({...param})
            this.formRefFooter.current.setFieldsValue({...param})

        })
    }
    saveSurvey = (from,newSchema)=>{
        console.log('value',newSchema,this.state.defaultValue)
        let value = newSchema?newSchema:this.state.defaultValue
        let properties = value.properties||{}
        let SurveySchema = {}
        let {title,
            description,
            attribute} = this.state.formParam
        let formJson = value
        let param = {
            id:this.state.surveyId,
            parentId:this.state.parentId,
            name:this.state.surveyInfo.name,
            mode:'survey',
            survey:{},
            setting:null,
            status:this.state.surveyInfo.status,
            formJson:value,
            url:encodeURIComponent(window.location.href)
        }
        SurveySchema = {
            "type": "Survey",
            "title": title,
            "description": description,
            "attribute": {
                ...attribute
            },
            children:[]
        }
        let isCheckOrNot = Object.keys(properties)
        let pattern = new RegExp('CheckOrNot')
        let checkOrNotKeys = isCheckOrNot.filter(item=>pattern.test(isCheckOrNot))
        let checkOrNotKey =checkOrNotKeys.length>0?checkOrNotKeys[0]:''

        let children = []
        for(let key in properties){
            let setIdType = this.setIdType(key)
            let type = setIdType.type
            let id = setIdType.id
            let {title,description} = properties[key]
            let childrenC = []
            if(checkOrNotKey){
                // properties[key].hidden = 'rootValue.'+checkOrNotKey+''
            }

            if(properties[key].hasOwnProperty('enum')){
                let enumArr = properties[key].enumList
                enumArr.map((item,index)=>{
                    childrenC.push({
                        id:item.value,
                        title:item.label,
                        score:Number(item.score)||''
                    })
                })
            }else{
                console.log(key,properties[key].props)
                childrenC.push({
                    id:properties[key].props.id,
                    title:'',
                })
            }

            children.push(
                {id,type,title,description,children:childrenC,attribute:{
                        required:properties[key].required,
                        disabled:properties[key].disabled,
                        readOnly:properties[key].readOnly,
                        hidden:properties[key].hidden,
                    },formJson: JSON.stringify({[key]:properties[key]})
                }
            )
        }
        SurveySchema.children = children
        param.survey = SurveySchema
        param.formJson = JSON.stringify({...value,properties})
        param.setting = Object.keys(this.state.settingParam).length>0?this.state.settingParam:null
        console.log('保存数据',param,formJson)
        saveSurveyProject(param).then(res=>{
            message.success('操作成功')
            if(!from){
                this.props.history.go(-1)
            }
        })
    }
    setIdType = (idType)=>{
        let setArr = idType.split('_')
        let type = setArr[0]
        setArr.splice(0,1)
        return {id:setArr.join('_'),type:type}
    }
    handleFormChange = (changedValues, allValues)=>{
        console.log(changedValues, allValues)
        let param = this.state.formParam
        if(allValues.hasOwnProperty('attribute')){
            param.attribute = {...param.attribute,...allValues.attribute}
        }else{
            let changeKey = Object.keys(changedValues)[0]
            param[changeKey] = allValues[changeKey]
        }
        this.setState({
            formParam:{
                ...param
            }
        })
    }
    handleChangeSchema = (schema)=>{
        let settingVal = this.genRef.current.getSettingsForm().getValues()
        console.log('schema change',schema,settingVal)


        let {properties,type,displayType} = schema
        let newSchema = {properties:{
                ...properties,
            },type,displayType,}
        for(let key in properties){
            let selId = settingVal.hasOwnProperty('$id')?settingVal.$id.split('#/')[1]:''
            if(key==selId){
                if(settingVal.$id&&(
                    settingVal.$id.indexOf('Select')>-1||
                    settingVal.$id.indexOf('Radio')>-1||
                    settingVal.$id.indexOf('MultiSelect')>-1||
                    settingVal.$id.indexOf('Checkbox')>-1)
                ){

                    let enumA = settingVal.enumList
                    if(this.state.defaultValue.properties[key].hasOwnProperty('enumList')){
                        this.state.defaultValue.properties[key].enumList.map((item,index)=>{
                            enumA[index].score = item.score||''
                        })
                    }

                    newSchema.properties[key] = {...properties[key],enumList:enumA}
                    this.genRef.current.getSettingsForm().setValueByPath('enumList',enumA)

                }else{
                    delete settingVal['enumList']
                    delete properties[key]['enum']
                    delete properties[key]['enumNames']
                    newSchema.properties[key] = properties[key]

                }
                if(settingVal.hasOwnProperty('enumList')){
                    console.log('enumlist')

                }else{
                    // newSchema.properties[key] = properties[key]
                }

            }
        }
        this.setState({
            defaultValue:newSchema
        })

    }
    canvasSel = (schema)=>{
        if(this.genRef.current.getSettingsForm()){
            let settingVal = this.genRef.current.getSettingsForm().getValues()
            console.log('can:s',settingVal)
        }
        this.setState({
            settingReset:resetCommonSetting(nanoidSet(4))
        })
    }
    settingSurvey = ()=>{
        this.settingRef.current.setFieldsValue({...this.state.settingParam})
        this.setState({
            dialogVisible:true
        })
    }
    openSurveyData = ()=>{
        this.setState({
            drawerVisible:true
        })
        getSurveyProjectTreeList({type:2}).then(res=>{
            this.setState({
                surveyTreeData:res
            })
        })

    }
    handleSubmitSetting = (val,allV)=>{
        let param = this.state.settingParam
        param.answerSetting = {...param.answerSetting,...val.answerSetting}
        param.answerSetting.endTime = val.answerSetting.endTime?new Date(val.answerSetting.endTime._d).getTime():null

        param.submittedSetting = {...param.submittedSetting,...val.submittedSetting}

        this.setState({
            settingParam:param
        })
        this.saveSurvey('setting')
        this.handleCloseModal()
    }
    handleTreeDataCheck = (checkedKeys, e)=>{
        console.log(checkedKeys,e)
        let checkedNodes = e.checkedNodes.filter(item=>item.mode=='question')
        this.setState({
            checkedSurveyKeysData:checkedNodes.map(item=>item.id)
        })
    }
    saveTreeDataCheck = ()=>{
        let qsIds = this.state.checkedSurveyKeysData.join(',')
        saveInsertSurveyQuestion({projectId:this.state.surveyId,moduleQuestionId:qsIds}).then(res=>{
            message.success('添加成功')
            this.handleCloseModal()
            this.getSurveyData()
        })
    }
    deleteSchema = (schema)=>{
        console.log('del',schema)
        let schemaA = this.genRef.current.getValue()
        let selId = schema.hasOwnProperty('$id')?schema.$id.split('#/')[1]:''
        delete schemaA.properties[selId]
        console.log('n',schemaA)
        this.genRef.current.setValue(schemaA)
        this.setState({
            defaultValue:schemaA
        })
        console.log('v',this.state.defaultValue)
        this.saveSurvey('del',schemaA)

    }
    copySchema = (schema)=>{
        console.log('copy',schema)
        let {$id,...otherSchema} = schema
        let selOriId = schema.hasOwnProperty('$id')?schema.$id.split('#/')[1]:''
        let selKey = selOriId.split('_')[0]
        let selNewKey = selKey+'_'+nanoidSet(6)
        let otherNewSchema = {...otherSchema}
        otherNewSchema.order = 1
        if(otherSchema.hasOwnProperty('enum')){
            otherNewSchema.enum = otherSchema.enum.map((item,index)=>{
                item = nanoidSet(4)
                if(otherSchema.hasOwnProperty('enumList')){
                    otherNewSchema.enumList[index].value = item
                }
                return item
            })
        }else{
            otherNewSchema.props.id = nanoidSet(4)
        }
        let copyScheme = {[selNewKey]:{...otherNewSchema}}
        let schemaA = this.genRef.current.getValue()
        schemaA.properties = {...schemaA.properties,...copyScheme}
        console.log('n',schemaA,schema,copyScheme)
        this.genRef.current.setValue(schemaA)
        this.setState({
            defaultValue:schemaA
        })

    }
    handleCloseModal = ()=>{
        this.setState({
            dialogVisible:false,
            drawerVisible:false,
            checkedSurveyKeysData:[]
        })
    }
    componentDidMount(){
        if(this.state.surveyId){
            this.getSurveyData()
        }
    }
    render() {
        const  locationQuery = new URLSearchParams(this.props.location.search)
        const defaultName = locationQuery.get('name')?locationQuery.get('name'):'';
        return (
            <div className={'card-fixed-head-wrap survey-render-wrap'}>
                <div className={'top-wrap'}>
                    <div
                        onClick={()=>{
                            this.props.history.go(-1)
                        }}
                        style={{lineHeight:'50px',padding:'0 0 0 10px',fontSize:'20px',cursor:'pointer'}}><Icon.ArrowLeftOutlined /></div>
                    <Form
                        style={{flex:1}}
                        ref={this.formRef}
                        initialValues={this.state.formParam}
                        onValuesChange={this.handleFormChange}
                    >
                            <Form.Item name={'title'}>
                                <Input className={'survey-header-title'} placeholder={'请输入问卷标题'}/>
                            </Form.Item>

                        <Form.Item name={'description'}>
                            <Input className={'survey-header-des'} placeholder={'请输入问卷描述'}/>
                        </Form.Item>
                    </Form>
                    <Button.Group style={{margin:'10px'}}>

                        {
                            this.state.surveyId?
                                <>
                                    <Button style={{background: 'green', color: '#fff', borderColor: 'green'}} type={'info'}
                                            onClick={()=>this.settingSurvey()}><Icon.SettingOutlined />配置</Button>
                                    <Button type={'primary'} danger onClick={()=>{
                                        this.openSurveyData()
                                    }}><Icon.DatabaseOutlined />题库</Button>
                                </>
                                :''
                        }

                        {/*<Button type={'primary'} onClick={()=>{*/}
                        {/*    this.setState({*/}
                        {/*        preview:!this.state.preview*/}
                        {/*    })*/}
                        {/*}}>{this.state.preview?'编辑':'预览'}</Button>*/}

                        <Button type={'primary'}  onClick={()=>this.saveSurvey()}><Icon.SaveOutlined />保存</Button>
                    </Button.Group>
                </div>

                <Generator
                    className={'layout-wrap'}
                    getId={name => `${name}_${nanoidSet(6)}`}
                    preview={this.state.preview}
                    extraButtons={[false,false,false,false]}
                    controlButtons={[true,false,{
                        text:<Icon.CopyFilled/>,
                        onClick:(event, schema)=>this.copySchema(schema)
                    }]}
                    widgets={{MyReactQuill,MyInput}}
                    defaultValue={this.state.defaultValue}
                    settings={this.state.settingReset}
                   commonSettings={defaultCommonSetting}
                    // globalSettings={resetGlobalSettings}
                   onCanvasSelect={(schema)=>this.canvasSel(schema)}
                   ref={this.genRef}
                   onSchemaChange={this.handleChangeSchema}
                />
                <div className={'bottom-wrap'}>
                    <Form ref={this.formRefFooter} initialValues={this.state.formParam} onValuesChange={this.handleFormChange}>
                        <div style={{padding:'0'}}>
                            <Form.Item name={['attribute','suffix']}>
                                <Input  className={'survey-footer-des'} placeholder={'问卷完成提示'}/>
                            </Form.Item>
                            <Form.Item name={['attribute','submitButton']}>
                                <Input className={'survey-footer-btn'} placeholder={'按钮文案'}/>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <Modal destroyOnClose forceRender title={"问卷配置"}
                       open={this.state.dialogVisible}
                       footer={false}
                       onCancel={this.handleCloseModal}>
                    <Form
                        labelCol={{flex:'100px'}}
                        ref={this.settingRef}
                        initialValues={this.state.settingParam}
                        layout="horizontal"
                        onFinish={this.handleSubmitSetting}
                    >
                        <Form.Item name={["answerSetting","endTime"]} label={'问卷截止时间'}>
                            <DatePicker
                                showTime={{ format: 'HH:mm:ss' }}
                                format="YYYY-MM-DD HH:mm:ss" placeholder="问卷截止时间"/>
                        </Form.Item>
                        <Form.Item name={["answerSetting","copyEnabled"]} label={'是否可复制'}>
                            <Radio.Group >
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name={["answerSetting","loginRequired"]} label={'是否需要登录'}>
                            <Radio.Group >
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name={["submittedSetting","enableUpdate"]}  label={'是否可更新'}>
                            <Radio.Group >
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name={["submittedSetting","redirectUrl"]} label={'答完跳转链接'}>
                            <Input placeholder="答完跳转链接"/>
                        </Form.Item>
                        <Form.Item name={["submittedSetting","invokeUrl"]} label={'回调链接'}>
                            <Input placeholder="回调链接"/>
                        </Form.Item>
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <Drawer width={'60%'} title="问卷题库" placement="right"
                        onClose={this.handleCloseModal}
                        open={this.state.drawerVisible}
                        extra={
                            <Button type={'primary'} danger onClick={this.saveTreeDataCheck}>添加到问卷</Button>
                        }
                >
                    <h3 style={{fontSize:'14px'}}>请从下列题库中选择需要的题</h3>
                    <Tree
                        className={'survey-data-tree-drawer'}
                        showIcon={true}
                        icon={
                            (props)=>{
                                return <span>
                                    {props.mode == 'folder' ? <Icon.FolderOpenOutlined/> : (props.mode == 'survey' ?
                                        <Icon.FileProtectOutlined/> : <Icon.HolderOutlined />)}
                                </span>
                            }

                        }
                        autoExpandParent
                        defaultExpandAll
                        onCheck={this.handleTreeDataCheck}
                        checkable
                        fieldNames={{title: 'name',key:'id',children:'child'}}
                        treeData={this.state.surveyTreeData}
                        titleRender={(node => {
                            return <span dangerouslySetInnerHTML={{ __html: node.name }}></span>
                        })}
                    />
                </Drawer>
            </div>
        )
    }
}

export default SurveyRender