import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {ConfigProvider, Layout} from 'antd';
import store from './store'

import {BrowserRouter as Router,Route,Switch,Redirect} from 'react-router-dom'
import {noPortalSide} from './routes'
import {PortalAside, PortalActions, Frame, Aside,Header} from './components'
import zhCN from 'antd/es/locale/zh_CN';
import DingOpen from "./components/DingOpen/DingOpen";

import './App.less'
import App from "./App";

render(
    <ConfigProvider locale={zhCN}>
        <Provider store={store}>
                <DingOpen/>
                <Router>
                    <Switch>
                        {
                            noPortalSide.map(route =>{
                                return <Route location={{...route}} key={route.pathname} path={route.pathname} component={route.component}></Route>
                            })
                        }
                        <Route path="/" render={props => {
                            {console.log('/',props)}
                            return (
                                <div style={{position:'relative',paddingLeft:'54px',height:'100%'}}>
                                    <PortalAside />
                                    <App/>
                                    <PortalActions/>
                                </div>

                            )
                        }}/>


                    </Switch>

                </Router>

        </Provider>
    </ConfigProvider>
    ,
    document.querySelector('#root')

)