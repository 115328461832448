const isLogin = window.localStorage.getItem('LOGIN_TOKEN')==1?true:false

const initState = {
    id:'',
    displayName:'',
    avatar:'',
    role:'',
    isLogin:isLogin
}
export default (state=initState,action)=>{
    switch(action.type){
        case 'START_LOGIN':
            return {
                ...state
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isLogin: true
            }
        case 'LOGIN_FAILED':
            return {
                ...state,
                isLogin: false
            }
        default:
            return state
    }
}