import React, {Component} from 'react'
import {
    Card,
    Button,
    message, Form,  Input, Collapse,Alert
} from 'antd';
import {getOAMessageSysList, saveOAMessageSys,} from '../../request'
import * as Icon from '@ant-design/icons';
import {withRouter} from "react-router-dom";
const { Panel } = Collapse;

class MessageSys extends Component {
    surveyModal = React.createRef()
    state = {
        dataSource:[],
        loading:false,
    };
    initTableDataList(){
        this.setState({ loading: true });
        getOAMessageSysList({}).then(res=>{
            this.setState({
                loading: false,
                dataSource:res.map(item=>{
                    let textArr = item.configText?item.configText.split('@'):[]
                    item.text1 = textArr.length>0?textArr[0]:''
                    item.text2 = textArr.length>2?textArr[2]:''
                    let delayKey = item.configValue?Object.keys(JSON.parse(item.configValue))[0]:null
                    let delayValue = item.configValue?Object.values(JSON.parse(item.configValue))[0]:null
                    item[delayKey] = delayValue
                    item.key = delayKey
                    return item
                })
            })
        })
    }
    handlePushFilterValueChange = (changedValues)=>{
        console.log(changedValues)
        saveOAMessageSys([changedValues]).then(res=>{
            message.success('保存成功')
            this.initTableDataList()
        })
    }
    componentDidMount(){
        this.initTableDataList()
    }
    render() {
        return (
            <>
                <Card title={'消息配置'} bordered={false} className={'card-fixed-head-wrap'}>
                    <Alert style={{marginBottom:'10px'}} message="说明：消息提示、消息title、消息内容输入框内皆可修改，ps:带有${}的字段可以删除、不要修改;" type="warning" showIcon />

                    <Collapse>
                        {
                            this.state.dataSource.map((item,index)=>{
                                return (
                                    <Panel forceRender header={item.configName} key={index}>
                                        <Form initialValues={item}
                                              onFinish={(changedValues, allValues)=>this.handlePushFilterValueChange(changedValues, allValues,index)}
                                        >
                                            <Form.Item hidden name='id'>
                                                <input/>
                                            </Form.Item>


                                            <div style={{display:'flex',alignItems:'center',marginBottom:'24px'}}>
                                                <span>消息提示：</span>
                                                <span>{item.text1}</span>
                                                {
                                                    item.key?<Form.Item name={item.key} style={{margin:'0 10px 0 10px'}}>
                                                        <Input />
                                                    </Form.Item>:''
                                                }

                                                <span>{item.text2}</span>
                                            </div>

                                            <Form.Item label={'消息Title'} name={'sendTitle'}>
                                                <Input.TextArea />
                                            </Form.Item>
                                            <Form.Item label={'消息内容'} name={'sendContent'}>
                                                <Input.TextArea />
                                            </Form.Item>
                                            <div style={{display:'flex',justifyContent:'flex-end'}}>
                                                <Form.Item
                                                >
                                                    <Button type="primary" htmlType="submit">
                                                        保存
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    </Panel>
                                )
                            })
                        }
                    </Collapse>
                </Card>
            </>

        )
    }
}
export default withRouter(MessageSys)