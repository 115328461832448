import React, {Component} from 'react'
import PerformanceDetail from "../../../components/OA/PerformanceDetail";
import {withRouter} from "react-router-dom";
const  locationQuery = new URLSearchParams(window.location.search)

class MyPerformanceDetail extends Component {
    state = {

    }
    componentDidMount(){
        console.log(this.props.history)

    }
    render() {
        const periodId = locationQuery.get('periodId')||undefined
        return (
            <>
                <PerformanceDetail periodId={periodId}/>
            </>
        )
    }
}
export default withRouter(MyPerformanceDetail)