import React, {Component} from 'react'
import {
    Card,
    Table,
    Button,
    Tag, Badge, Drawer, Form, Select, Pagination, Alert, Tooltip
} from 'antd';
import {getEvalPeriodList, getEvalScoreList, getUserListForDropbox} from '../../../request'
import {withRouter} from "react-router-dom";
import * as Icon from "@ant-design/icons";

let columnsKey = {
    index:"序号",
    periodName: '周期',
    evalClassName: '问卷类型',
    evalUserNick: '评分人',
    status: '状态',
    totalScore:'分数',
    totalAvgScore:'平均分',
    suggest: '定性建议',
}
let subsColumnsKey = {
    title: '问卷问题',
    score:'分数',
    avgScore:'平均分',
    actions: '',
}
class ScoreByList extends Component {
    state = {
        dataSource:[],
        columns:[],
        selSuggest:[],
        selSuggestTitle:'',
        formParam:{
            pageNum:1,
            pageSize:20,
        },
        total:0,
        suggestVisible:false
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='index'){
                return {
                    ...initObj,
                    align:'center',
                    width:50,
                    render:(text,record,index)=>{
                        return index+1
                    }
                }
            }else if(item=='evalUserNick'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{

                        return(
                            <>
                                {record.evalUserNick.map(item=>{
                                    return <div><Badge status={'processing'} text={
                                        item.type+'：'+item.value
                                    }/></div>
                                })
                                }
                            </>
                        )
                    }
                }
            }else if(item=='periodName'){
                return {
                    ...initObj,
                    render:(text,record,index)=> {
                        return (
                            <>
                                {text}
                                {record.linkType ? ('-' + record.linkType) : ''}
                            </>
                        )
                    }
                }
            }else if(item=='totalScore'||item=='totalAvgScore'){
                return {
                    ...initObj,
                    width: 80,
                    render: (text, record, index) => {
                        console.log('text',text)
                        return (
                            <span>{text!=undefined?Number(text).toFixed(2):'-'}</span>
                        )
                    }
                }
            }else if(item=='suggest'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            <>
                                <Button type="link" size="small" onClick={()=>this.openSuggest(record)}>查看</Button>
                            </>
                        )
                    }
                }
            }else if(item=='status'){
                return {
                    ...initObj,
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            text==2?
                                <Tooltip title={'已出结果：是指填报时间已经截止。此状态下，页面呈现结果为最终结果，评分伙伴不可以为其进行评分。'}>
                                    <Tag color="green"><Icon.InfoCircleOutlined /> 已出结果</Tag>
                                </Tooltip>
                                :
                                (text==1?
                                    <Tooltip title={'已计算：是指伙伴的分数已初步计算但未到填报截止时间。此状态下，页面呈现结果非最终结果，评分伙伴可以为其进行评分。'}>
                                        <Tag color="cyan"><Icon.InfoCircleOutlined /> 已计算</Tag>
                                    </Tooltip>
                                    :<Tag color="red">未计算</Tag>)

                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    createSubsColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='score'||item=='avgScore'){
                return {
                    ...initObj,
                    width: 80,
                    render: (text, record, index) => {
                        return (
                            <span>{text!=undefined?Number(text).toFixed(2):''}</span>
                        )
                    }
                }
            }else if(item=='title'){
                return {
                    ...initObj,
                    render: (text, record, index) => {
                        return (
                            <div dangerouslySetInnerHTML={{ __html: text }} ></div>
                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 100,
                }
            }else{
                return initObj
            }
        })
        this.setState({
            subsColumns:columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        getEvalScoreList(this.state.formParam).then(res=>{
            let result = res.list.map(item=>{
                let evalUserNickObj = JSON.parse(item.evalUserNick)
                let evalUserNickArr = []
                for(let key in evalUserNickObj){
                    evalUserNickArr.push({type:key,value:evalUserNickObj[key].join(',')})
                }
                item.evalUserNick = evalUserNickArr
                return item
            })
            this.setState({
                loading: false,
                dataSource:result,
                total:res.total

            })
        })
    }
    initEvalPeriodList = ()=>{
        getEvalPeriodList({type:0}).
        then(res=>{
            this.setState({
                periodList:[...res]
            })
        })
    }
    openSuggest = (values)=>{
        console.log(values)
        this.setState({
            suggestVisible:true,
            selSuggest:values.suggest,
            selSuggestTitle:values.periodName
        })

    }
    closeBox = ()=>{
        this.setState({
            suggestVisible:false,
            selSuggest:[],
            selSuggestTitle:''
        })
    }
    expandedRowRender = (record)=>{
        return <Table pagination={false} columns={this.state.subsColumns} dataSource={record.questions} />
    }
    handleValueChange = (values,allValues)=>{
        console.log(values,allValues)
        let param = this.state.formParam
        param.pageNum = 1
        this.setState({
            formParam:{
                ...param,...values
            }
        },()=>{
            this.initTableDataList()

        })
    }
    componentDidMount(){
        console.log(this.props.history)
        this.createColumns(columnsKey);
        this.createSubsColumns(subsColumnsKey);
        this.initEvalPeriodList()
        this.initTableDataList()
    }
    render() {

        const { dataSource,columns, loading} = this.state;

        return (
            <>
                <Card title={<span>被评分列表</span>} bordered={false} className={'card-fixed-head-wrap'}>
                    <div style={{display:"flex",justifyContent:'space-between',marginBottom:'20px'}}>
                        <Form layout="inline"
                              initialValues={this.state.formParam}
                              onValuesChange={this.handleValueChange}>
                            <Form.Item
                                name="status"
                            >
                                <Select
                                    style={{minWidth:'200px'}}
                                    allowClear
                                    showSearch
                                    placeholder="状态"
                                    filterOption={(input, option) =>
                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        {label:'所有',value:''},
                                        {label:'未计算',value:0},
                                        {label:'已计算',value:1},
                                        {label:'已出结果',value:2},
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item name={'periodId'}>
                                <Select
                                    style={{minWidth: '200px'}}
                                    showSearch
                                    optionFilterProp="children"
                                    fieldNames={{
                                        label: 'periodName',
                                        value: 'periodId'
                                    }}
                                    filterOption={(input, option) => {
                                        let searchText = (option?.periodName ?? '') + (option?.pinyin ?? '')
                                        return searchText.toLowerCase().includes(input.toLowerCase())
                                    }
                                    }
                                    allowClear
                                    placeholder="请选择期次"
                                    options={this.state.periodList}
                                    onChange={this.handleUserChange}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                    <Alert message="平均分为 同考核周期、同问卷类型下的该维度分数的平均分" type="info" showIcon />

                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}
                        expandable={{
                            expandedRowRender:this.expandedRowRender
                        }}
                    />
                    <div style={{display:'flex',justifyContent:'flex-end',margin:'20px 0'}}>
                        <Pagination size="small"
                                    hideOnSinglePage={true}
                                    current={this.state.formParam.pageNum}
                                    pageSize={this.state.formParam.pageSize}
                                    total={this.state.total}
                                    showSizeChanger
                                    onChange={(page, pageSize)=>{
                                        console.log(page, pageSize)
                                        let param = this.state.formParam
                                        param.pageSize = pageSize
                                        param.pageNum = page
                                        this.setState({
                                            formParam:{...param}
                                        })
                                        this.initTableDataList()
                                    }}
                        />

                    </div>

                </Card>
                <Drawer title={"定性建议汇总: "+this.state.selSuggestTitle} width={'60%'} placement="right" onClose={
                    this.closeBox
                }  open={this.state.suggestVisible}>
                    <Table pagination={false} columns={
                        [
                            {dataIndex:'title',title:'问题'},
                            {dataIndex:'answer',title:'建议',render:(text)=>{
                                return (
                                    <>
                                        {
                                            (text||[]).map(item=>{
                                                return <div><Badge status={'success'} text={item}/></div>
                                            })
                                        }

                                    </>
                                )
                                }},
                        ]
                    } dataSource={
                        this.state.selSuggest
                    } />
                </Drawer>
            </>

        )
    }
}
export default withRouter(ScoreByList)