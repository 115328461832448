import React, {Component} from 'react'
import {
    Card,
    Table,
    Button,
    Tag, Form, Select, Tooltip, Pagination, Space, Input, InputNumber, Drawer, Badge,
} from 'antd';
import {getEvalPeriodList, getEvalPeriodLogList, getUserListForDropbox} from '../../request'
import {withRouter} from "react-router-dom";
import * as Icon from "@ant-design/icons";

let columnsKey = {
    index:'序号',
    periodName: '周期',
    userNick: '被评人',
    status: '状态',
    message: '消息',
    evalUser:'发放情况',
}
class ClassLog extends Component {
    state = {
        dataSource:[],
        columns:[],
        formParam:{
            pageNum:1,
            pageSize:10,
        },
        total:0,
        userList:[],
        periodList:[],
        detailDrawerVisible:false
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='index'){
                return {
                    ...initObj,
                    align:'center',
                    width:50,
                    render:(text,record,index)=>{
                        return index+1
                    }
                }
            }else if(item=='status'){
                return {
                    ...initObj,
                    width: 60,
                    render:(text,record,index)=>{
                        return(
                            text==1?
                            <Tag color="blue">有效</Tag>
                                :<Tag color="pink">无效</Tag>
                        )
                    }
                }
            }else if(item=='evalUser'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            <>
                                {(text||[]).map(item=>{
                                    return <div style={{marginBottom:'10px'}}>
                                        <Badge status={'processing' } text={
                                            <>问卷「{item.projectName}」发送给{item.refUserNick}({item.refType}) <Tag color={item.message=='操作成功'?'green':'red'}>{item.message}</Tag></>} />
                                    </div>
                                })}
                            </>
                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            <>
                                {
                                        <>
                                            <Button type="link" size="small"
                                                    onClick={() => (this.toScorePage(record))}>{record.status>1?'查看':'去评分'}</Button>

                                        </>

                                }
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    openClassLog = (values)=>{
        console.log('open')
        this.createColumns(columnsKey);
        let param = this.state.formParam
        param.classId = values.id
        this.setState({
            detailDrawerVisible:true,
        })
        this.initUserList()
        this.initEvalPeriodList()
        this.initTableDataList()

    }
    initTableDataList(){
        this.setState({ loading: true });
        getEvalPeriodLogList(this.state.formParam).then(res=>{
            this.setState({
                dataSource:res.list,
                total:false,
                loading:false
            })
        })
    }
    initUserList = ()=>{
        getUserListForDropbox({type:0}).
        then(res=>{
            this.setState({
                userList:[...res]
            })
        })
    }
    initEvalPeriodList = ()=>{
        getEvalPeriodList({type:1}).
        then(res=>{
            this.setState({
                periodList:[...res]
            })
        })
    }
    toScorePage = (values)=>{
        console.log(values)
        // this.props.history.push({
        //     pathname:values.projectUrl
        // })
        window.open(values.projectUrl,'_blank')
    }
    handleValueChange = (values,allValues)=>{
        console.log(values,allValues)
        let param = this.state.formParam
        param.pageNum = 1
        this.setState({
            formParam:{
                ...param,...values
            }
        },()=>{
            this.initTableDataList()

        })
    }
    onDrawerClose = ()=>{
        this.setState({
            detailDrawerVisible:false
        })
    }
    componentDidMount(){
        console.log(this.props.history)

    }
    render() {

        const { dataSource,columns, loading,detailDrawerVisible} = this.state;

        return (
            <>
                <Drawer
                    destroyOnClose
                    title="日志"
                    width={'70%'}
                    onClose={this.onDrawerClose}
                    open={detailDrawerVisible}
                >
                        <div style={{display:"flex",justifyContent:'space-between',marginBottom:'20px'}}>
                            <Form layout="inline"
                                  initialValues={this.state.formParam}
                                  onValuesChange={this.handleValueChange}>

                                <Form.Item name={'userId'}>
                                    <Select
                                        style={{minWidth: '200px'}}
                                        showSearch
                                        optionFilterProp="children"
                                        fieldNames={{
                                            label: 'USER_NICK',
                                            value: 'USER_ID'
                                        }}
                                        filterOption={(input, option) => {
                                            let searchText = (option?.USER_NICK ?? '') + (option?.pinyin ?? '')
                                            return searchText.toLowerCase().includes(input.toLowerCase())
                                        }
                                        }
                                        allowClear
                                        placeholder="请选择被评人"
                                        options={this.state.userList}
                                        onChange={this.handleUserChange}
                                    />
                                </Form.Item>
                                <Form.Item name={'periodId'}>
                                    <Select
                                        style={{minWidth: '200px'}}
                                        showSearch
                                        optionFilterProp="children"
                                        fieldNames={{
                                            label: 'periodName',
                                            value: 'periodId'
                                        }}
                                        filterOption={(input, option) => {
                                            let searchText = (option?.periodName ?? '') + (option?.pinyin ?? '')
                                            return searchText.toLowerCase().includes(input.toLowerCase())
                                        }
                                        }
                                        allowClear
                                        placeholder="请选择期次"
                                        options={this.state.periodList}
                                        onChange={this.handleUserChange}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                        <Table
                            bordered
                            size="small"
                            pagination={false}
                            dataSource={dataSource}
                            columns={columns}
                            rowKey={record=>record.id}
                            loading={loading}
                        />
                        <div style={{display:'flex',justifyContent:'flex-end',margin:'20px 0'}}>
                            <Pagination size="small"
                                        hideOnSinglePage={true}
                                        current={this.state.formParam.pageNum}
                                        pageSize={this.state.formParam.pageSize}
                                        total={this.state.total}
                                        showSizeChanger
                                        onChange={(page, pageSize)=>{
                                            console.log(page, pageSize)
                                            let param = this.state.formParam
                                            param.pageSize = pageSize
                                            param.pageNum = page
                                            this.setState({
                                                formParam:{...param}
                                            })
                                            this.initTableDataList()
                                        }}
                            />

                        </div>

                </Drawer>
            </>

        )
    }
}
export default ClassLog