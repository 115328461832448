import React,{Component} from 'react'
import {Route,Switch,Redirect,withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {adminRouter} from '../../routes'
import {Frame} from '../../components'

const mapState = (state)=>{
    return({
        isLogin:state.users.isLogin
    })
}
@connect(mapState)
class AMS extends Component {
    render(){
        return (
            <Frame title={'AMS系统'} sys={'AMS'} sysLink={'admin'}>
                <Switch>
                    {
                        adminRouter.map(route =>{
                            return <Route key={route.pathname} exact={route.exact} path={route.pathname} render={(routerProps)=>{
                                return <route.component {...routerProps}  />
                            }} />
                        })
                    }
                    <Redirect to={adminRouter[0].pathname} from="/admin" exact />
                </Switch>
            </Frame>

        )
    }
}

export default AMS