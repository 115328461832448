import React, {Component,useState} from 'react'
import {Steps,Popover,Tag,Tooltip,Timeline} from "antd";
import * as Icon from "@ant-design/icons";

class ProcessSteps extends Component{
    state = {

    }
    render() {
        const stepItem = this.props.steps.allStage
        const currentStep = this.props.steps.currentStage
        return(
            <div>
                <Popover placement="leftTop" content={
                    <Timeline style={{marginTop:'20px'}}>
                        {
                            stepItem.map(item=>{
                                return (
                                        <Timeline.Item
                                            color={currentStep==item.stageKey?'red':(currentStep<item.stageKey?'gray':'green')}
                                            dot={currentStep==item.stageKey?<Icon.ClockCircleOutlined />:(currentStep<item.stageKey?null:<Icon.CheckCircleOutlined />)}>
                                            <span style={{color:currentStep<item.stageKey?'#999999':''}}>{item.stageValue}</span>
                                        </Timeline.Item>
                                )

                            })
                        }
                    </Timeline>
                } title="进度">
                    <Tag color={'volcano'}>进度</Tag>
                </Popover>

            </div>
        )
    }
}
export default ProcessSteps