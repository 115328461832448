import React,{Component} from 'react'
import {Route,Switch,Redirect,withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {adminRouter} from './routes'
import {Frame} from './components'
import {AMS,OA,Portal} from './views'

const mapState = (state)=>{
    return({
        isLogin:state.users.isLogin
    })
}
@connect(mapState)
class App extends Component {
    render(){
        return (
            <>
                <Switch>
                    <Route path={"/admin"} render={props1 => <AMS/>}/>
                    <Route path={"/oa"} render={props1 => <OA/>}/>
                    <Route path={"/portal"} render={props1 => <Portal/>}/>
                    <Redirect to="/portal/dashboard" from="/" exact />
                    <Redirect to="/404"  />
                </Switch>
            </>

        )
    }
}

export default App