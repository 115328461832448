import React, {Component} from 'react'
import {envHttpUrl} from '../../http/globalMethod'
import {withRouter} from "react-router-dom";

class LcPageSystem extends Component {
    state = {
        erpUrl:`${window.location.protocol}${envHttpUrl().erpUrl}`
    }
    componentDidMount(){}
    render() {
        const  locationQuery = new URLSearchParams(this.props.location.search)
        const defaultTab = locationQuery.get('url')?locationQuery.get('url'):'';
        return (
            <div style={{boxSizing:'border-box',height:'100%',paddingBottom:'10px'}} id='erp-container'>
                <iframe style={{width:'100%',height:'100%',border:'none'}} src={defaultTab}></iframe>
            </div>

        )
    }
}
export default withRouter(LcPageSystem)

