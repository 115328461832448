import React, {Component} from 'react'
import {
    Button,
    Card,
    Table,
    Tag,Tooltip
} from 'antd';
import {getScoreResult,downloadScoreResult} from '../../../request'
import {withRouter} from "react-router-dom";
import '../oa.less'
// import { utils, writeFileXLSX } from "xlsx";
import ExportJsonExcel from "js-export-excel";
import {envHttpUrl} from "../../../http/globalMethod";

class ScoreResult extends Component {
    tableRef = React.createRef()
    tableChildRef = React.createRef()
    state = {
        dataSource:[],
        columns:[],
        loading:false,
    };
    createColumns = ()=>{
        let mouthNumber = 5
        let userInfoKey = {
            jobNum:'工号',
            deptName:'钉钉组织架构口径',
            title:'岗位名称',
            userStatus:'员工状态',
            jobLevel:'段位',
            inDate:'入职时间',
            rDate:'计划转正日期',
            leaderNick:'直属leader',
        }
        let mouthColumnKey = {
            startDate: '起止日期',
            kpiScore: '工作业绩得分',
            valuesScore: '价值观得分',
            evalScore: '360环评得分',
            coolScore: '培训旅程得分',
            fullScore: '当月满分分数',
            totalScore: '实际得分',
            scoreGrade: '考核结果',
        }
        let userColumns = [
            {
                title:'序号',
                dataIndex:'index',
                key:'index',
                fixed:'left',
                align:'center',
                width:50,
                render:(text,record,index)=>{
                    return index+1
                }
            },{
                title:'新伙伴',
                dataIndex:'userNick',
                key:'userNick',
                fixed:'left',
                ...this.getColumnSearchProps('userNick'),
                render:(text,record,index)=>{
                    return (
                        <Tooltip title={'跳转到用户绩效评估'}>
                            <span
                                style={{color:'#0094ff',textDecoration:'underline',cursor:'pointer'}}
                                onClick={()=>{
                                window.open(`${window.location.protocol}${envHttpUrl().portalUrl}/oa/subordinate/performancedetail/${record.userId}`)
                            }
                            }>{text}</span>
                        </Tooltip>
                    )
                }
            },
            {
                title:'基础信息',
                children:Object.keys(userInfoKey).map(item=>{
                    let initObj = {
                        title:userInfoKey[item],
                        dataIndex: item,
                        key: item,
                        align: 'center'
                    }
                    if(item=='jobNum'||item=='deptName'||item=='leaderNick'||item=='userStatus'){
                        return {
                            ...initObj,
                            ...this.getColumnSearchProps(item)
                        }
                    }else if(item=='userStatus'){
                        return {
                            ...initObj,
                            filters:[
                                {text:'在职',value:'在职'},
                                {text:'已转正',value:'已转正'},
                                {text:'已离职',value:'已离职'},
                            ],
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record[item] == value
                            },
                        }
                    }else{
                        return {
                            ...initObj,
                        }
                    }

                })
            }
        ]
        let mouthColumns = []
        for(let index=1;index<=mouthNumber;index++){

            mouthColumns.push(
                {
                    title:`第${index}个月考核进度`,
                    children:Object.keys(mouthColumnKey).map(item=>{
                        let initObj = {
                            title:mouthColumnKey[item],
                            dataIndex: `month_${index}_${item}`,
                            key: `month_${index}_${item}`,
                            align: 'center'
                        }
                        if(item=='scoreGrade'){
                            return {
                                ...initObj,
                                filters:[
                                    {text:'合格',value:'合格'},
                                    {text:'不合格',value:'不合格'},
                                    {text:'优秀',value:'优秀'},
                                ],
                                filterSearch: true,
                                onFilter: (value, record) => {
                                    return record[`month_${index}_${item}`] == value
                                },
                                render:(text,record,index)=>{
                                    return (
                                        <Tag color={text=='优秀'?'green':(text=='合格'?'blue':(text=='不合格'?'red':''))}>{text}</Tag>
                                    )
                                }
                            }
                        }else if(item=='totalScore'||item=='kpiScore'||item=='valuesScore'||item=='evalScore'){
                            return {
                                ...initObj,
                                ...this.handlePropsSort(`month_${index}_${item}`)
                            }
                        }else{
                            return initObj
                        }

                    })
                }
            )
        }
        this.setState({
            columns:[...userColumns,...mouthColumns,{
                title:'转正审批进度',
                dataIndex:'approveStage',
                key:'approveStage',
            }],
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        getScoreResult({}).then(res=>{
            this.setState({
                loading: false,
                dataSource:res,
            })
            this.createColumns();
        })
    }
    getColumnSearchProps = (dataIndex)=>{
        let filterObj = {}
        this.state.dataSource.map((item,index)=>{
            filterObj[item[dataIndex]] = item[dataIndex]
        })
        let newFilterList = []
        for(let key in filterObj){
            newFilterList.push({text:filterObj[key],value:key})
        }
        return (
            {
                filters:newFilterList,
                filterSearch: true,
                onFilter: (value, record) => record[dataIndex] == value,
            }
        )
    }
    handlePropsSort = (dataIndex)=>{
        return (
            {
                sorter: (a, b) => {
                    return Number(a[dataIndex]) - Number(b[dataIndex])
                }
            }
        )
    }
    downloadTable = ()=>{
        downloadScoreResult()
    }
    componentDidMount(){
        console.log(this.props.history)
        this.createColumns();
        this.initTableDataList()
    }
    render() {
        const { dataSource,columns, loading} = this.state;
        return (
            <>
                <Card title={'评分汇总'} bordered={false} className={'card-fixed-head-wrap score-result-wrap'}>
                    <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'10px'}}>
                        <Button type={'primary'} onClick={this.downloadTable}>导出表单</Button>
                    </div>
                    <Table
                        ref={this.tableRef}
                        id={'table-ref'}
                        scroll={{x:6500}}
                        bordered={true}
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.userId}
                        loading={loading}/>

                </Card>

            </>

        )
    }
}
export default withRouter(ScoreResult)