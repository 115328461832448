import React, {Component} from 'react'
import {
    Card,
    Table,
    Button,
    Tag, Form, Select, Tooltip, Pagination,
} from 'antd';
import {getEvalPeriodList, getScoreList, getUserListForDropbox} from '../../../request'
import {withRouter} from "react-router-dom";
import * as Icon from "@ant-design/icons";

let columnsKey = {
    index:'序号',
    periodName: '周期',
    userNick: '被评人',
    projectName:'问卷名称',
    createTime:'问卷下发',
    endTime:'问卷结束',
    status:'问卷状态',
    score:'分数',
    actions: '操作',
}
class ScoreList extends Component {
    state = {
        dataSource:[],
        columns:[],
        formParam:{
            pageNum:1,
            pageSize:10,
        },
        total:0,
        userList:[],
        periodList:[],
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='index'){
                return {
                    ...initObj,
                    align:'center',
                    width:50,
                    render:(text,record,index)=>{
                        return index+1
                    }
                }
            }else if(item=='status'){
                return {
                    ...initObj,
                    width: 60,
                    render:(text,record,index)=>{
                        return(
                            text==3?
                            <Tag color="purple">已汇算</Tag>
                                :
                                (text==2?<Tag color="geekblue">已评分</Tag>
                                    :
                                        (text==1?<Tag color="lime">已下发</Tag>:<Tag color="pink">已结束</Tag>))
                        )
                    }
                }
            }else if(item=='refType'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            text==1? 'peers' : (text==2?'leader' : (text==3?'下级':'自己'))
                        )
                    }
                }
            }else if(item=='score'){
                return {
                    ...initObj,
                    width: 80,
                    render: (text, record, index) => {
                        console.log('text',text)
                        return (
                            <span>{text!=undefined?Number(text).toFixed(2):'-'}</span>
                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            <>
                                {
                                        <>
                                            <Button type="link" size="small" style={{color:record.status>1?'green':'#e50011'}}
                                                    onClick={() => (this.toScorePage(record))}>{((record.status>1 && record.status<4) ||record.status==-1)?'已评去查看':(record.status==1?'去评分':'')}</Button>

                                        </>

                                }
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        getScoreList(this.state.formParam).then(res=>{
            this.setState({
                loading: false,
                dataSource:res.list,
                total:res.total
            })
        })
    }
    initUserList = ()=>{
        getUserListForDropbox({type:0}).
        then(res=>{
            this.setState({
                userList:[...res]
            })
        })
    }
    initEvalPeriodList = ()=>{
        getEvalPeriodList({type:0}).
        then(res=>{
            this.setState({
                periodList:[...res]
            })
        })
    }
    toScorePage = (values)=>{
        console.log(values)
        // this.props.history.push({
        //     pathname:values.projectUrl
        // })
        window.open(values.projectUrl,'_blank')
    }
    handleValueChange = (values,allValues)=>{
        console.log(values,allValues)
        let param = this.state.formParam
        param.pageNum = 1
        this.setState({
            formParam:{
                ...param,...values
            }
        },()=>{
            this.initTableDataList()

        })
    }
    componentDidMount(){
        console.log(this.props.history)
        this.createColumns(columnsKey);
        this.initUserList()
        this.initEvalPeriodList()
        this.initTableDataList()
    }
    render() {

        const { dataSource,columns, loading} = this.state;

        return (
            <>
                <Card title={<span>评分列表</span>} bordered={false} className={'card-fixed-head-wrap'}>
                    <div style={{display:"flex",justifyContent:'space-between',marginBottom:'20px'}}>
                        <Form layout="inline"
                              initialValues={this.state.formParam}
                              onValuesChange={this.handleValueChange}>
                            <Form.Item
                                name="status"
                            >
                                <Select
                                    style={{minWidth:'200px'}}
                                    allowClear
                                    showSearch
                                    placeholder="状态"
                                    filterOption={(input, option) =>
                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        {label:'所有',value:''},
                                        // {label:'待下发',value:0},
                                        {label:'已下发',value:1},
                                        {label:'已评分',value:2},
                                        {label:'已汇算',value:3},
                                        {label:'已结束',value:4},
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item name={'userId'}>
                                <Select
                                    style={{minWidth: '200px'}}
                                    showSearch
                                    optionFilterProp="children"
                                    fieldNames={{
                                        label: 'USER_NICK',
                                        value: 'USER_ID'
                                    }}
                                    filterOption={(input, option) => {
                                        let searchText = (option?.USER_NICK ?? '') + (option?.pinyin ?? '')
                                        return searchText.toLowerCase().includes(input.toLowerCase())
                                    }
                                    }
                                    allowClear
                                    placeholder="请选择被评人"
                                    options={this.state.userList}
                                    onChange={this.handleUserChange}
                                />
                            </Form.Item>
                            <Form.Item name={'periodId'}>
                                <Select
                                    style={{minWidth: '200px'}}
                                    showSearch
                                    optionFilterProp="children"
                                    fieldNames={{
                                        label: 'periodName',
                                        value: 'periodId'
                                    }}
                                    filterOption={(input, option) => {
                                        let searchText = (option?.periodName ?? '') + (option?.pinyin ?? '')
                                        return searchText.toLowerCase().includes(input.toLowerCase())
                                    }
                                    }
                                    allowClear
                                    placeholder="请选择期次"
                                    options={this.state.periodList}
                                    onChange={this.handleUserChange}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}
                    />
                    <div style={{display:'flex',justifyContent:'flex-end',margin:'20px 0'}}>
                        <Pagination size="small"
                                    hideOnSinglePage={true}
                                    current={this.state.formParam.pageNum}
                                    pageSize={this.state.formParam.pageSize}
                                    total={this.state.total}
                                    showSizeChanger
                                    onChange={(page, pageSize)=>{
                                        console.log(page, pageSize)
                                        let param = this.state.formParam
                                        param.pageSize = pageSize
                                        param.pageNum = page
                                        this.setState({
                                            formParam:{...param}
                                        })
                                        this.initTableDataList()
                                    }}
                        />

                    </div>

                </Card>
            </>

        )
    }
}
export default withRouter(ScoreList)