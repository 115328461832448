import React, {Component,useState} from 'react'
import {Radio,Card,Divider,Tag, Transfer,Table, Button,Pagination, Modal,Form,Tree, Input, notification,Select} from 'antd';
import {getSalesTypeRoleList,getMallRoleList,saveOrUpdateMallRole,getMallRoleResourceList,saveRoleResource,saveRoleSalesResource,getSalesRoleList,getRoleUserList,saveRoleUser} from '../../request'
import * as Icon from '@ant-design/icons';

const { Option } = Select;
let columsKey = {
    roleId: '角色标识',
    roleName: '角色名',
    platformGroupName: '所属公司',
    status: '状态',
    actions: '操作'
}
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 6,
        span: 18,
    },
};
const initItemDetail = {
    platformGroupId: 0,
    platformGroupName: "",
    roleId: '',
    roleName: "",
    status: 1
}

export default class sysRole extends Component {
    state = {
        dataSource:[],
        authDataSource:[],
        departs:[],
        userDropList:[],
        departsTem:[],
        columns:[],
        authColumns:[{
            title:'id',
            dataIndex:'id',
            key:'id',
        },{
            title:'名称',
            dataIndex:'name',
            key:'name',
        },],
        selectNodes:[],
        selectSalesNodes:[],
        halfSelectNodes:[],
        halfSalesSelectNodes:[],
        salesRoleList:[{key:0,title:'全部权限',children:[]}],
        salesTypeList:[],
        userList:[],
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {},
        loading:false,
        initItemDetail,
        visible:false,
        visibleAuth:false,
        visibleSalesAuth:false,
        visibleUser:false,
        roleId:'',
        roleName:'',
        salesType:1,
        checkSaleAll:false
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'center'
            }
            if(item=='status'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return  text==1?<Icon.CheckCircleOutlined style={{ fontSize: '16px', color: 'green' }}/>:<Icon.CloseCircleOutlined style={{ fontSize: '16px', color: 'red' }} />
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed:'right',
                    width:240,
                    render:(text, record, index)=>{
                        return (
                            <>
                                <Button type="link" size="small" onClick={()=>(this.handleItemEdit(record))}>修改</Button>
                                <Button type="link" size="small" onClick={()=>(this.handleAuthEdit(record))}>权限</Button>
                                <Button type="link" size="small" onClick={()=>(this.handleSalesAuthEdit(record))}>分销权限</Button>
                                <Button type="link" size="small" onClick={()=>(this.handleUsers(record))}>用户</Button>
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(roleName='',pagination={}){
        this.setState({ loading: true });
        //角色列表
        getMallRoleList({
            roleName:roleName,
            currentPage:pagination.current,
            pageSize:pagination.pageSize}).
        then(res=>{
            this.setState({
                loading: false,
                dataSource:res.list,
                pagination:{
                    ...pagination,
                    total:res.total
                }
            })
        })
    }
    initSalesTypeRoleList(){
        this.setState({ loading: true });
        //角色列表
        getSalesTypeRoleList({}).then(res=>{
            this.setState({
                salesTypeList:res,
            })
        })
    }
    handleItemEdit = (item)=>{
        this.setState({
            visible:true,
            initItemDetail:item,
            roleName:item.roleName
        })
    }
    handleAuthEdit = (item)=>{
        const selectNodes = []
        const halfSelectNodes = []

        const renderData = data=>data.map(item=>{
            item.title = item.resourceName
            item.key = item.resourceId
            if(item.hasRight==1){
                selectNodes.push(item.resourceId)
            }
            if(item.hasRight==2){
                halfSelectNodes.push(item.resourceId)
            }
            if(item.children.length>0){
                renderData(item.children)
            }
            return item
        })
        this.setState({
            visibleAuth:true,
            selectNodes,
            halfSelectNodes,
            roleId:item.roleId,
            roleName:item.roleName
        })
        getMallRoleResourceList({roleId:item.roleId}).then(res=>{
            this.setState({
                loading: false,
                authDataSource:renderData(res),
            })
        })
    }
    handleSalesAuthEdit = (item)=>{
        this.setState({
            visibleSalesAuth:true,
            roleId:item.roleId,
            roleName:item.roleName,
            salesType:1,
        })
        this.getSalesRoleData(1)

    }
    getSalesRoleData = (salesType)=>{
        getSalesRoleList({roleId:this.state.roleId,dataType:salesType,pageSize:10000,currentPage:1}).then(res=>{
            let salesRoleTreeList = [{key:0,title:'全部权限',children:[]}]
            let allSel = false
            const selectSalesNodes = []
            res.map(item=>{
                item.title = item.name
                item.key = item.id
                if(item.id==0){

                    allSel = true
                }else{
                    if(item.sel==1){
                        selectSalesNodes.push(item.id)
                    }
                    salesRoleTreeList[0].children.push(item)
                }
            })
            this.setState({
                loading: false,
                salesRoleList:salesRoleTreeList,
                selectSalesNodes:allSel?[0]:selectSalesNodes,

            })
        })
    }
    handleSubmit = (values)=>{
        const {pagination,initItemDetail} = this.state;
        values.roleId = initItemDetail.roleId
        saveOrUpdateMallRole({...values}).
        then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.setState({
                visible:false
            })
            this.initTableDataList('',pagination)

        })
    }
    handleAuthSaveOk = (values)=>{
        const {roleId,selectNodes,halfSelectNodes,pagination} = this.state;
        let selNodes = [...selectNodes]
        let selHalfNodes = [...halfSelectNodes]
        this.setState({
            visibleAuth:false
        })
        saveRoleResource({ roleId:roleId, resourceIds:selNodes.join(','),resourceHalfIds:selHalfNodes.join(',')}).
        then(res=>{
            notification.success({
                message: '操作成功',
            });

            this.initTableDataList('',pagination)

        })
    }
    handleAuthSalesSaveOk = (values)=>{
        const {roleId,selectSalesNodes,pagination,salesType} = this.state;
        console.log(selectSalesNodes.filter(item => item == 0).length);
        let selNodes = selectSalesNodes.filter(item=>item==0).length>0?[0]:[...selectSalesNodes]
        saveRoleSalesResource({ roleId:roleId, dataIds:selNodes.join(','),dataType:salesType}).
        then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.setState({
                visibleSalesAuth:false
            })
            this.initTableDataList('',pagination)

        })
    }
    handleUserSaveOk = (userId,selected)=>{
        const {roleId} = this.state;

        saveRoleUser({ roleId,userId:userId.join(','),selected}).
        then(res=>{
            notification.success({
                message: '操作成功',
            });
            // this.setState({
            //     visibleUser:false
            // })
            // this.initTableDataList('',pagination)

        })
    }
    handleCloseModel = ()=>{
        this.setState({
            visible:false,
            visibleAuth:false,
            visibleSalesAuth:false,
            visibleUser:false,
        })
    }
    handlePageChange = (current,pageSize)=>{
        this.initTableDataList('',{current,pageSize})
    }
    handleSalesType = (e)=>{
        console.log(e.target)
        this.setState({
            salesType:e.target.value
        })
        this.getSalesRoleData(e.target.value)
    }
    handleAuthSelect = (selectedRowKeys,e)=>{

        let halfCheckedKeys= e.halfCheckedKeys
        console.log(selectedRowKeys,halfCheckedKeys)
        this.setState({
            selectNodes:selectedRowKeys,
            halfSelectNodes:halfCheckedKeys
        })
    }
    handleAuthSalesSelect = (selectedRowKeys,e)=>{
        console.log(selectedRowKeys,e)
        this.setState({
            selectSalesNodes:selectedRowKeys,
            halfHalfSelectNodes:e.halfCheckedKeys,
        })
    }
    handleSelAllSales = (e)=>{
        console.log(e)
        const allSelSalesNodes = e.target.checked?this.state.salesRoleList.map(item=>item.id):[]
        console.log(allSelSalesNodes)
        this.setState({
            selectSalesNodes:allSelSalesNodes,
        })
    }

    handleUsers = (item)=>{
        this.setState({
            visibleUser:true,
            roleId:item.roleId,
            roleName:item.roleName
        })
        this.getRoleUserData(item.roleId)

    }
    getRoleUserData = (roleId)=>{
        getRoleUserList({roleId,pageSize:10000,pageNum:1}).then(res=>{
           this.setState({
               userList:res.list,
               userTargetKeys:res.list.filter(item=>item.selected==1).map(item=>item.userId)
           })
        })
    }
    handleUserChange = (targetKeys, direction, moveKeys)=>{
        console.log(targetKeys, direction, moveKeys)
        this.setState({
            userTargetKeys:targetKeys
        })
        this.handleUserSaveOk(moveKeys,direction=='left'?0:1)
    }
    componentDidMount(){
        const {pagination} = this.state;
        this.createColumns(columsKey);
        this.initTableDataList('',pagination)
        this.initSalesTypeRoleList()
    }
    render() {
        const { dataSource,checkSaleAll,columns,salesRoleList,selectSalesNodes,halfHalfSelectNodes, pagination, loading,selectNodes,halfSelectNodes,authDataSource,salesTypeList,salesType } = this.state;
        return (
            <div>
                <Card title="角色管理" bordered={false}>
                    <Button type="primary" style={{marginBottom:'20px'}} onClick={()=>this.handleItemEdit(initItemDetail)}>新增</Button>

                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.roleId}
                        loading={loading}/>
                    <Pagination style={{float:'right',margin:'16px 0'}} hideOnSinglePage={true} size="small" {...pagination} onChange={this.handlePageChange}/>
                </Card>
                <Modal
                    title={"修改（角色："+this.state.roleName+"）"}
                    width="570px"
                    open={this.state.visible}
                    destroyOnClose={true}
                    onCancel={this.handleCloseModel}
                    footer={null}
                >
                    <Form
                        {...layout}
                        labelAlign="right"
                        preserve={false}
                        initialValues={this.state.initItemDetail}
                        onFinish={this.handleSubmit}
                    >
                        <Form.Item
                            label="角色名"
                            name="roleName"
                            rules={[{ required: true, message: '请输入角色名' }]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            label="所属公司"
                            name="platformGroupId"
                        >
                            <Select style={{ width: '100%' }} value={this.state.initItemDetail.platformGroupId}>
                                <Option value={0}>全部</Option>
                                <Option value={1000}>乐纯</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="status"
                        >
                            <Select style={{ width: '100%' }} value={this.state.initItemDetail.status}>
                                <Option value={1}>有效</Option>
                                <Option value={-1}>无效</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={"权限选择（角色："+this.state.roleName+"）"}
                    width="570px"
                    open={this.state.visibleAuth}
                    destroyOnClose={true}
                    onCancel={this.handleCloseModel}
                    onOk={this.handleAuthSaveOk}
                >
                    <Tree
                        checkable
                        showLine={{
                            showLeafIcon: false,
                        }}
                        autoExpandParent
                        checkedKeys={{checked:selectNodes,halfChecked:halfSelectNodes}}
                        treeData={authDataSource}
                        onCheck={this.handleAuthSelect}
                    >
                    </Tree>
                </Modal>
                <Modal
                    title={"分销权限选择（角色："+this.state.roleName+"）"}
                    width="570px"
                    open={this.state.visibleSalesAuth}
                    destroyOnClose={true}
                    onCancel={this.handleCloseModel}
                    onOk={this.handleAuthSalesSaveOk}
                >
                    <Radio.Group style={{marginBottom:'10px'}} value={salesType} onChange={this.handleSalesType} buttonStyle="solid">
                        {salesTypeList.map(item=>{
                            return <Radio.Button key={item.value} value={item.value}>{item.name}</Radio.Button>
                        })}
                    </Radio.Group>
                    <Divider style={{margin:'10px 0'}} />
                    <div style={{position:'relative',maxHeight:'300px',overflowY:'scroll'}}>
                        <Tree
                            checkable
                            showLine={{
                                showLeafIcon: false,
                            }}
                            defaultExpandAll={true}
                            expandedKeys={[0]}
                            defaultExpandParent={true}
                            checkedKeys={{checked:selectSalesNodes,halfChecked:halfSelectNodes}}
                            treeData={salesRoleList}
                            onCheck={this.handleAuthSalesSelect}
                        >
                        </Tree>
                    </div>

                </Modal>
                <Modal
                    title={"用户（角色："+this.state.roleName+"）"}
                    width="570px"
                    open={this.state.visibleUser}
                    destroyOnClose={true}
                    onCancel={this.handleCloseModel}
                    onOk={this.handleUserSaveOk}
                    footer={null}
                >
                    <Transfer
                        dataSource={this.state.userList}
                        showSearch
                        pagination
                        filterOption={(inputValue, option) => {
                            return option.userNick.indexOf(inputValue) > -1;
                        }}
                        titles={[<Tag color={'pink'}>未选用户</Tag>,<Tag color={'green'}>已选用户</Tag>]}
                        targetKeys={this.state.userTargetKeys}
                        onChange={this.handleUserChange}
                        render={(item) => item.userNick}
                        rowKey={(item) => item.userId}
                    />
                    <div style={{display: 'flex',justifyContent: 'flex-end',marginTop: '20px'}}>
                        <Button onClick={()=>this.handleCloseModel()} type="primary" danger>关闭</Button>
                    </div>
                </Modal>

            </div>
        )
    }
}