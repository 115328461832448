import React, {Component} from 'react'
import { Calendar,Card } from 'antd';

class Dashboard extends Component {
    render() {
        return (
            <>
                <Card title={'Dashboard'}>
                    <Calendar />
                </Card>
            </>
        )
    }
}
export default Dashboard