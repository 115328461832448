import React, {Component} from 'react'
import {
    Card,
    Table,
    Button,
    message,
    Tag,
    Badge, Pagination, Form, Input, Select, Dropdown, Modal
} from 'antd';
import {
    getConfigureClassMessageList,
    delNotificationData, saveNotificationData,moveConfigureClassMessage
} from '../../request'
import {withRouter} from "react-router-dom";

import IconFont from "../../public/iconfont/iconFont";

let columsKey = {
    name: '名称',
    messageSource: '来源',
    messageType: '类型',
    messageLevel:'级别',
    pushDingding:'推送类型',
    lastTime:'运行情况',
    // nextTime:'下次运行时间',
    status:'状态',
    actions: '操作',
}
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};
class SetNotification extends Component {
    formRef = React.createRef()
    state = {
        dataSource:[],
        columns:[],
        loading:false,
        visible:false,
        systemItem:{},
        formParam:{
            pageNum:1,
            pageSize:20,
            status:1,
            parentId:this.props.match.params.id?this.props.match.params.id:0
        },
        total:0,
        folderList:[],
        folderDialogVisible:false,
        formDataParam:{}
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            console.log('key',item)
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='name'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            <div style={{display:'flex',cursor:'pointer'}} onClick={()=>(this.openFolder(record))}>
                                {record.mode == 2 ?
                                    <IconFont type={'icon-Openedfolder'} style={{marginRight:'5px',fontSize:'20px'}} />
                                    : ''}
                                <span style={{textDecoration:'underline'}}>{text}</span>
                            </div>

                        )
                    }
                }
            }else if(item=='messageType'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            <Tag color={text==1?'green':'red'}>{text==1?'通知':'任务'}</Tag>
                        )
                    }
                }
            }else if(item=='messageLevel'){
                return {
                    ...initObj,
                    render:(text,record)=>{
                        let levelType = {
                            0:{color:'#cd201f',name:'非常紧急'},
                            1:{color:'#f50',name:'紧急'},
                            2:{color:'#55acee',name:'一般'},
                            3:{color:'#999999',name:'不紧急'},
                        }
                        return(

                            text!==null?<Tag color={levelType[text].color}>{levelType[text].name}</Tag>:''


                        )
                    }
                }

            }else if(item=='pushDingding'){
                return {
                    ...initObj,
                    render:(text,record)=>{
                        let pushType = text?text.split(','):[]
                        return(
                            <>
                                {
                                    pushType.map((item,index)=>{
                                        return  <div key={index}><Badge status="processing" text={item} /></div>

                                    })
                                }
                            </>

                        )
                    }
                }

            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed:'right',
                    width:120,
                    render:(text, record, index)=>{
                        return (
                            <>
                                {
                                    record.mode == 2 ?
                                        <>
                                            {/*<Button type="link" size="small" onClick={()=>(this.openFolder(record))}>打开</Button>*/}
                                            <Button type="link" size="small"
                                                    onClick={() => (this.editNameDialog(record))}>重命名</Button>
                                            <Button type="link" size="small"
                                                    onClick={() => (this.delMessageData(record))}>删除</Button>
                                        </>
                                        :
                                        <>
                                            <Button type="link" size="small" onClick={()=>(this.editItemParam(record))}>编辑</Button>
                                            <Dropdown
                                                // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                                menu={
                                                    {
                                                        items: [

                                                            {
                                                                key: 1, label: '消息列表'
                                                            },{
                                                                key: 2, label: '移动到', children: this.state.folderList
                                                            },{
                                                                key: 3,
                                                                label: record.status==1?'禁用':'启用',
                                                            },

                                                        ],
                                                        onClick: (val) => this.handleAction(val,record)
                                                    }
                                                } trigger={'click'}>
                                                <Button type="link" size="small">...</Button>
                                            </Dropdown>
                                        </>
                                }


                            </>
                        )
                    }
                }
            }else if(item=='status'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            text==1?
                            <Tag color="#2db7f5">可用</Tag>
                                :
                            <Tag color="#cd201f">禁用</Tag>
                        )
                    }
                }
            }else if(item=='lastTime'){
                return {
                    ...initObj,
                    align: 'left',
                    width:300,
                    render:(text,record,index)=>{
                        return(
                            record.mode == 1 ?
                                <div className={'last-run-info'}>
                                    <div><Badge status="success" text={'信息：'+(record.lastMessage||'')} /></div>
                                    <div><Badge status="success" text={'行数：'+(record.lastDataCount)} /></div>
                                    <div><Badge status="success" text={'时间：'+(text||'')} /></div>
                                    <div><Badge status="success" text={'下次运行：'+(record.nextTime||'')} /></div>
                                </div>:''

                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        getConfigureClassMessageList(this.state.formParam).then(res=>{
            this.setState({
                loading: false,
                dataSource:res.list,
                total:res.total
            })
        })
        getConfigureClassMessageList({mode:2}).then(res=>{
            let folderList = [{key:'0',label:'根目录'}]
            let folderData = res.list.map(item=>{
                item.key = item.id
                item.label = item.name
                return {key:item.id,label:item.name}
            })
            this.setState({
                folderList:[...folderList,...folderData]
            })
        })
    }
    editItemParam = (values,tab)=>{
        console.log(values)
        let search = tab? {tab}:null
        console.log(search)
        this.props.history.push({
            pathname:`/admin/setnotification/detail/${values.parentId||0}/${values.id||0}`,
            search:tab?'tab='+tab:null
        })
    }
    copyTestCode = (text)=>{
        console.log(text)
        var input = document.createElement("textarea"); // 创建input对象
        input.value = text; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        message.success('已复制到剪贴板');
    }
    openFolder = (values)=>{
        console.log(values)
        if(values.mode==2){
            this.props.history.push({
                pathname:`/admin/setnotification/${values.id}`,
                search:'name='+values.name
            })
        }else{
            this.props.history.push({
                pathname:`/admin/setnotification/detail/${values.parentId||this.state.formParam.parentId||0}/${values.id||0}`,
            })
        }

    }
    handleAction = ({ key, keyPath },record)=> {
        console.log(key, keyPath,record)
        if(key==1){
            this.editItemParam(record,'2')
        }else if(key==3){
            this.delMessageData(record)
        }else{//移动
            this.handleChangeMessageFile({...record,parentId:key})
        }
    }
    handleChangeMessageFile = (val)=>{
        moveConfigureClassMessage({parentId:val.parentId,messageClassId: val.id}).then(res=>{
            message.success('成功')
            this.initTableDataList()
        })
    }
    delMessageData = (record)=> {
        Modal.confirm({
            title: '提示',
            content: `确定要${record.status==1?'禁用':'启用'}这条数据吗?`,
            onOk: () => {
                delNotificationData({id:record.id}).then(res=>{
                    message.success('成功')
                    this.initTableDataList()
                })
            },
            onCancel: () => {

            }

        })

    }
    handleValueChange = (value)=>{
        console.log(value)
        let {status,...param} = this.state.formParam
        let setParam = value.status==null?param:{...param,...value}
        this.setState({
            formParam:{
                ...setParam
            }
        },()=>{
            this.initTableDataList()
        })

    }
    editNameDialog = (val)=>{
        this.formRef.current.setFieldsValue({...val})
        this.setState({
            folderDialogVisible:true,
            formDataParam:{...val}
        })

    }
    handleCloseModal = ()=>{
        console.log(this.formRef)
        this.formRef.current.resetFields()
        this.setState({
            folderDialogVisible:false,
            formDataParam:{}
        })
    }
    handleSubmitFolder = (val)=>{
        console.log(val)
        let param = {...this.state.formDataParam,...val}
        console.log(param)
        saveNotificationData(param).then(res=>{
            message.success('成功')
            this.initTableDataList()
            this.handleCloseModal()
        })
    }
    componentDidMount(){
        this.createColumns(columsKey);
        this.initTableDataList()
    }
    render() {
        const  locationQuery = new URLSearchParams(this.props.location.search)
        const defaultName = locationQuery.get('name')?locationQuery.get('name'):'';
        const { dataSource,columns, loading,visible,systemItem,formParam,folderDialogVisible} = this.state;
        return (
            <>
                <Card title={
                    <>
                        {
                            defaultName?
                                <>
                                    <span style={{color:'#999999',cursor:'pointer'}} onClick={()=>this.props.history.go(-1)}>配置消息</span>
                                    <span> / {defaultName}</span>
                                </>
                                :<span>配置消息</span>
                        }
                    </>
                } bordered={false} className={'card-fixed-head-wrap'}>
                    <div style={{display:"flex",justifyContent:'space-between'}}>
                        <Form layout="inline" initialValues={formParam} onValuesChange={this.handleValueChange}>
                            <Form.Item
                                name="status"
                            >
                                <Select
                                    style={{minWidth:'200px'}}
                                    allowClear
                                    showSearch
                                    placeholder="状态"
                                    filterOption={(input, option) =>
                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        {label:'全部',value:''},
                                        {label:'可用',value:1},
                                        {label:'禁用',value:0},
                                    ]}
                                />
                            </Form.Item>
                        </Form>
                        <Dropdown getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                  menu={
                                      {
                                          items:[
                                              {key:1,label:'新建文件夹',onClick:()=>this.editNameDialog({mode:2,name:'',id:null, parentId:this.state.formParam.parentId}),disabled:defaultName?true:false},
                                              {key:2,label:'新建配置消息',onClick:()=>this.openFolder({mode:1,name:''})},
                                          ]
                                      }
                                  } trigger={'click'}>
                            <Button type="primary" style={{marginBottom:'20px'}}>+ 新建</Button>
                        </Dropdown>
                        {/*<Button type="primary" style={{marginBottom:'20px'}} onClick={()=>this.editItemParam(systemItem)}>新增</Button>*/}

                    </div>
                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}/>
                    <div style={{display:'flex',justifyContent:'flex-end',margin:'20px 0'}}>
                        <Pagination size="small"
                                    hideOnSinglePage={true}
                                    current={this.state.formParam.pageNum}
                                    pageSize={this.state.formParam.pageSize}
                                    total={this.state.total}
                                    showSizeChanger
                                    onChange={(page, pageSize)=>{
                                        console.log(page, pageSize)
                                        let param = this.state.formParam
                                        param.pageSize = pageSize
                                        param.pageNum = page
                                        this.setState({
                                            formParam:{...param}
                                        })
                                        this.initTableDataList()
                                    }}
                        />

                    </div>
                </Card>
                <Modal destroyOnClose forceRender title={"新建/重命名"} open={folderDialogVisible} footer={false} onCancel={this.handleCloseModal}>
                    <Form
                        ref={this.formRef}
                        initialValues={this.state.formDataParam}
                        layout="horizontal"
                        onFinish={this.handleSubmitFolder}
                    >
                        <Form.Item name="name">
                            <Input placeholder="文件夹名称"/>
                        </Form.Item>
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </div>
                    </Form>
                </Modal>

            </>

        )
    }
}
export default withRouter(SetNotification)