import React, {Component} from 'react'
import { Card,Table,Tag } from 'antd';
import {apiGetRequest} from '../../http/http'

const columns = [
    {
        title: '角色名称',
        dataIndex: 'roleName',
        key: 'roleName',
    },
    {
        title: '角色标识',
        dataIndex: 'roleId',
        key:'roleId'
    },
    {
        title: '状态',
        dataIndex: 'status',
        render:(text,record,index)=>{
            return  text==1?<Tag color="green">有效</Tag>:<Tag color="magenta">无效</Tag>
        }
    },
    {
        title: '所属公司',
        dataIndex: 'platformGroupName',
        key: 'platformGroupName',
    },
    {
        title: '操作',
        dataIndex:'edit',
        render:(text, record, index)=>{
            return <Tag color="#cd201f">修改</Tag>
        }
    }
];
export default class List extends Component {
    componentDidMount(){
        apiGetRequest('lechun-baseservice/role/getMallRoleList',{userName: "",
            userNick: "",
            currentPage: 1,
            pageSize: 15,
            recordsTotal: 0
        }).then(res=>{
            this.setState({
                dataSource:res.list
            })
        })
    }
    state = {
        dataSource:[]
    };
    render() {
        return (
            <div>

                <Card title="Card title" bordered={false}>
                    <Table size="small" pagination={{hideOnSinglePage:true}} dataSource={this.state.dataSource} columns={columns} />
                </Card>
            </div>
        )
    }
}