import {nanoidSet} from '../../public/nanoidSet'

window.addOne =  ({ value, onChange, schema }) => {
    console.log('settingV',value)
    onChange([...value, { value: nanoidSet(4),label:'-' }]);
};

export const commonSetting = []
export const defaultCommonSetting = {
    "$id": {
        "title": "ID",
        "description": "字段名称/英文",
        "type": "string",
        "widget": "idInput",
        "require": true,
        "rules": [
            {
                "pattern": "^#/.+$",
                "message": "ID 必填"
            }
        ]
    },
    "title": {
        "title": "标题",
        "type": "string",
        "format": "textarea",
        widget:'MyReactQuill'
    },
    // "displayType": {
    //     "title": "标题展示模式",
    //     "type": "string",
    //     "enum": [
    //         "row",
    //         "column"
    //     ],
    //     "enumNames": [
    //         "同行",
    //         "单独一行"
    //     ],
    //     "widget": "radio"
    // },
    "description": {
        "title": "说明",
        "format": "textarea",
        "type": "string",
    },
    "default": {
        "title": "默认值",
        "type": "string"
    },
    "placeholder": {
        "title": "占位符",
        "type": "string"
    },
    "required": {
        "title": "是否必填",
        "type": "boolean",
        default:false
    },

    // "bind": {
    //     "title": "Bind",
    //     "type": "string"
    // },
    // "min": {
    //     "title": "最小值",
    //     "type": "number"
    // },
    // "max": {
    //     "title": "最大值",
    //     "type": "number"
    // },
    "disabled": {
        "title": "是否禁用",
        "type": "boolean"
    },
    "readOnly": {
        "title": "是否只读",
        "type": "boolean"
    },
    // "hidden": {
    //     "title": "是否隐藏",
    //     "type": "boolean",
    //     "widget":'myHidden'
    // },
    // "readOnlyWidget": {
    //     "title": "只读组件",
    //     "type": "string"
    // },
    // "width": {
    //     "title": "元素宽度",
    //     "type": "string",
    //     "widget": "percentSlider"
    // },
    // "labelWidth": {
    //     "title": "标签宽度",
    //     "description": "默认值120",
    //     "type": "number",
    //     "widget": "slider",
    //     "max": 400,
    //     "props": {
    //         "hideNumber": true
    //     }
    // }
}
export const resetCommonSetting = (newId)=>{
    return [
        // {
        //     "title": "判断组件",
        //     "widgets": [
        //         {
        //             "text": "是否选择",
        //             "name": "CheckOrNot",
        //             "schema": {
        //                 "title": "您认为和这位伙伴平时工作中是否有一定交集，因而可以给出以下评估?",
        //                 "type": "boolean",
        //                 "widget": "switch",
        //                 default:false,
        //                 required: true,
        //                 props:{
        //                     "checkedChildren":'是',
        //                     "unCheckedChildren":'否',
        //                     id:newId
        //                 }
        //
        //             },
        //             "setting": {
        //                 id:{
        //                     "title": "子Id",
        //                     "type": "string",
        //                     default:newId
        //                 },
        //                 "default": {
        //                     "title": "是否默认开启",
        //                     "type": "boolean"
        //                 }
        //             }
        //         }
        //     ],
        //     "show": true,
        //     "useCommon": true
        // },
        {
            "title": "基础组件",
            "widgets": [
                {
                    "text": "输入框",
                    "name": "FillBlank",
                    "schema": {
                        "title": "输入框",
                        "type": "string",
                        rules: [
                            {
                                message: '该题为必填',
                            },
                        ],
                        widget: 'MyInput',
                        props: {
                            id:newId,
                            rules: [
                                {
                                    message: '该题为必填1',
                                },
                            ],
                        }
                    },
                    "setting": {
                        "props": {
                            "title": "选项",
                            "type": "object",
                            "labelWidth": 80,
                            "properties": {
                                id:{
                                    "title": "子Id",
                                    "type": "string",
                                    default:newId
                                },
                                "allowClear": {
                                    "title": "是否带清除按钮",
                                    "description": "填写内容后才会出现x哦",
                                    "type": "boolean"
                                },
                                "addonBefore": {
                                    "title": "前tab",
                                    "type": "string"
                                },
                                "addonAfter": {
                                    "title": "后tab",
                                    "type": "string"
                                },
                                "prefix": {
                                    "title": "前缀",
                                    "type": "string"
                                },
                                "suffix": {
                                    "title": "后缀",
                                    "type": "string"
                                }
                            },

                        },

                        "minLength": {
                            "title": "最短字数",
                            "type": "number"
                        },
                        "maxLength": {
                            "title": "最长字数",
                            "type": "number"
                        },
                        "pattern": {
                            "title": "校验正则表达式",
                            "type": "string",
                            "props": {
                                "placeholder": "填写正则表达式"
                            }
                        }
                    }
                },
                {
                    "text": "大输入框",
                    "name": "Textarea",
                    "schema": {
                        "title": "编辑框",
                        "type": "string",
                        "format": "textarea",
                        props: {
                            id:newId
                        }
                    },
                    "setting": {
                        "props": {
                            "title": "选项",
                            "type": "object",
                            "labelWidth": 80,
                            "properties": {
                                id:{
                                    "title": "子Id",
                                    "type": "string",
                                    default:newId
                                },
                                "autoSize": {
                                    "title": "高度自动",
                                    "type": "boolean"
                                },
                                "rows": {
                                    "title": "指定高度",
                                    "type": "number"
                                }
                            }
                        },
                        "minLength": {
                            "title": "最短字数",
                            "type": "number"
                        },
                        "maxLength": {
                            "title": "最长字数",
                            "type": "number"
                        },
                        "pattern": {
                            "title": "校验正则表达式",
                            "type": "string",
                            "props": {
                                "placeholder": "填写正则表达式"
                            }
                        }
                    }
                },
                {
                    "text": "日期选择",
                    "name": "Date",
                    "schema": {
                        "title": "日期选择",
                        "type": "string",
                        "format": "date",
                        props: {
                            id:newId
                        }
                    },
                    "setting": {
                        id:{
                            "title": "子Id",
                            "type": "string",
                            default:newId
                        },
                        "format": {
                            "title": "格式",
                            "type": "string",
                            "enum": [
                                "dateTime",
                                "date",
                                "time"
                            ],
                            "enumNames": [
                                "日期时间",
                                "日期",
                                "时间"
                            ]
                        }
                    }
                },
                {
                    "text": "时间选择",
                    "name": "Time",
                    "show": false,
                    "schema": {
                        "title": "时间选择",
                        "type": "string",
                        "format": "time",
                        props: {
                            id:newId
                        }
                    },
                    "setting": {
                        id:{
                            "title": "子Id",
                            "type": "string",
                            default:newId
                        },
                        "format": {
                            "title": "格式",
                            "type": "string",
                            "enum": [
                                "dateTime",
                                "date",
                                "time"
                            ],
                            "enumNames": [
                                "日期时间",
                                "日期",
                                "时间"
                            ]
                        }
                    }
                },
                {
                    "text": "数字输入框",
                    "name": "Number",
                    "schema": {
                        "title": "数字输入框",
                        "type": "number",
                        props: {
                            id:newId
                        }
                    },
                    "setting": {
                        id:{
                            "title": "子Id",
                            "type": "string",
                            default:newId
                        },
                        "default": {
                            "title": "默认值",
                            "type": "number"
                        },
                        "min": {
                            "title": "最小值",
                            "type": "number"
                        },
                        "max": {
                            "title": "最大值",
                            "type": "number"
                        },
                    }
                },
                // {
                //     "text": "是否选择",
                //     "name": "Checkbox",
                //     "schema": {
                //         "title": "是否选择",
                //         "type": "boolean",
                //         "widget": "checkbox"
                //     },
                //     "setting": {
                //         "default": {
                //             "title": "是否默认勾选",
                //             "type": "boolean"
                //         }
                //     }
                // },
                {
                    "text": "是否switch",
                    "name": "Switchs",
                    "schema": {
                        "title": "是否选择",
                        "type": "boolean",
                        "widget": "switch",
                        props: {
                            id:newId
                        }
                    },
                    "setting": {
                        id:{
                            "title": "子Id",
                            "type": "string",
                            default:newId
                        },
                        "default": {
                            "title": "是否默认开启",
                            "type": "boolean"
                        }
                    }
                },
                {
                    "text": "下拉单选",
                    "name": "Select",
                    "schema": {
                        "title": "单选",
                        "type": "string",
                        "enum": [
                            newId
                        ],
                        "enumNames": [
                            "选项一",
                        ],
                        "widget": "select"
                    },
                    "setting": {
                        "enumList": {
                            "title": "选项配置",
                            "type": "array",
                            "widget": "cardList",
                            "className": "frg-options-list",
                            displayType:'row',
                            labelWidth:'50px',
                            "items": {
                                "type": "object",
                                "properties": {
                                    "value": {
                                        "title": "id",
                                        "type": "string",
                                        "className": "frg-options-input",
                                        "props":{},
                                        "placeholder": "id"
                                    },
                                    "label": {
                                        "title": "名称",
                                        "type": "string",
                                        "className": "frg-options-input",
                                        "props": {},
                                        // "default":'-',
                                        "placeholder": "名称",
                                        widget:'MyReactQuill'
                                    },
                                    "score": {
                                        "title": "分数",
                                        "type": "string",
                                        "className": "frg-options-input",
                                        "props": {},
                                        "placeholder": "分数"
                                    }
                                }
                            },
                            "props": {
                                "hideMove": true,
                                "hideCopy": true,
                                "hideAdd": true,
                                buttons: [{
                                    text:'新增一条',
                                    callback:'addOne'
                                }],
                            }
                        }
                    }
                },
                {
                    "text": "点击单选",
                    "name": "Radio",
                    "schema": {
                        "title": "单选",
                        "type": "string",
                        "enum": [
                            newId
                        ],
                        "enumNames": [
                            "选项一",
                        ],
                        "widget": "radio",
                    },
                    "setting": {
                        "enumList": {
                            "title": "选项配置",
                            "type": "array",
                            "widget": "cardList",
                            "className": "frg-options-list",
                            displayType:'row',
                            labelWidth:'50px',
                            "items": {
                                "type": "object",
                                "properties": {
                                    "value": {
                                        "title": "id",
                                        "type": "string",
                                        "className": "frg-options-input",
                                        "props":{},
                                        "placeholder": "id"
                                    },

                                    "score": {
                                        "title": "分数",
                                        "type": "number",
                                        "className": "frg-options-input",
                                        "placeholder": "分数"
                                    },
                                    "label": {
                                        "title": "名称",
                                        "type": "string",
                                        format:'textarea',
                                        "className": "frg-options-input",
                                        "props": {},
                                        // "default":'-',
                                        "placeholder": "名称",
                                        widget:'MyReactQuill'
                                    },
                                }
                            },
                            "props": {
                                "hideMove": true,
                                "hideCopy": true,
                                "hideAdd": true,
                                buttons: [{
                                    text:'新增一条',
                                    callback:'addOne'
                                }],
                            }
                        }
                    }
                },
                {
                    "text": "下拉多选",
                    "name": "MultiSelect",
                    "schema": {
                        "title": "多选",
                        "description": "下拉多选",
                        "type": "array",
                        "items": {
                            "type": "string"
                        },
                        "enum": [
                            newId
                        ],
                        "enumNames": [
                            "选项一",
                        ],
                        "widget": "multiSelect"
                    },
                    "setting": {
                        "default": {
                            "title": "默认值",
                            "type": "array",
                            "widget": "jsonInput"
                        },
                        "enumList": {
                            "title": "选项",
                            "type": "array",
                            "widget": "cardList",
                            displayType:'row',
                            labelWidth:'50px',
                            "className": "frg-options-list",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "value": {
                                        "title": "",
                                        "type": "string",
                                        "className": "frg-options-input",
                                        "props": {},
                                        "placeholder": "字段"
                                    },
                                    "label": {
                                        "title": "",
                                        "type": "string",
                                        "className": "frg-options-input",
                                        "props": {},
                                        "placeholder": "名称",
                                        widget:'MyReactQuill'
                                    }
                                }
                            },
                            "props": {
                                "hideMove": true,
                                "hideCopy": true,
                                "hideAdd": true,
                                buttons: [{
                                    text:'新增一条',
                                    callback:'addOne'
                                }],
                            }
                        }
                    }
                },
                {
                    "text": "点击多选",
                    "name": "Checkbox",
                    "schema": {
                        "title": "多选",
                        "type": "array",
                        "widget": "checkboxes",
                        "items": {
                            "type": "string"
                        },
                        "enum": [
                            newId
                        ],
                        "enumNames": [
                            "选项一",
                        ],
                    },
                    "setting": {
                        "default": {
                            "title": "默认值",
                            "type": "array",
                            "widget": "jsonInput"
                        },
                        "enumList": {
                            "title": "选项",
                            "type": "array",
                            "widget": "cardList",
                            displayType:'row',
                            labelWidth:'50px',
                            "className": "frg-options-list",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "value": {
                                        "title": "id",
                                        "type": "string",
                                        "className": "frg-options-input",
                                        "props": {},
                                        "placeholder": "字段"
                                    },
                                    "label": {
                                        "title": "名称",
                                        "type": "string",
                                        "className": "frg-options-input",
                                        "props": {},
                                        "placeholder": "名称",
                                        widget:'MyReactQuill'
                                    }
                                }
                            },
                            "props": {
                                "hideMove": true,
                                "hideCopy": true,
                                "hideAdd": true,
                                buttons: [{
                                    text:'新增一条',
                                    callback:'addOne'
                                }],
                            }
                        }
                    }
                },
                // {
                //     "text": "HTML",
                //     "name": "Html",
                //     "schema": {
                //         "title": "HTML",
                //         "type": "string",
                //         "widget": "html",
                //         props: {
                //             id:newId
                //         }
                //     },
                //     "setting": {
                //         "props": {
                //             "type": "object",
                //             "properties": {
                //                 id:{
                //                     "title": "子Id",
                //                     "type": "string",
                //                     default:newId
                //                 },
                //                 "value": {
                //                     "title": "展示内容",
                //                     "type": "string"
                //                 }
                //             }
                //         }
                //     }
                // }
            ],
            "show": true,
            "useCommon": true
        }
    ]
}
export const resetGlobalSettings = {
    "type": "object",
    "properties": {
        "column": {
            "title": "整体布局",
            "type": "number",
            "enum": [
                1,
                2,
                3
            ],
            "enumNames": [
                "一行一列",
                "一行二列",
                "一行三列"
            ],
            "props": {
                "placeholder": "默认一行一列"
            }
        },
        // "labelWidth": {
        //     "title": "标签宽度",
        //     "type": "number",
        //     "widget": "slider",
        //     "max": 300,
        //     "default": 120,
        //     "props": {
        //         "hideNumber": true
        //     }
        // },
        "displayType": {
            "title": "标签展示模式",
            "type": "string",
            "default": "row",
            "enum": [
                "row",
                "column"
            ],
            "enumNames": [
                "同行",
                "单独一行"
            ],
            "widget": "radio"
        }
    }
}
