
import {
    Dashboard,
    NotFound,
    Login,
    List,
    SysRole,
    SysUser,
    SysResource,
    Password,
    ControlSystem,
    PortalSystem,
    Notifications,
    NotificationsResult,NotificationsEmpty,NotificationsSurveyForm,
    MyField,
    GuanyuanBi,
    CalendarPage,
    ErpSystem,SetNotification,SetReply,SetNotificationDetail,
    GuanyuanPage,
    GuanyuanForm,
    LcPageSystem,
    ssoSystem,SurveyRender,OADashboard,SurveyList,FormPlayground,Class360,
    Subordinate,SubordinateKpiDetail,MyKpiDetail,SubordinatePerformanceDetail,MyPerformanceDetail,
    ScoreResult360,ScoreList,ScoreByList,SubordinateScore,MessageSys,EmployeeSys,SetScore,ScoreResult,
    KettlePassword,SetNoticeList
} from '../views'
export const mainRouter = [

];
export const portalRouter = [{
    pathname: '/portal/dashboard',
    component: PortalSystem,
    meta:{
        title:'portal',
        portalName:'Portal'
    },
},{
    pathname: '/portal/password',
    component: Password,
    meta:{
        title:'密码设置',
        portalName:'Password'
    },
},{
    pathname: '/portal/notifications',
    name:'Notification',
    state:{
        title:'消息中心',
        portalName:'Notification'
    },
    component: Notifications
},{
    pathname: '/portal/calendar',
    name:'Calendar',
    state:{
        title:'日历',
        portalName:'Calendar'
    },
    component: CalendarPage
},{
    pathname: '/portal/guanyuanbi',
    component: GuanyuanBi,
    meta:{
        title:'观远',
        portalName:'Portal'
    },
},{
    pathname: '/portal/erp',
    component: ErpSystem,
    meta:{
        title:'ERP',
        portalName:'Portal'
    },
},{
    pathname: '/portal/guanyuanpage',
    component: GuanyuanPage
},{
    pathname: '/portal/guanyuanform',
    component: GuanyuanForm
},{
    pathname: '/portal/lcpagesystem',
    component: LcPageSystem
},];

export const adminRouter = [{
    pathname: '/admin/dashboard',
    component: Dashboard,
    title:'仪表盘',
    isNav:true
},{
    pathname: '/admin/list',
    component: List,
    title:'列表',
    isNav:true
},{
    pathname: '/admin/sysuser',
    component: SysUser,
    title:'用户管理',
    isNav:true
},{
    pathname: '/admin/sysresource',
    component: SysResource,
    title:'资源管理',
    isNav:true
},{
    pathname: '/admin/sysrole',
    component: SysRole,
    title:'角色管理',
    isNav:true
},{
    pathname: '/admin/password',
    component: Password,
    title:'密码管理',
    isNav:true
},{
    pathname: '/admin/controlsystem',
    component: ControlSystem,
    title:'测试认证',
    isNav:true
},{
    pathname: '/admin/setnotification',
    component: SetNotification,
    title:'配置消息',
    isNav:true,
    exact:true
},{
    pathname: '/admin/setnotification/:id',
    component: SetNotification,
    title:'配置消息',
    isNav:true,
    exact:true
},{
    pathname: '/admin/setnotification/detail/:pid/:id',
    component: SetNotificationDetail,
    title:'配置消息详情',
    isNav:true,
    exact:true
},{
    pathname: '/admin/setreply',
    component: SetReply,
    title:'反馈处理',
    isNav:true
},{
    pathname: '/admin/setnoticelist',
    component: SetNoticeList,
    title:'公告设置',
    isNav:true
},{
    pathname: '/admin/ssosystem',
    component: ssoSystem,
    title:'sso配置',
    isNav:true
}];
export const oaRouter = [
    {
        pathname: '/oa/dashboard',
        component: OADashboard,
        title:'OA首页',
    },{
        pathname: '/oa/surveylist',
        component: SurveyList,
        title:'SurveyList',
        exact:true
    },{
        pathname: '/oa/surveylist/:id',
        component: SurveyList,
        title:'SurveyList',
        exact:true
    },{
        pathname: '/oa/surveylist/:pid/:id',
        component: SurveyRender,
        title:'SurveyRender',
        exact:true
    },{
        pathname: '/oa/class360',
        component: Class360,
        title:'360管理',
    },{
        pathname: '/oa/scoreresult360',
        component: ScoreResult360,
        title:'360打分汇总',
    },{
        pathname: '/oa/scorelist',
        component: ScoreList,
        title:'评分列表',
    },{
        pathname: '/oa/scorebylist',
        component: ScoreByList,
        title:'被评分列表',
    },{
        pathname: '/oa/subordinate',
        component: Subordinate,
        title:'我的下级',
        exact:true
    },{
        pathname: '/oa/subordinatescore',
        component: SubordinateScore,
        title:'下级评分',
        exact:true
    },{
        pathname: '/oa/subordinate/kpidetail/:id',
        component: SubordinateKpiDetail,
        title:'下级Kpi详情',
    },{
        pathname: '/oa/mykpidetail',
        component: MyKpiDetail,
        title:'我的KPI',
    },{
        pathname: '/oa/subordinate/performancedetail/:id',
        component: SubordinatePerformanceDetail,
        title:'下级绩效详情',
    },{
        pathname: '/oa/myperformance',
        component: MyPerformanceDetail,
        title:'我的绩效',
    },{
        pathname: '/oa/messagesys',
        component: MessageSys,
        title:'消息配置',
    },{
        pathname: '/oa/employeesys',
        component: EmployeeSys,
        title:'员工管理',
    },{
        pathname: '/oa/setscore',
        component: SetScore,
        title:'评分配置',
    },{
        pathname: '/oa/scoreresult',
        component: ScoreResult,
        title:'评分汇总',
    }
]
export const noPortalSide = [
    {
        pathname: '/portal/notificationresult/:id',
        component: NotificationsResult,
        title:'消息详情',
    },{
        pathname: '/portal/notificationsempty',
        component: NotificationsEmpty,
        title:'消息详情',
    },{
        pathname: '/portal/notificationsurveyform/:id',
        component: NotificationsSurveyForm,
        title:'问卷调查',
    },{
        pathname: '/portal/myfield',
        component: MyField,
        title:'消息详情',
    },{
        pathname: '/portal/kettle/password',
        component: KettlePassword,
        title:'设置密码',
    },{
        pathname: '/oa/formplayground/:id',
        component: FormPlayground,
        title:'FormPlayground',
    },{
        pathname: '/404',
        component: NotFound
    },
]
