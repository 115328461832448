import crypto from 'crypto'

export const getmd5 = (params='') =>{
    var md5 = crypto.createHash("md5");
    md5.update(params);
    return md5.digest('hex');
}
export const envHttpUrl = params => {
    console.log('env',process.env)
    const env = process.env.REACT_APP_SECRET_ENV
    let envUrl = {}
    switch (env){
        case 'development':
            envUrl.baseUrl = '//gatewaytest.lechun.cc'
            envUrl.webUrl = '//csmscheck.lechun.cc'
            envUrl.erpUrl = '//registertest.lechun.cc/erp/index.html'
            envUrl.portalUrl = '//amslocal.lechun.cc:3000'
            break
        case 'devproduction':
            envUrl.baseUrl = '//scmapi.lechun.cc'
            envUrl.webUrl = '//csms.lechun.cc'
            envUrl.erpUrl = '//scm.lechun.cc/erp/index.html'
            envUrl.portalUrl = '//portal.lechun.cc'
            break
        case 'protest':
            envUrl.baseUrl = '//gatewaytest.lechun.cc'
            envUrl.webUrl = '//csmscheck.lechun.cc'
            envUrl.erpUrl = '//registertest.lechun.cc/erp/index.html'
            envUrl.portalUrl = '//portalcheck.lechun.cc'
            break
        case 'production':
            envUrl.baseUrl = '//scmapi.lechun.cc'
            envUrl.webUrl = '//csms.lechun.cc'
            envUrl.erpUrl = '//scm.lechun.cc/erp/index.html'
            envUrl.portalUrl = '//portal.lechun.cc'
            break
    }
    return envUrl
}