import React, {Component} from 'react'
import {
    Card,Tag,
    Table,
    Button
} from 'antd';
import {getSubordinateKpiList} from '../../../request'
import {withRouter} from "react-router-dom";
import TransferModal from "../../../components/OA/TransferModal";
let columsKey = {
    index:'序号',
    currentPeriodName: '当前周期',
    userName: '员工',
    isLeader: 'Leader(我)角色',
    actions: '操作',
}
class Subordinate extends Component {
    employeeModal = React.createRef()
    surveyModal = React.createRef()
    state = {
        dataSource:[],
        dicList:{},
        SurveyQaList:[],
        columns:[],
        loading:false,
        visible:false,
        systemItem:{},
        formParam:{
            id:null,
            name:null
        },
        total:0,
        formDataParam:{
            name:''
        },
        detailDrawerVisible:false,
        editParam:{}
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='index'){
                return {
                    ...initObj,
                    align:'center',
                    width:50,
                    render:(text,record,index)=>{
                        return index+1
                    }
                }
            }else if(item=='isLeader'){
                return {
                    ...initObj,
                    align:'center',
                    width:200,
                    filters:[
                        {text:'直属Leader',value:'isLeader'},
                        {text:'部门Leader',value:'isSuperLeader'}
                    ],
                    filterSearch: false,
                    onFilter: (value, record) => {
                        return record[value] == true
                    },
                    render:(text,record,index)=> {
                        return (
                            <>
                                {
                                    text ? <Tag color="green" style={{marginRight: '10px'}}>直属Leader</Tag> : ''
                                }
                                {
                                    record.isSuperLeader ? <Tag color="red">部门Leader</Tag> : ''
                                }
                            </>
                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 280,
                    render: (text, record, index) => {
                        return (
                            <>
                                {
                                    record.isLeader ? <Button type="link" size="small"
                                                              onClick={() => (this.setKpi(record))}>设置KPI</Button> : ''
                                }
                                <Button type="link" size="small"
                                        onClick={() => (this.setPerformance(record))}>绩效评估</Button>
                                {
                                    record.isLeader ? <Button type="link" size="small"
                                                              onClick={() => (this.setPeers(record))}>360环评人员</Button> : ''
                                }


                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        console.log(this.state.formParam)
        getSubordinateKpiList({}).then(res=>{
            this.setState({
                loading: false,
                dataSource:res,
            })
        })
    }
    setKpi = (values)=>{
        this.props.history.push({
            pathname:`/oa/subordinate/kpidetail/${values.userId}`,
        })
    }
    setPerformance = (values)=>{
        this.props.history.push({
            pathname:`/oa/subordinate/performancedetail/${values.userId}`,
        })
    }
    setPeers = (values)=>{
        console.log(values,this.employeeModal)
        this.employeeModal.current.handleModalOpen({userId:values.userId,departmentShow:false})
    }
    componentDidMount(){
        console.log(this.props.history)
        this.createColumns(columsKey);
        this.initTableDataList()
    }
    render() {
        const { dataSource,columns, loading} = this.state;

        return (
            <>
                <Card title={'我的下级'} bordered={false} className={'card-fixed-head-wrap'}>
                    <Table
                        bordered={true}
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.userId}
                        loading={loading}/>
                </Card>
                <TransferModal ref={this.employeeModal} from={'employee'}  handleCloseModal={(val)=>console.log(val)}/>

            </>

        )
    }
}
export default withRouter(Subordinate)