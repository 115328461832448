import React, {Component} from 'react'
import {getmd5} from '../../http/globalMethod'

import {
    Card,
    Table,
    Button,
    Badge,
    Pagination,
    Modal,
    Form,
    Input,
    notification,
    Radio,
    Tag,
    Select,
    Space,
    Avatar
} from 'antd';
import {
    getMallUserList,
    saveOrUpdateMallUser,
    getUserDeparts,
    getUserListForDropbox,
    handleUpdateDingDingUser,
    getMallUserRoleList,
    deleteUserRole,
    getMallRoleList,
    saveUserRole
} from '../../request'
import * as Icon from '@ant-design/icons';
import './index.less'
const { Option } = Select;
const { confirm } = Modal;
let columsKey = {
    userName: '登录名',
    dingdingDeptId: '钉钉部门',
    platformNames: '所属平台',
    evalStatus: '离职后权限',
    createTime: '创建时间',
    status: '状态',
    actions: '操作'
}
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 6,
        span: 18,
    },
};
const initItemDetail = {
    // createTime: '',
    // deptId: [],
    // dingdingId: '',
    // financialDeptId: '',
    // isFinancialAdmin: '',
    // leader: "",
    // password: getmd5('123456'),
    // platformGroupId: 0,
    // platformGroupName: "",
    // platformId: [],
    // platformIds: '',
    // platformNames: "",
    // status: 1,
    // userId: "",
    // userName: "",
    // userNick: "",
    // userType: 1,
    // mobile:'',
    // evalStatus:'',
    // cashStatus:'',
}
const financialDeptlist = [
    {label:"产品中心",value:1},
    {label:"供应链中心",value:2},
    {label:"环境建设中心-财务",value:3},
    {label:"环境建设中心-京东",value:4},
    {label:"环境建设中心-人力行政",value:5},
    {label:"环境建设中心-战略",value:6},
    {label:"环境建设中心-战略第三方",value:7},
    {label:"门店-新业务",value:8},
    {label:"用户推广中心-渠道",value:9},
    {label:"用户运营中心-京东POP",value:10},
    {label:"用户运营中心-天猫",value:11},
    {label:"用户运营中心-微信",value:12},
]
const platFormList = [
    {
        "platformId": 1,
        "platformName": "乐纯微信商城"
    },
    {
        "platformId": 2,
        "platformName": "乐纯APP",
    },
    {
        "platformId": 3,
        "platformName": "乐纯PCWEB"
    },
    {
        "platformId": 4,
        "platformName": "乐纯小程序"
    },
    {
        "platformId": 5,
        "platformName": "乐纯WAP"
    }
]
export default class sysUser extends Component {
    state = {
        dataSource:[],
        departs:[],
        userDropList:[],
        departsTem:[],
        columns:[],
        roleList:[],
        userRoleList:{
            userId:'',
            roleIds:[]
        },
        defaultRoleList:[],
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {},
        loading:false,
        initItemDetail:{},
        visible:false,
        visibleRole:false,
        selDeptIds:[],
        formParam:{
            currentPage:1,
            pageSize:20,
        },
        total:0
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'center'
            }
            if(item=='userName'){
                return {
                    ...initObj,
                    align: 'left',
                    width: 300,
                    render:(text,record,index)=> {
                        return (
                            <>
                                <Space direction={'vertical'} size={10}>
                                    <Badge status="processing" text={
                                        <>
                                            <span className={'user-info-label'}>头像：</span><Avatar size={24} src={record.avatar} />
                                        </>
                                    }/>
                                    <Badge status="processing" text={
                                        <>
                                            <span className={'user-info-label'}>登录名：</span>{text}
                                        </>
                                    }/>
                                    <Badge status="processing" text={
                                        <>
                                            <span className={'user-info-label'}>昵称：</span>{record.userNick}
                                        </>
                                    }/>
                                </Space>

                            </>
                        )
                    }
                }
            }else if(item=='status'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return  <Badge color={text==1? '#52c41a' : '#ff4d4f'} count={text==1? '有效' : '无效'} />}
                }
            }else if(item=='evalStatus'){
                return {
                    ...initObj,
                    width: 150,
                    render:(text,record,index)=> {
                        return (
                            <>
                                <Badge style={{marginBottom: '10px'}} color="orange" text={
                                    <>
                                        是否可评分：{
                                            text == 1 ? <Icon.CheckCircleOutlined style={{ color: '#52c41a' }}/> : <Icon.CloseCircleOutlined style={{ color: '#ff4d4f' }}/>
                                        }
                                    </>
                                }/>
                                {
                                    record.cashStatus?
                                        <Badge color="purple" text={
                                            <>
                                                是否可领券：{
                                                record.cashStatus == 1 ? <Icon.CheckCircleOutlined style={{ color: '#52c41a' }}/> : <Icon.CloseCircleOutlined style={{color: '#ff4d4f' }}/>
                                            }
                                            </>
                                        }/>:''
                                }

                            </>
                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed:'right',
                    width:140,
                    render:(text, record, index)=>{
                        return (
                            <>
                                <Button type="link" size="small" onClick={()=>(this.handleItemEdit(record))}>修改</Button>
                                <Button type="link" size="small" onClick={()=>(this.handleItemRole(record))}>角色</Button>
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns:columns
        })
    }
    initTableDataList = (userName='',userNick='',pagination={})=>{
        this.setState({ loading: true });
        //用户列表
        getMallUserList({...this.state.formParam}).
        then(res=>{
            this.setState({
                loading: false,
                dataSource:res.list.map(item=>{
                    item.platformId = item.platformId.split(',').map(num=>parseInt(num))
                    return item
                }),
                total:res.total
            })
        })
    }
    initRoleList = ()=>{
        getMallRoleList({
            roleName:'',
            currentPage:1,
            pageSize:5000}).
        then(res=>{
            this.setState({
                roleList:res.list
            })
        })
    }
    handleItemEdit = (item)=>{
        console.log(this.state.selDeptIds)
        item.evalStatus = item.evalStatus==1?1:0
        item.status = item.status?item.status:1
        item.dingdingDeptId = item.dingdingDeptId?item.dingdingDeptId:'-'
        item.platformId = item.platformId?item.platformId:[]
        this.setState({
            visible:true,
            initItemDetail: {...item}
        })
    }
    handleUpdateDingDingUser = ()=>{
        handleUpdateDingDingUser({}).then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.initTableDataList();
        })
    }
    handleItemRole = (item)=>{
        getMallUserRoleList({userId:item.userId, currentPage:1, pageSize:50}).then(res=>{
            this.setState({
                visibleRole:true,
                defaultRoleList:res.list.map(value=>{
                    return value.roleId
                }),
                userRoleList:{
                    ...this.state.userRoleList,
                    userId:item.userId
                }

            })
        })
    }
    handleDelete = (item)=>{
        confirm({
            title: '提示',
            icon: <Icon.ExclamationCircleOutlined />,
            content: '你确定要删除用户吗?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                deleteUserRole({id:item.userId}).then(res=>{
                    notification.success({
                        message: '操作成功',
                    });
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    handleSubmit = (values)=>{
        const {initItemDetail} = this.state;
        console.log(values)
        values.platformId = values.platformId.join(',')
        // values.deptId = values.deptIdTem.length?values.deptIdTem[values.deptIdTem.length-1]:null
        this.setState({
            initItemDetail:{...initItemDetail,...values}
        })
        let {password,...params} = this.state.initItemDetail
        console.log('value',values,params)

        this.saveUserInfoFun(params)
    }
    saveUserInfoFun = (param)=>{
        console.log('param',param)
        saveOrUpdateMallUser({...param}).
        then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.setState({
                visible:false
            })
            this.initTableDataList()

        })
    }
    saveUserRoleAuth = (values)=>{
        const {userRoleList} = this.state;
        saveUserRole({roleIds: userRoleList.roleIds,userId: userRoleList.userId}).
        then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.setState({
                visibleRole:false
            })
            this.initTableDataList()

        })
    }
    handleCloseModel = ()=>{
        this.setState({
            visible:false,
            visibleRole:false,
        })
    }
    handlePageChange = (current,pageSize)=>{
        this.setState({
            formParam:{
                ...this.state.formParam,
                currentPage:current,
                pageSize:pageSize
            }
        })
        this.initTableDataList()
    }
    handleSelectRole = (value,option)=>{
        this.setState({
            userRoleList:{
                ...this.state.userRoleList,
                roleIds:value.join(',')
            }
        })
    }
    searchChange = (value,option)=>{
        console.log(value,option,this.state.formParam)
        let param = {...this.state.formParam}
        this.setState({
            formParam: {...param,...value}
        })

    }
    resetPassword = ()=>{
        //重置密码为123456
        let {initItemDetail} = this.state
        this.saveUserInfoFun({userId:initItemDetail.userId,password:getmd5('123456')})
    }
    setArrTreeData = (arr)=>{
        let tree = []
        arr.forEach((item) => {
            if (item.parentId === 0) {
                const rootNode = this.createNode(item);
                rootNode.treeIds = [item.id]
                this.findChildren(rootNode, arr);

                tree.push(rootNode);
            }
        });

        return tree;
    }
    // 创建一个树节点
    createNode = (data)=> {
        return {
            id: data.id,
            name: data.name,
            children: []
        };
    }
    findChildren = (node, arr)=> {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].parentId !== node.id) continue;

            const childNode = this.createNode(arr[i]);
            childNode.treeIds = [...node.treeIds,arr[i].id]
            this.findChildren(childNode, arr);

            node.children.push(childNode);
        }
    }
    componentDidMount(){
        this.createColumns(columsKey);
        this.initTableDataList()
        this.initRoleList()
        //获取部门
        getUserDeparts({}).then(res=>{
            let departsTem = []
            let departsTree = this.setArrTreeData(res||[])
            console.log('2',departsTree)
            this.setState({
                departs:departsTree,departsTem
            })
        })
        //下拉用户列表
        getUserListForDropbox().then(res=>{
            this.setState({
                userDropList:res
            })
        })
    }

    render() {
        const { dataSource,columns, loading,roleList ,defaultRoleList} = this.state;
        return (
            <div>
                <Card title="用户管理" bordered={false}
                      extra={
                          <>
                              <Button type="primary" onClick={()=>this.handleItemEdit({cashStatus: 1,evalStatus: 0})}>新增</Button>
                              <Button type="primary" style={{marginLeft: '10px'}} danger onClick={()=>this.handleUpdateDingDingUser()}>从钉钉同步</Button>

                          </>
                      }
                >
                    <div style={{display:"flex",justifyContent:'space-between'}}>
                        <Form initialValues={this.state.formParam} layout="inline" onValuesChange={(val)=>this.searchChange(val)}>
                            <Form.Item
                                style={{marginBottom:'20px'}}
                                name="userName"
                                label={'登录名'}
                            >
                                <Input placeholder="用户登录名" allowClear/>
                            </Form.Item>
                            <Form.Item
                                name="userNick"
                                label={'昵称'}
                            >
                                <Input
                                    placeholder="用户昵称" allowClear/>
                            </Form.Item>
                            <Form.Item
                                name="status"
                                label={'状态'}
                            >
                                <Select allowClear  placeholder="请选择状态">
                                    <Option value={1}>有效</Option>
                                    <Option value={-1}>无效</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button danger onClick={()=> {
                                    let param = this.state.formParam
                                    param.currentPage = 1
                                    this.setState({
                                        formParam:{...param}
                                    })
                                    this.initTableDataList()
                                }}>查询</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.userId}
                        loading={loading}/>
                    <div style={{display:'flex',justifyContent:'flex-end',margin:'20px 0'}}>
                        <Pagination size="small"
                                    hideOnSinglePage={true}
                                    current={this.state.formParam.currentPage}
                                    pageSize={this.state.formParam.pageSize}
                                    total={this.state.total}
                                    showSizeChanger
                                    onChange={(page, pageSize)=>{
                                        console.log(page, pageSize)
                                        let param = this.state.formParam
                                        param.pageSize = pageSize
                                        param.currentPage = page
                                        this.setState({
                                            formParam:{...param}
                                        })
                                        this.initTableDataList()
                                    }}
                        />

                    </div>
                </Card>
                <Modal
                    title="修改"
                    width="570px"
                    open={this.state.visible}
                    destroyOnClose={true}
                    onCancel={this.handleCloseModel}
                    footer={null}
                >
                    <div style={{height:'526px',overflowY:'scroll'}}>
                        <Form
                            labelCol={{ flex: '100px' }}
                            labelWrap
                            labelAlign="right"
                            preserve={false}
                            initialValues={this.state.initItemDetail}
                            onFinish={this.handleSubmit}
                        >
                            <Form.Item
                                label="userId"
                                name="userId"
                                hidden
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item
                                label="用户登录名"
                                name="userName"
                                rules={[{ required: true, message: '请输入用户登录名' }]}
                            >
                                <Input autoComplete="off" placeholder="请输入用户登录名"/>
                            </Form.Item>
                            <Form.Item
                                label="用户昵称"
                                name="userNick"
                                rules={[{ required: true, message: '请输入用户昵称' }]}
                            >
                                <Input placeholder="请输入用户昵称" />
                            </Form.Item>
                            <Form.Item
                                label="手机号码"
                                name="mobile"
                            >
                                <Input autoComplete="off" placeholder="请输入手机号码" />
                            </Form.Item>
                            <Form.Item
                                label="LEADER"
                                name="leader"
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="选择leader"
                                    fieldNames={{label: 'USER_NAME', value: 'USER_ID'}}
                                    filterOption={(input, option) =>
                                        (option?.USER_NAME ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.userDropList}
                                />
                            </Form.Item>
                            <Form.Item
                                label="所属钉钉部门"
                            >
                                {this.state.initItemDetail.dingdingDeptId}
                                {/*<Input autoComplete="off" />*/}
                                {/*<Cascader
                                options={this.state.departs}
                                fieldNames={{label: 'name', value: 'id'}}
                                placeholder="Please select" />*/}
                            </Form.Item>
                            <Form.Item
                                label="观远账号"
                                name="guanyuanAccount"
                            >
                                <Input autoComplete="off" placeholder="请输入观远账号" />

                            </Form.Item>
                            <Form.Item
                                label="是否财务口径管理"
                                name="isFinancialAdmin"
                            >
                                <Radio.Group>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label="财务口径所属部门"
                                name="financialDeptId"
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="选择财务口径所属部门"
                                    fieldNames={{label: 'label', value: 'value'}}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={financialDeptlist}
                                />
                            </Form.Item>

                            <Form.Item
                                label="离职后是否可评分"
                                name="evalStatus"
                            >
                                <Radio.Group>
                                    <Radio value={1}>可以</Radio>
                                    <Radio value={0}>不可以</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="是否允许离职后领券"
                                name="cashStatus"
                            >
                                <Radio.Group disabled={this.state.initItemDetail.status==1?true:false}>
                                    <Radio value={1}>允许</Radio>
                                    <Radio value={0}>不允许</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="所属公司"
                                name="platformGroupId"
                            >
                                <Select  placeholder="请选择所属公司">
                                    <Option value={0}>全部</Option>
                                    <Option value={1000}>乐纯</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="所属平台"
                                name="platformId">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    showSearch
                                    placeholder="选择所属平台"
                                    fieldNames={{label: 'platformName', value: 'platformId'}}
                                    filterOption={(input, option) =>
                                        (option?.platformName ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={platFormList}
                                />
                            </Form.Item>
                            <Form.Item
                                label="状态"
                                name="status"
                            >
                                <Radio.Group buttonStyle={'solid'}>
                                    <Radio.Button value={1}>有效</Radio.Button>
                                    <Radio.Button value={-1}>无效</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                <span style={{color:'#999999',fontSize:'10px'}}>（* 初始密码123456)</span>

                                <Button style={{margin:'0 10px'}} danger={true} onClick={this.resetPassword}>重置用户登录密码</Button>

                                <Button type="primary" htmlType="submit">
                                    保存用户信息
                                </Button>
                            </div>

                        </Form>
                    </div>

                </Modal>
                <Modal
                    title="我的角色"
                    width="570px"
                    open={this.state.visibleRole}
                    destroyOnClose={true}
                    onCancel={this.handleCloseModel}
                    footer={null}
                >
                    <Select
                        defaultValue={defaultRoleList}
                        mode="multiple"
                        allowClear
                        style={{width: '100%'}}
                        showSearch
                        placeholder="请选择"
                        fieldNames={{label: 'roleName', value: 'roleId'}}
                        filterOption={(input, option) =>
                            (option?.roleName ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        tagRender={(props)=>{
                            const { label, value, closable, onClose } = props;
                            return (
                                <Tag color={'#108ee9'} closable={closable} onClose={onClose}>{label}</Tag>
                            )
                        }}
                        onChange={this.handleSelectRole}

                        options={roleList}
                    />
                    {/*<Select*/}
                    {/*    mode="tags"*/}
                    {/*    showArrow*/}
                    {/*    size="large"*/}
                    {/*    placeholder="Please select"*/}
                    {/*    tagRender={(props)=>{*/}
                    {/*        const { label, value, closable, onClose } = props;*/}
                    {/*        return (*/}
                    {/*            <Tag color={'#108ee9'} closable={closable} onClose={onClose}>{label}</Tag>*/}
                    {/*        )*/}
                    {/*    }}*/}
                    {/*    onChange={this.handleSelectRole}*/}
                    {/*    style={{ width: '100%' }}*/}
                    {/*    defaultValue={defaultRoleList}*/}
                    {/*>*/}
                    {/*    {*/}
                    {/*        roleList.map(item=>{*/}
                    {/*            return (*/}
                    {/*                <Option key={item.roleId} value={item.roleId.toString()} obj={item}>{item.roleName}</Option>*/}
                    {/*            )*/}
                    {/*        })*/}
                    {/*    }*/}

                    {/*</Select>*/}
                    <Button style={{margin:'20px auto',display:'block'}} danger onClick={this.saveUserRoleAuth}>保存</Button>

                </Modal>
            </div>
        )
    }
}