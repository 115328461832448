import React, {Component} from 'react'
import {
    Select,
    Collapse,
    message,
    Badge,
    Tag,
    Tooltip,
    Button,
    PageHeader,
    Space,
    Form, Empty,Pagination
} from 'antd';
import {getNotificationsList,setNotificationsRead,handleNotifications,handleLinkNotifications,getNotificationsOptions} from '../../request'
import * as Icon from "@ant-design/icons";
import Markdown from 'markdown-to-jsx'
import './index.less'
const { Panel } = Collapse;
export default class Notifications extends Component {
    state = {
        notificationsList:[],
        messageSourceList:[],
        messageLevelList:[],
        messageTypeList:[],
        isReadList:[],
        statusList:[],
        isModalOpen:false,
        titleMessage:'请设置密码',
        password:'',
        formParam:{
            messageClassId:null,
            messageTitle:null,
            messageHead:null,
            messageSource:null,
            messageLevel:null,
            messageType:null,
            status:null,
            isRead:null,
            pageSize:20,
            pageNum:1
        },
        total:0,
    }
    initNotificationsList = ()=>{
        console.log(12,this.state.formParam)

        getNotificationsList({...this.state.formParam}).then(res=>{
            console.log(res)
            this.setState({
                notificationsList:res.list,
                total:res.total
            })
        })
    }
    initNotificationsOptions = ()=>{
        getNotificationsOptions({}).then(res=>{
            console.log(res)
            this.setState({
                messageSourceList:res.messageSourceList||[],
                messageLevelList:res.messageLevelList||[],
                messageTypeList:res.messageTypeList||[],
                isReadList:res.isReadList||[],
                statusList:res.statusList||[],

            })
        })
    }
    changeCollapse = (item,index)=>{
        let param = item
        if(!item.isRead){
            param.isRead = true
            console.log(param,item.isRead)
            this.setState({
                ['notificationsList['+index+']']:param
            })
            setNotificationsRead({messageUserId:item.messageUserId}).then((res)=>{

            })
        }


    }
    handleMessage = (item,nId)=>{
        console.log(item)
        handleNotifications({messageUserId:nId,actionId:item.id}).then(res=>{
            if(item.buttonAction==1){
                window.open(item.buttonUrl,'_blank')
            }else if(item.buttonAction==2){
                handleLinkNotifications(item.buttonUrl).then(res1=>{
                    message.success('success')
                })
            }

        })
    }
    handleValueChange = (value)=>{
        this.setState({
            formParam:{
                ...this.state.formParam,
                ...value
            }
        },()=>{
            this.initNotificationsList()
        })

    }
    componentDidMount(){
        this.initNotificationsList()
        this.initNotificationsOptions()
    }
    render() {
        const {messageSourceList, messageLevelList, messageTypeList, statusList} = this.state
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="消息通知中心"
                    subTitle=""
                />
                <div className='notification-wrap'>
                    <div style={{marginBottom: '20px'}}>
                        <Form
                            layout='inline'
                            onValuesChange={this.handleValueChange}
                        >
                            <Form.Item name="messageSource">
                                <Select
                                    allowClear
                                    style={{minWidth: '160px'}}
                                    showSearch
                                    placeholder="筛选消息来源系统"
                                    fieldNames={{label: 'name', value: 'code'}}
                                    filterOption={(input, option) =>
                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={messageSourceList}
                                />
                            </Form.Item>
                            <Form.Item name="messageLevel">
                                <Select
                                    allowClear
                                    style={{minWidth: '160px'}}
                                    showSearch
                                    placeholder="消息优先级"
                                    optionFilterProp="children"
                                    fieldNames={{label: 'name', value: 'code'}}
                                    filterOption={(input, option) =>
                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.messageLevelList}
                                />
                            </Form.Item>
                            <Form.Item name="messageType">
                                <Select
                                    allowClear
                                    style={{minWidth: '160px'}}
                                    showSearch
                                    placeholder="消息分类"
                                    optionFilterProp="children"
                                    fieldNames={{label: 'name', value: 'code'}}
                                    filterOption={(input, option) =>
                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.messageTypeList}
                                />
                            </Form.Item>
                            <Form.Item name="isRead">
                                <Select
                                    allowClear
                                    style={{minWidth: '160px'}}
                                    showSearch
                                    placeholder="是否未读"
                                    optionFilterProp="children"
                                    fieldNames={{label: 'name', value: 'code'}}
                                    filterOption={(input, option) =>
                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.isReadList}
                                />
                            </Form.Item>

                            {/*<Form.Item name="status">*/}
                            {/*    <Select*/}
                            {/*        allowClear*/}
                            {/*        style={{minWidth: '160px'}}*/}
                            {/*        showSearch*/}
                            {/*        placeholder="是否处理"*/}
                            {/*        optionFilterProp="children"*/}
                            {/*        fieldNames={{label: 'name', value: 'code'}}*/}
                            {/*        filterOption={(input, option) =>*/}
                            {/*            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())*/}
                            {/*        }*/}
                            {/*        options={this.state.statusList}*/}
                            {/*    />*/}
                            {/*</Form.Item>*/}
                        </Form>
                    </div>


                    <Collapse
                        accordion
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIconPosition={"end"}
                        showArrow={false}
                        expandIcon={({isActive}) => <Icon.CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                        style={{background: '#ffffff'}}
                    >
                        {
                            this.state.notificationsList.map((item, index) => {
                                return (
                                    <Panel key={item.messageUserId} onClick={() => this.changeCollapse(item, index)}
                                           extra={
                                               <>
                                                   <span style={{
                                                       marginRight: '10px',
                                                       color: '#2db7f5',
                                                       fontSize: '12px'
                                                   }}>来源：{item.messageSource}</span>
                                                   <span>{item.messageTime}</span>
                                               </>
                                           }
                                           header={
                                               <>
                                                   <Badge color={item.isRead ? "#ffffff" : ''}
                                                          style={{marginRight: '10px'}}
                                                          status={item.isRead ? "default" : "processing"}/>
                                                   <span
                                                       className={item.isRead ? '' : 'title-active'}><b>{messageTypeList[item.messageType - 1]?messageTypeList[item.messageType - 1].name:''}：</b>{item.messageTitle}</span>
                                                   {/*{*/}
                                                   {/*    messageTypeList.length > 0&&item.messageType?*/}
                                                   {/*        <Tag style={{marginLeft: '10px'}}*/}
                                                   {/*             color={item.messageType == 1 ? 'green' : 'cyan'}*/}
                                                   {/*             size='small'>{messageTypeList[item.messageType-1].name}</Tag>*/}
                                                   {/*        : ''*/}
                                                   {/*}*/}
                                                   {
                                                       messageLevelList.length > 0 && item.messageLevel < 2 ?
                                                           <Tag style={{marginLeft: '5px'}}
                                                                color={item.messageLevel == 0 ? 'red' : 'pink'}
                                                                size='small'>{messageLevelList[item.messageLevel].name}</Tag>
                                                           : ''
                                                   }

                                                   {/*{*/}
                                                   {/*    statusList.length > 0 && item.status == 1 ?*/}
                                                   {/*        <Tag style={{marginLeft: '5px'}}*/}
                                                   {/*             color={'#87d068'}*/}
                                                   {/*             size='small'>{statusList[item.status].name}</Tag>*/}
                                                   {/*        : ''*/}
                                                   {/*}*/}
                                                   <Tooltip title={item.dingError} placement="right">
                                                       <Tag style={{margin: '0 10px'}}
                                                            icon={item.dingCode == 200 ? <Icon.CheckCircleOutlined/> :
                                                                <Icon.CloseCircleOutlined/>}
                                                            color={item.dingCode == 200 ? "cyan" : "volcano"}>{item.pushDingding}</Tag>
                                                   </Tooltip>
                                               </>
                                           } className="site-collapse-custom-panel">
                                        <p className="notification-text">{item.messageHead}</p>
                                        {/*<p className="notification-text">{item.messageBody}</p>*/}
                                        <div className={'ant-table'}>
                                            <Markdown
                                                children={item.messageBody || ''}
                                                options={{
                                                    overrides: {
                                                        div: {
                                                            component: ({ children, ...props }) => <div {...props}>111{children}</div>,
                                                            props: {
                                                                className: 'foo',
                                                            },
                                                        },
                                                    },
                                                }}/>
                                        </div>
                                        <p className="notification-text">{item.messageTail}</p>
                                        <p className="notification-text">{item.messageRemark}</p>
                                        {
                                            item.actions.length > 0 ?
                                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <Space>
                                                        {
                                                            item.actions.map(item1 => {
                                                                return <Button key={item1.messageUserId}
                                                                               onClick={() => this.handleMessage(item1, item.messageUserId)}
                                                                               type="primary">{item1.buttonText}</Button>
                                                            })
                                                        }
                                                    </Space>

                                                </div>
                                                : ''

                                        }

                                    </Panel>
                                )
                            })
                        }
                    </Collapse>
                    {
                        this.state.notificationsList.length <= 0 ?
                            <Empty style={{margin: '150px 0 0'}} image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty> : ''
                    }
                    <div style={{display: 'flex', justifyContent: 'flex-end', margin: '20px 0'}}>
                        <Pagination size="small"
                                    hideOnSinglePage={true}
                                    current={this.state.formParam.pageNum}
                                    pageSize={this.state.formParam.pageSize}
                                    total={this.state.total}
                                    showSizeChanger
                                    onChange={(page, pageSize) => {
                                        console.log(page, pageSize)
                                        let param = this.state.formParam
                                        param.pageSize = pageSize
                                        param.pageNum = page
                                        this.setState({
                                            formParam: {...param}
                                        })
                                        this.initNotificationsList()
                                    }}
                        />

                    </div>
                </div>
            </>

        )
    }
}