import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import KpiDetail from "../../../components/OA/KpiDetail";

class SubordinateKpiDetail extends Component {
    state = {
        userId:this.props.match.params.id!=0?this.props.match.params.id:null,
    };
    componentDidMount(){
        console.log(this.props.history)

    }
    render() {
        return (
            <>
                <KpiDetail id={this.state.userId}/>
            </>

        )
    }
}
export default withRouter(SubordinateKpiDetail)