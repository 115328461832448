import React, {Component} from 'react'
import {
    Card,
    Table,
    Button,
    Tag,
    Modal,
    Form,
    Input,
    TreeSelect,
    notification,
    Radio,
    InputNumber,
    Select,
    Pagination
} from 'antd';
import {getMallResourceList,saveMallResource} from '../../request'
import * as Icon from '@ant-design/icons';

const { Option } = Select;
const { TreeNode } = TreeSelect;

let columsKey = {
    resourceName: '名称',
    resourceCode: '标识',
    resourceOrder: '排序',
    resourceIcon: '图标',
    resourceIconExt: '外部图标',
    resourceType: '类型',
    resourceHtmlUrl: '路由',
    status: '状态',
    actions: '操作'
}
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 6,
        span: 18,
    },
};
const initItemDetail = {
    resourceHtmlUrl: "",
    resourceIcon: "",
    resourceIconExt: "",
    resourceId: '',
    resourceName: "",
    resourceCode: "",
    resourceOrder: 1,
    resourceParentId: '',
    resourceType: 1,
    resourceUrl: "",
    status: 1,
}
export default class sysResource extends Component {
    state = {
        dataSource:[],
        columns:[],
        pagination: {
            current: 1,
            pageSize: 10,
        },
        initItemDetail,
        visible:false,
        loading:false,
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
            }
            if(item=='resourceIcon'){
                return {
                    ...initObj,
                    render:(text, record, index)=>{
                        return (text&&text.indexOf('el-')<=-1?
                                React.createElement( Icon && Icon[text],{
                                    style:{fontSize:'16px'}
                                })
                                :''
                        )
                    }
                }
            }else if(item=='resourceOrder'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return text
                    }
                }
            }else if(item=='resourceType'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        switch (text){
                            case 1:
                                return <Tag color="blue">菜单</Tag>
                            case 2:
                                return <Tag color="magenta">资源</Tag>
                            case 3:
                                return <Tag>按钮</Tag>
                            default:
                                return ''
                        }
                    }
                }
            }else if(item=='status'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return  text==1?<Icon.CheckCircleOutlined style={{ fontSize: '16px', color: 'green' }}/>:<Icon.CloseCircleOutlined style={{ fontSize: '16px', color: 'red' }} />
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed:'right',
                    width:120,
                    render:(text, record, index)=>{
                        return <Button type="link" size="small" onClick={()=>(this.handleItemEdit(record))}>修改</Button>
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns:columns
        })
    }
    initTableDataList = (resourceName='',pagination={})=>{
        this.setState({ loading: true });
        getMallResourceList({
            currentPage:pagination.current,
            pageSize:pagination.pageSize,
            resourceName:resourceName}).
        then(res=>{
            let newList = res.list.map(item=>{
                let {children,...remain} = item
                if(children.length>0){
                    item.children = children.map(itemC=>{
                        let {children,...remainC} = itemC
                        if(children.length>0){
                            itemC.children = children.map(itemCC=>{
                                let {children,...remainCC} = itemCC
                                if(children.length>0){
                                    itemCC.children = children.map(itemCCC=>{
                                        let {children,...remainCCC} = itemCCC
                                        return remainCCC
                                    })
                                    return itemCC
                                }else{
                                    return remainCC
                                }
                            })
                            return itemC
                        }else{
                            return remainC
                        }

                    })
                    return item
                }else{
                    return remain
                }

            })
            console.log(newList)
            this.setState({
                dataSource:newList,
                loading:false,
                pagination:{
                    ...pagination,
                    total:res.total
                }
            })
        })
    }
    handleItemEdit = (item)=>{
        let {children,...detail} = item
        this.setState({
            visible:true,
            initItemDetail:detail
        })
    }
    handleSubmit = (values)=>{
        const {pagination,initItemDetail} = this.state;
        values.resourceId = initItemDetail.resourceId
        saveMallResource(values).
        then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.setState({
                visible:false,
            })
            this.initTableDataList('',pagination)

        })
    }
    handlePageChange = (current,pageSize)=>{
        this.initTableDataList('',{current,pageSize})
    }
    handleCloseModel = ()=>{
        this.setState({
            visible:false,
        })
    }
    componentDidMount(){
        const {pagination} = this.state;
        this.createColumns(columsKey);
        this.initTableDataList('',pagination)
    }
    render() {
        const { dataSource,columns, visible, loading,pagination } = this.state;

        return (
            <div>
                <Card title="资源管理" bordered={false}>
                    <Button type="primary" style={{marginBottom:'20px'}} onClick={()=>this.handleItemEdit(initItemDetail)}>新增</Button>

                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.resourceId}
                        loading={loading}
                        transformCellText={(text)=>{
                            console.log('t',text)
                            return ''
                        }}
                        expandable={{
                            rowExpandable:(record)=>console.log(record),
                            indentSize:40
                        }}/>
                    <Pagination style={{float:'right',margin:'16px 0'}} hideOnSinglePage={true} size="small" {...pagination} onChange={this.handlePageChange}/>

                </Card>
                <Modal
                    title="修改"
                    open={visible}
                    destroyOnClose={true}
                    onCancel={this.handleCloseModel}
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        labelAlign="right"
                        preserve={false}
                        initialValues={this.state.initItemDetail}
                        onFinish={this.handleSubmit}
                    >
                        <Form.Item
                            label="类型"
                            labelAlign="right"
                            name="resourceType"
                        >
                            <Radio.Group value={this.state.initItemDetail.resourceType}>
                                <Radio value={1}>菜单</Radio>
                                <Radio value={2}>按钮</Radio>
                                <Radio value={3}>基础数据</Radio>
                                <Radio value={4}>账表</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="名称"
                            name="resourceName"
                            rules={[{ required: true, message: '请输入菜单名称' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="上级菜单"
                            name="resourceParentId"
                            rules={[{ required: true, message: '请选择上级菜单' }]}
                        >
                            <TreeSelect
                                showSearch
                                filterTreeNode={(inputValue,treeNode)=>{return (treeNode?.title ?? '').toLowerCase().includes(inputValue.toLowerCase()) }}
                                treeLine={{showLeafIcon:false}}
                            >
                                <TreeNode value={0} key={0} title={'一级菜单'} />
                                {dataSource.map(item=>{
                                    return (
                                        <TreeNode value={item.resourceId} key={item.resourceId} title={item.resourceName}>
                                            {(item.children||[]).map(item1=>{
                                                return (
                                                    <TreeNode value={item1.resourceId} key={item1.resourceId} title={item1.resourceName}>
                                                        {(item1.children||[]).map(item2=>{
                                                            return (
                                                                <TreeNode value={item2.resourceId} key={item2.resourceId} title={item2.resourceName} />
                                                            )
                                                        })}
                                                    </TreeNode>
                                                )
                                            })}
                                        </TreeNode>
                                    )
                                })}
                            </TreeSelect>
                        </Form.Item>
                        <Form.Item
                            label="资源Code"
                            name="resourceCode"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="资源路径"
                            name="resourceHtmlUrl"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="资源图标"
                            name="resourceIcon"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="外部图标"
                            name="resourceIconExt"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="排序"
                            name="resourceOrder"
                        >
                            <InputNumber value={this.state.initItemDetail.resourceOrder} min={1} max={50} />
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="status"
                        >
                            <Select style={{ width: '100%' }} value={this.state.initItemDetail.status}>
                                <Option value={1}>有效</Option>
                                <Option value={0}>无效</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}