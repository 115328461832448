import React, {Component,useState} from 'react'
import {Button, Modal, Form, Input, InputNumber, Popconfirm,Cascader, Table, Typography, Tag, Select, message} from 'antd';
import {get360ConnectSurveyList,getSurveyProjectTreeList,save360ConnectSurveyList} from '../../request'
let columsKey = {
    refType: '类型',
    projectId: '关联问卷',
    // actions: '操作',
}
class ConnectSurveyModal extends Component {
    state = {
        dialogVisible:false,
        dataSource:[],
        columns:[],
        surveyList:[],
        loading:false
    }

    initData = (values)=>{
        get360ConnectSurveyList({classId:values.id}).then(res=>{
            this.setState({
                dataSource:res
            })
        })
    }
    initSurveyList = (values)=>{
        getSurveyProjectTreeList({type:1}).then(res=>{
            this.setState({
                surveyList:res
            })
            this.createColumns(columsKey)
            this.initData(values)
        })
    }
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='projectId'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        console.log(text)
                        let projectAll = JSON.parse(record.projectAll)
                        return(
                            <>
                                <Cascader
                                    style={{width:'100%'}}
                                    defaultValue={projectAll}
                                    allowClear
                                    showSearch
                                    placeholder="选择问卷"
                                    optionFilterProp="children"
                                    fieldNames={{label: 'name', value: 'id',children:'child'}}
                                    filterOption={(input, option) =>
                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.surveyList}
                                    onChange={(val)=>this.saveSurvey(val,record)}
                                />
                            </>
                        )
                    }
                }
            }else if(item=='refType'){
                return {
                    ...initObj,
                    align: 'center',
                    render:(text,record,index)=>{
                        return(

                            text==1?
                                <Tag color="cyan">peers</Tag>
                                :
                                (text==2?<Tag color="red">leader</Tag>
                                    :
                                        (text==3?<Tag color="blue">下级</Tag>:<Tag color="green">自己</Tag>)
                                )
                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 180,
                    render: (text, record, index) => {
                        return (
                            <>
                                <Button type="link" size="small" onClick={() => (this.openSetting(record))}>保存</Button>
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    handleTransferChange = (targetKey,directions)=>{
        console.log('transferC',targetKey,directions)
    }
    handleModalOpen = (values)=>{
        this.setState({
            dialogVisible:true
        })
        this.initSurveyList(values)

    }
    handleCloseModal = ()=>{
        this.setState({
            dialogVisible:false,
            dataSource:[]
        })
    }
    saveSurvey = (val,record)=>{
        console.log(val,record)
        let projectId = val?val[val.length-1]:null
        save360ConnectSurveyList({
            ...record,projectId,projectAll:JSON.stringify(val||[])
        }).then(res=>{
            message.success('关联成功')
        })
    }
    componentDidMount(){
        console.log('current', this.props);
    }
    render() {
        const { dataSource,columns, loading} = this.state;

        return (
            <>
                <Modal width={'50%'} destroyOnClose forceRender title={"设置问卷"} open={this.state.dialogVisible} footer={false} onCancel={this.handleCloseModal}>

                    <Table
                        bordered={true}
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}/>

                </Modal>
            </>


        )
    }
}
export default ConnectSurveyModal