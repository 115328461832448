import React, {Component} from 'react'

import {Layout, Dropdown, Menu, Avatar, Tooltip} from 'antd';
import { withRouter} from 'react-router-dom'
import * as Icon from "@ant-design/icons";
const { Header} = Layout;

class PublicHeader extends Component {
    state = {
        collapsed: false,
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    componentDidMount() {
        console.log(this.props)
    }
    render() {
        return (
            <Header className="site-layout-background header-section" >
                <div className="logo-wrap">
                    <div className="logo">{this.props.title}</div>
                    {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: this.toggle,
                    })}
                </div>
            </Header>
        )
    }
}
export default withRouter(PublicHeader)