import React, {Component} from 'react'
import {
    Card,
    Tooltip,
    Table,
    Button,
    message,
    notification,
    Tag,
    Form,
    Input, Modal, Switch
} from 'antd';
import {getControlSystemParam, getControlSystemList, saveControlSystem} from '../../request'

let columsKey = {
    id: '编码',
    name: '名称',
    urlParam: '认证码',
    status:'登录控制状态',
    controlStatus:'权限控制状态',
    time: '有效期到',
    actions: '操作',
}
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 6,
        span: 18,
    },
};
export default class ControlSystem extends Component {
    state = {
        dataSource:[],
        columns:[],
        loading:false,
        visible:false,
        systemItem:{}
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            console.log('key',item)
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'center'
            }
            if(item=='urlParam'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            record.urlParam?
                            <Tooltip title={record.urlParam} color={"pink"}>
                                <Button size={"small"} type={"primary"} onClick={()=>this.copyTestCode(record.urlParam)}>复制测试码</Button>
                            </Tooltip>:''

                        )
                    }
                }
            }else if(item=='status'){
                return {
                    ...initObj,
                    render:(text,record)=>{
                        return(
                            <Tag color={text==1?'green':'red'}>{text==1?'有效':'无效'}</Tag>
                        )
                    }
                }

            }else if(item=='controlStatus'){
                return {
                    ...initObj,
                    render:(text,record)=>{
                        return(
                            <Tag color={text==1?'green':'red'}>{text==1?'有效':'无效'}</Tag>
                        )
                    }
                }

            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed:'right',
                    width:140,
                    render:(text, record, index)=>{
                        return (
                            <>
                                <Button type="link" size="small" onClick={()=>(this.editItemParam(record))}>编辑</Button>
                                <Button type="link" size="small" onClick={()=>(this.handleItemParam(record))}>获取参数</Button>
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        //角色列表
        getControlSystemList().
        then(res=>{
            this.setState({
                loading: false,
                dataSource:res
            })
        })
    }
    editItemParam = (values)=>{
        console.log(values)
        this.setState({
            visible:true,
            systemItem:{
                code:values.id,
                name:values.name,
                status:values.status,
                controlStatus:values.controlStatus||false,
                remark:values.remark
            }
        })
    }
    handleCloseModel = ()=>{
        this.setState({
            visible:false,
        })
    }
    handleItemParam = (values)=>{
        getControlSystemParam({ sysCode:values.id}).
        then(res=>{
            notification.success({
                message: '获取成功',
            });
            this.initTableDataList()
        })
    }
    handleStatusChange = (checked,e)=>{
        console.log(checked,e)
        let stateItem = this.state.systemItem
        this.setState({
            systemItem:{
                ...stateItem,
                status:checked?1:0
            }
        })
    }
    handleControlStatusChange = (checked,e)=>{
        console.log(checked,e)
        let stateItem = this.state.systemItem

        this.setState({
            systemItem:{
                ...stateItem,
                controlStatus:checked?1:0
            }
        })
    }
    handleSubmit = (values)=>{
        const {systemItem} = this.state;
        console.log(systemItem,values)
        values.controlStatus = values.controlStatus?1:0
        values.status = values.status?1:0
        saveControlSystem(values).
        then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.setState({
                visible:false,
            })
            this.initTableDataList()

        })
    }
    copyTestCode = (text)=>{
        console.log(text)
        var input = document.createElement("textarea"); // 创建input对象
        input.value = text; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        message.success('已复制到剪贴板');
    }
    componentDidMount(){
        this.createColumns(columsKey);
        this.initTableDataList()
    }
    render() {
        const { dataSource,columns, loading,visible,systemItem} = this.state;
        return (
            <div>
                <Card title="测试认证" bordered={false}>
                    <Button type="primary" style={{marginBottom:'20px'}} onClick={()=>this.editItemParam(systemItem)}>新增</Button>

                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}/>
                </Card>
                <Modal
                    title="修改"
                    open={visible}
                    destroyOnClose={true}
                    onCancel={this.handleCloseModel}
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        labelAlign="right"
                        preserve={false}
                        initialValues={systemItem}
                        onFinish={this.handleSubmit}
                    >
                        <Form.Item
                            label="编码"
                            name="code"
                            extra={<span style={{fontSize:'12px'}}>* 编码取网关后的第一个路径，如：http://gatewaytest.lechun.cc/lechun-tools/module/getModuleList，取：lechun-tools</span>}
                            rules={[{ required: true, message: '请输入编码' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="名称"
                            name="name"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="备注"
                            name="remark"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="登录控制状态"
                            name="status"
                        >
                            <Switch
                                checked={this.state.systemItem.status}
                                onChange={this.handleStatusChange}
                                checkedChildren="有效" unCheckedChildren="无效">
                            </Switch>
                        </Form.Item>
                        <Form.Item
                            label="权限控制状态"
                            name="controlStatus"
                        >
                            <Switch
                                checked={this.state.systemItem.controlStatus}
                                onChange={this.handleControlStatusChange}
                                checkedChildren="有效" unCheckedChildren="无效">
                            </Switch>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>

        )
    }
}