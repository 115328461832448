import React, {Component,useState} from 'react'
import {Button, message, Modal, Transfer,Tabs} from 'antd';
import {get360ClassUserList,getUserRefUsers,save360ClassUser,saveUserRefPeers} from '../../request'
import {cloneDeep, handleLeftTreeData,handleRightTreeData} from "../../public/tree";
import {Redirect, Route, Switch, withRouter,useRouteMatch,useLocation} from 'react-router-dom'
import TransferTree from './TransferTree'

class TransferModal extends Component {
    state = {
        dialogVisible:false,
        userData:[],
        userTargetData:[],
        currentItem:{},
        deptTargetData: [],
        deptData: [],
        deptLeftTreeData: [],
        deptRightTreeData: [],
        deptLeftCheckedKeys: [],
        deptLeftCheckedHalfKeys: [],
        deptLeftCheckedAllKeys: [],
        deptRightCheckedKeys: [],
        deptRightCheckedAllKeys: [],
        tabItems:[]
    }
    initData = (values)=>{
        if(this.props.from == 'employee'){
            getUserRefUsers({ refType:1,userId:values.userId}).then(res=>{
                this.setState({
                    userData:res.userList,
                    userTargetData:res.peers||res['协作者'],
                })
            })
        }else{
            get360ClassUserList({classId:values.id,userId:values.userId}).then(res=>{
                let selectDepts = res.selectDepts
                let deptTreeData = this.setDataParentIdKey(res.deptList,0)
                this.setState({
                    userData:res.userList,
                    userTargetData:res.selectUsers,
                    deptData: deptTreeData,
                    deptTargetData: selectDepts,
                    deptLeftTreeData: handleLeftTreeData(cloneDeep(deptTreeData),selectDepts),
                    deptRightTreeData: handleRightTreeData(cloneDeep(deptTreeData),selectDepts),
                    deptLeftCheckedKeys: selectDepts
                })
            })
        }

    }
    handleLeftCheck = (checkedKeys,node,halfCheckedKeys)=>{
        let {deptLeftCheckedHalfKeys} = this.state
        this.setState({
            deptLeftCheckedKeys: checkedKeys,
            deptLeftCheckedAllKeys: [...deptLeftCheckedHalfKeys,...halfCheckedKeys,...checkedKeys],
            deptLeftCheckedHalfKeys: [...deptLeftCheckedHalfKeys,...halfCheckedKeys],
        })
    }
    handleRightCheck = (checkedKeys,node,halfCheckedKeys)=>{
        let {deptLeftCheckedHalfKeys} = this.state
        this.setState({
            deptRightCheckedKeys: checkedKeys,
            deptRightCheckedAllKeys: [...deptLeftCheckedHalfKeys.value,...halfCheckedKeys,...checkedKeys]
        })

    }
    handleTransferChange = (targetKeys,directions,type)=>{
        console.log('transferC',targetKeys,directions,type)
        let {deptTargetData,deptLeftCheckedHalfKeys,deptLeftCheckedKeys,deptLeftCheckedAllKeys,deptData,deptRightTreeData,deptLeftTreeData,deptRightCheckedKeys } = this.state
        switch(type){
            case 'deptId':
                if(directions=='right'){
                    this.setState({
                        deptTargetData:deptLeftCheckedAllKeys,
                        deptRightCheckedKeys:[],
                        deptRightTreeData: handleRightTreeData(cloneDeep(deptData),deptLeftCheckedAllKeys,'right'),
                        deptLeftTreeData: handleLeftTreeData(cloneDeep(deptData),deptLeftCheckedKeys,'right')

                    })
                }else if(directions == 'left'){
                    this.setState({
                        deptRightTreeData: handleRightTreeData(cloneDeep(deptRightTreeData),deptRightCheckedKeys,'left'),
                        deptLeftTreeData: handleLeftTreeData(cloneDeep(deptLeftTreeData),deptRightCheckedKeys,'left'),
                        deptTargetData: deptTargetData.value.filter((item)=>deptRightCheckedKeys.indexOf(item)===-1),
                        deptLeftCheckedKeys: deptLeftCheckedKeys.value.filter((item)=>deptRightCheckedKeys.indexOf(item)===-1),
                        deptLeftCheckedHalfKeys: deptLeftCheckedHalfKeys.value.filter((item)=>deptRightCheckedKeys.indexOf(item)===-1),
                        deptRightCheckedKeys: []
                    })
                }

                break;
            case 'USER_ID':
                this.setState({
                    userTargetData:targetKeys
                })
                break;

        }
    }
    setDataParentIdKey = (data,parentId)=>{
        data = (data||[]).map((item)=>{
            item.parentId = parentId
            if(item?.children){
                this.setDataParentIdKey(item.children,item.deptId)
            }
            return item
        })
        return data
    }
    handleModalOpen = (values)=>{
        console.log(values)
        this.setState({
            dialogVisible:true,
            currentItem:values
        })
        this.initData(values)
    }
    handleCloseModal = ()=>{
        this.setState({
            dialogVisible:false,
            userData:[]
        })
        this.props.handleCloseModal()
    }
    submitTransfer = (targets,type)=>{
        let {id,userId} = this.state.currentItem
        if(this.props.from == 'employee'){
            saveUserRefPeers({ userId:userId,  peerIds:targets}).then(res=>{
                message.success('保存成功')
                this.handleCloseModal()
            })
        }else{
            save360ClassUser({classId:id, selectUsers:type?[]:targets, selectDepts:type?targets:[]}).then(res=>{
                message.success('保存成功')
                this.handleCloseModal()
            })
        }

    }
    componentDidMount(){
        console.log('current', this.props);
    }
    render() {
        const {currentItem,deptData,deptTargetData,deptLeftTreeData,deptRightTreeData,deptLeftCheckedKeys,deptRightCheckedKeys} = this.state
        let disabledState = false
        console.log(disabledState)
        return (
            <>
                <Modal destroyOnClose forceRender title={"添加/设置人员"} open={this.state.dialogVisible} footer={false} onCancel={this.handleCloseModal}>
                    <Tabs type={'card'}>
                        <Tabs.TabPane tab={'按员工'} key={'1'}>
                            <TransferTree
                                disabled={disabledState}
                                title={"USER_NICK"}
                                rowKey={"USER_ID"}
                                targetDataKeys={this.state.userTargetData}
                                treeData={this.state.userData}
                                titles={[' 未选',' 已选']}
                                handleTransferChange={this.handleTransferChange}
                            />
                            {
                                !disabledState?<div style={{display: 'flex',justifyContent: 'flex-end',marginTop: '20px'}}>
                                    <Button disabled={disabledState} onClick={()=>this.submitTransfer(this.state.userTargetData)} type="primary">保存员工</Button>
                                </div>:''
                            }
                        </Tabs.TabPane>
                        {
                            this.state.currentItem.departmentShow?<Tabs.TabPane tab={'按部门'} key={'2'}>
                                <TransferTree
                                    title={"deptName"}
                                    rowKey={"deptId"}
                                    targetDataKeys={deptTargetData}
                                    treeData={deptData}
                                    titles={[' 未选',' 已选']}
                                    leftTreeData={deptLeftTreeData}
                                    rightTreeData={deptRightTreeData}
                                    leftCheckedKeys={deptLeftCheckedKeys}
                                    rightCheckedKeys={deptRightCheckedKeys}
                                    handleLeftCheck={this.handleLeftCheck}
                                    handleRightCheck={this.handleRightCheck}
                                    handleTransferChange={this.handleTransferChange}
                                />
                                <div style={{display: 'flex',justifyContent: 'flex-end',marginTop: '20px'}}>
                                    <Button onClick={()=>this.submitTransfer(deptTargetData,'dept')} type="primary" danger>保存部门</Button>
                                </div>
                            </Tabs.TabPane>:''
                        }
                    </Tabs>

                </Modal>
            </>


        )
    }
}
export default TransferModal