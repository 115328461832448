import React, {Component} from 'react'
import {
    Card,
    Button,
    message, Form, Input, Collapse, InputNumber, Select,
} from 'antd';
import {get360SurveySetting, save360SurveySetting,get360SurveyQaList} from '../../request'
import {withRouter} from "react-router-dom";
const { Panel } = Collapse;

class SetScore extends Component {
    formRef = React.createRef()
    state = {
        dataSource: {},
        loading:false,
        qaList:[]
    };
    initTableDataList(){
        this.setState({ loading: true });
        get360SurveySetting({}).then(res=>{
            let paramInfo = res
            paramInfo.suggestQuestionIds = JSON.parse(res.suggestQuestionIds||"[]")
            this.setState({
                loading: false,
                dataSource:res
            })
            this.formRef.current.setFieldsValue(res)
        })
    }
    init360SurveyQaList(){
        this.setState({ loading: true });
        get360SurveyQaList({}).then(res=>{
            this.setState({
                loading: false,
                qaList:res
            })
        })
    }
    handlePushFilterValueChange = (changedValues)=>{
        console.log(changedValues)
        let param = changedValues
        param.suggestQuestionIds = JSON.stringify(changedValues.suggestQuestionIds)
        save360SurveySetting(param).then(res=>{
            message.success('保存成功')
            this.initTableDataList()
        })
    }
    componentDidMount(){
        this.init360SurveyQaList()
        this.initTableDataList()
    }
    render() {
        console.log(this.state.dataSource)
        return (
            <>
                <Card title={'评分配置'} bordered={false} className={'card-fixed-head-wrap'}>
                    <Collapse defaultActiveKey={1}>
                        {
                                <Panel forceRender header={'360评分'} key={1}>
                                    <Form labelCol={{flex:'60px' }} ref={this.formRef} initialValues={this.state.dataSource}
                                          onFinish={(changedValues, allValues)=>this.handlePushFilterValueChange(changedValues, allValues)}
                                    >
                                        <Form.Item hidden name='id'>
                                            <input/>
                                        </Form.Item>
                                        <Form.Item label={'名称'} name={'name'}>
                                            <Input.TextArea />
                                        </Form.Item>

                                        <Form.Item label={'总分'} name={'totalScore'}>
                                            <InputNumber disabled={true} />
                                        </Form.Item>
                                        <Form.Item label={'题分值'} name={'score'}>
                                            <InputNumber />
                                        </Form.Item>
                                        <Form.Item label={'主观题'} name={'suggestQuestionIds'}>
                                            <Select
                                                allowClear
                                                style={{minWidth: '160px'}}
                                                showSearch
                                                mode="multiple"
                                                placeholder="请选择"
                                                optionFilterProp="children"
                                                fieldNames={{label: 'name', value: 'id'}}
                                                filterOption={(input, option) =>
                                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={this.state.qaList}
                                            />
                                        </Form.Item>
                                        <Form.Item label={'权重'} name={'rate'}>
                                            <InputNumber disabled={true} />
                                        </Form.Item>
                                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                                            <Form.Item
                                            >
                                                <Button type="primary" htmlType="submit">
                                                    保存
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </Panel>
                        }
                    </Collapse>
                </Card>
            </>

        )
    }
}
export default withRouter(SetScore)