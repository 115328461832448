import React, {Component} from 'react'
import {
    Typography,Table, Card
} from 'antd';
import {getFields} from '../../request'
import * as Icon from "@ant-design/icons";
import './index.less'
export default class MyField extends Component {
    state = {
        dataSource:[],
        projectList:[],
        formParam:{
            projectId:'',
        },
        loading:false
    }
    createColumns = ()=>{
        let columns = [
            {
                title:'任务名称',
                dataIndex:'TASK_NAME',
                key:'TASK_NAME',
                align:'left',
            },{
                title:'任务类型',
                dataIndex:'FIELD_TYPE_NAME',
                key:'FIELD_TYPE_NAME',
                align:'left',
                width:100
            },{
                title:'任务内容',
                dataIndex:'TITLE',
                key:'TITLE',
                align:'left',
                minWidth:90,
                render:(text,record,index)=>{
                    return(
                        <>
                            {
                                record.FIELD_TYPE_NAME=='钉钉文档'||record.FIELD_TYPE_NAME=='附件'?
                                    <Typography.Link onClick={()=>this.handleContext(record.FIELD_TYPE_NAME=='附件'?record.TITLE_DOWNLOAD_URL:record.TITLE_URL)}>
                                        {record.TITLE_DOWNLOAD_URL?<Icon.DownloadOutlined />:<Icon.FolderOpenOutlined /> }
                                        <span style={{textDecoration:'underline',marginLeft:'8px'}}>{text}</span>
                                    </Typography.Link>
                                    :
                                    <Typography.Text>{text}</Typography.Text>
                            }
                        </>
                    )
                }
            },

        ]
        this.setState({
            columns,
        })
    }
    initTableData = ()=>{
        this.setState({ loading: true });

        getFields({...this.state.formParam}).
        then(res=>{
            this.setState({
                dataSource:res,
                loading: false
            });
        })
    }
    handleContext = (url)=>{
        console.log(url)
        window.open(url,'_blank')
    }
    componentDidMount(){
        this.createColumns();
        const locationQuery = new URLSearchParams(this.props.location.search)
        let param = this.state.formParam
        param.projectId = locationQuery.get('project')
        this.setState({
            formParam:{...param}
        })
        this.initTableData()
    }
    render() {
        const {dataSource, columns,loading} = this.state
        return (
            <div>
                <Card title="项目信息概览" bordered={false}>
                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        loading={loading}/>
                </Card>
            </div>

        )
    }
}