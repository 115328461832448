import React, {Component} from 'react'
import {
    Card,
    Tooltip,
    Table,
    Button,
    message,
    notification,
    Space,
    Tag,
    Form,
    Radio,
    Input,
    TreeSelect,
    InputNumber, Modal, Switch, Popconfirm, Pagination
} from 'antd';
import {getAnnouncementList, saveAnnouncement} from '../../request'
import * as Icon from '@ant-design/icons';

let columsKey = {
    index: '序号',
    type: '类型',
    tag: '公告属性',
    title: '标题',
    announcementLink: '链接',
    status: '状态',
    updatedAt: '更新时间',
    action: '操作',
}
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 6,
        span: 18,
    },
};
export default class SetNoticeList extends Component {
    state = {
        dataSource:[],
        columns:[],
        loading:false,
        visible:false,
        systemItem:{},
        searchParam:{
            pageSize:20,
            currentPage:1
        },
        formParam:{

        },
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            console.log('key',item)
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'center'
            }
            if(item=='index'){
                return {
                    ...initObj,
                    align:'center',
                    width:60,
                    render:(text,record,index)=>{
                        return index+1
                    }
                }
            }else if(item=='type'){
                return {
                    ...initObj,
                    render:(text,record)=>{
                        return(
                            <>
                                {
                                    text==1?<Tag color={'#e50011'}>公告</Tag>:<Tag color='#108ee9'>使用帮助</Tag>
                                }
                            </>


                        )
                    }
                }

            }else if(item=='tag'){
                return {
                    ...initObj,
                    render:(text,record)=>{
                        return(
                            <Tag>{text}</Tag>
                        )
                    }
                }

            }else if(item=='announcementLink'){
                return {
                    ...initObj,
                    width: 200,
                    ellipsis:true,
                }

            }else if(item=='updatedAt'){
                return {
                    ...initObj,
                    render: (text, record) => {
                        return (
                            <>{record.updatedAt || record.createdAt}</>
                        )
                    }
                }

            }else if(item=='status'){
                return {
                    ...initObj,
                    width:120,
                    render:(text, record, index)=> {
                        return (
                            <Tag color={text == 1 ? 'green' : 'red'}>{text == 1 ? '已启用' : '已禁用'}</Tag>

                        )
                    }
                }
            }else if(item=='action'){
                return {
                    ...initObj,
                    fixed:'right',
                    width:180,
                    render:(text, record, index)=>{
                        return (
                            <>
                                <Button type="link" size="small" onClick={()=>(this.editItemParam(record))}>编辑</Button>
                                <Popconfirm
                                    title="确定要删除该条数据吗？"
                                    onConfirm={()=>this.deleteItem(record)}
                                    okText="确认"
                                    cancelText="取消"
                                >
                                    <Button type="link" size="small">删除</Button>
                                </Popconfirm>
                            </>

                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        getAnnouncementList(this.state.searchParam).
        then(res=>{
            this.setState({
                loading: false,
                dataSource:res.list,
                total:res.total
            })
        })
    }
    editItemParam = (values)=>{
        console.log(values)
        let param = this.state.formParam
        this.setState({
            visible:true,
            formParam:{
                ...param,
                ...values
            }
        })
    }
    handleCopy = (text)=>{
        console.log(text)
        var input = document.createElement("textarea"); // 创建input对象
        input.value = text; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        message.success('已复制到剪贴板');
    }
    openSsoPage = (values)=>{
        console.log(values)
        window.open(`${window.location.protocol}${envHttpUrl().portalUrl}/portal/guanyuanpage?id=${values.id}`)
    }
    deleteItem = (values)=>{
        delSsoDataList({id:values.id}).then(res=>{
            message.success('删除成功')
            this.initTableDataList()
        })
    }
    handleCloseModel = ()=>{
        this.setState({
            visible:false,
            formParam:{}
        })
    }
    formValueChange = (changedValues, allValues)=>{
        console.log(changedValues,allValues)
        let param = this.state.formParam
        let changeKey = Object.keys(changedValues)[0]
        param[changeKey] = allValues[changeKey]
        this.setState({
            formParam:{
                ...param
            }
        })
    }
    handleSubmit = (values)=>{
        const {formParam} = this.state;
        saveAnnouncement({...formParam,...values}).
        then(res=>{
            notification.success({
                message: '操作成功',
            });
            this.handleCloseModel()
            this.initTableDataList()

        })
    }
    componentDidMount(){
        this.createColumns(columsKey);
        this.initTableDataList()
    }
    render() {
        const { dataSource,columns, loading,visible,formParam,systemItem} = this.state;
        return (
            <div>
                <Card title="公告/使用帮助 列表配置" bordered={false}>
                    <Button type="primary" style={{marginBottom:'20px'}} onClick={()=>this.editItemParam({id:null,status:1,tag:'更新'})}>新增</Button>

                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}/>
                    <div style={{display:'flex',justifyContent:'flex-end',margin:'20px 0'}}>
                        <Pagination size="small"
                                    hideOnSinglePage={true}
                                    current={this.state.searchParam.currentPage}
                                    pageSize={this.state.searchParam.pageSize}
                                    total={this.state.total}
                                    showSizeChanger
                                    onChange={(page, pageSize)=>{
                                        console.log(page, pageSize)
                                        let param = this.state.searchParam
                                        param.pageSize = pageSize
                                        param.currentPage = page
                                        this.setState({
                                            searchParam:{...param}
                                        })
                                        this.initTableDataList()
                                    }}
                        />

                    </div>
                </Card>
                <Modal
                    width={600}
                    title="新增/修改"
                    open={visible}
                    destroyOnClose={true}
                    onCancel={this.handleCloseModel}
                    footer={null}
                >
                    <Form
                        name="basic"
                        labelAlign="right"
                        preserve={false}
                        initialValues={formParam}
                        onFinish={this.handleSubmit}
                        onValuesChange={this.formValueChange}
                    >
                        <Form.Item
                            label="类型"
                            name="type"
                            rules={[{ required: true, }]}

                        >
                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                options={[
                                    {label:'公告',value:1},
                                    {label:'使用帮助',value:2},
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label="标题"
                            name="title"
                            rules={[{ required: true, message: '请输入标题' }]}
                        >
                            <Input placeholder={'请输入标题'}/>
                        </Form.Item>
                        <Form.Item
                            label="公告属性"
                            name="tag"
                            rules={[{ required: true, }]}

                        >
                            <Radio.Group>
                                <Space direction="vertical" size={14}>
                                    <Radio value={'更新'}>更新<span style={{fontSize:'10px',color:'#e50011'}}>（日常系统模块更新、优化等）</span></Radio>
                                    <Radio value={'系统'}>系统<span style={{fontSize:'10px',color:'#e50011'}}>（系统升级、系统维护等）</span></Radio>
                                    <Radio value={'上新'}>上新<span style={{fontSize:'10px',color:'#e50011'}}>（新功能、系统、模块上线）</span></Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="status"
                            rules={[{ required: true, }]}

                        >
                            <Radio.Group
                                options={[
                                    {label:'启用',value:1},
                                    {label:'禁用',value:0},
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label="跳转链接"
                            name="announcementLink"
                            extra={<span style={{fontSize:'10px',color:'#e50011'}}>* 可粘贴钉钉文档链接，没有跳转链接，不填</span>}
                        >
                            <Input placeholder={'请输入链接'} />
                        </Form.Item>
                        <Form.Item
                            label="排序"
                            name="sort"
                            extra={<span style={{fontSize:'10px',color:'#e50011'}}>* 数字越小越靠前</span>}
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item>
                            <div style={{display:'flex',justifyContent:'flex-end'}}>
                                <Button type="primary" htmlType="submit">
                                    提交
                                </Button>
                            </div>

                        </Form.Item>
                    </Form>
                </Modal>
            </div>

        )
    }
}