
//检测当前浏览器端
export class CurrBrowser {
    static get() {
        var browser = {
            versions: function() {
                var u = navigator.userAgent;
                var app = navigator.appVersion;
                return { //移动终端浏览器版本信息
                    trident: u.indexOf('Trident') > -1, //IE内核
                    presto: u.indexOf('Presto') > -1, //opera内核
                    webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
                    iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                    iPad: u.indexOf('iPad') > -1, //是否iPad
                    webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
                };
            }(),
            language: (navigator.browserLanguage || navigator.language).toLowerCase()
        }
        if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
            //return 'mobile';
            var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                //在微信中打开
                return 'WX';
            }
            if (ua.match(/WeiBo/i) == "weibo") {
                //在新浪微博客户端打开
            }
            if (ua.match(/QQ/i) == "qq") {
                //在QQ空间打开
            }
            if (browser.versions.ios) {
                //是否在IOS浏览器打开
                return 'ios WAP';
            }
            if (browser.versions.android) {
                //是否在安卓浏览器打开
                return 'android WAP';
            }
            return 'APP';
        } else {
            //否则就是PC浏览器打开
            return 'PC';
        }
    }
    static getPC() {
        var sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s;
        (s = ua.match(/edge\/([\d.]+)/)) ? sys.edge = s[1]:
            (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? sys.ie = s[1] :
                (s = ua.match(/msie ([\d.]+)/)) ? sys.ie = s[1] :
                    (s = ua.match(/firefox\/([\d.]+)/)) ? sys.firefox = s[1] :
                        (s = ua.match(/chrome\/([\d.]+)/)) ? sys.chrome = s[1] :
                            (s = ua.match(/opera.([\d.]+)/)) ? sys.opera = s[1] :
                                (s = ua.match(/version\/([\d.]+).*safari/)) ? sys.safari = s[1] : 0;
        if (sys.edge) return { broswer: "Edge", version: sys.edge };
        if (sys.ie) return { broswer: "IE", version: sys.ie };
        if (sys.firefox) return { broswer: "Firefox", version: sys.firefox };
        if (sys.chrome) return { broswer: "Chrome", version: sys.chrome };
        if (sys.opera) return { broswer: "Opera", version: sys.opera };
        if (sys.safari) return { broswer: "Safari", version: sys.safari };
        return { broswer: "", version: "0" };
    }
    static getNetWork() { // 获取网络状态
        var netWork;
        switch (navigator.connection.effectiveType) {
            case 'wifi':
                netWork = 'wifi'; // wifi
                break;
            case '4g':
                netWork = '4G'; // 4g
                break;
            case '2g':
                netWork = '2G'; // 2g
                break;
            case  '3g':
                netWork = '3G'; // 3g
                break;
            case  'ethernet':
                netWork = '以太网'; // ethernet
                break;
            case  'default':
                netWork = '未知'; // 未知
                break;
        }
        return netWork;
    }
    static getOS() { // 获取当前操作系统
        let platform = '';
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows
        if (userAgent.indexOf("Windows") != -1) {
            platform = "Windows";

            if (userAgent.indexOf("NT 10.0") != -1) {
                platform += " 10";
            }
            if (userAgent.indexOf("NT 6.2") != -1) {
                platform += " 8.1";
            }
            if (userAgent.indexOf("NT 6.1") != -1) {
                platform += " 7";
            }
            if (userAgent.indexOf("NT 6.0") != -1) {
                platform += " Vista";
            }
            if (userAgent.indexOf("NT 5.2") != -1) {
                platform += " XP";
            }
            if (userAgent.indexOf("NT 5.1") != -1) {
                platform += " XP";
            }
        }
        // Mac
        else if (userAgent.indexOf("Mac") != -1) {
            platform = "Mac";
        }
        // iOS
        else if (userAgent.indexOf("iPhone") != -1) {
            platform = "iPhone";
        } else if (userAgent.indexOf("iPad") != -1) {
            platform = "iPad";
        } else if (userAgent.indexOf("iPod") != -1) {
            platform = "iPod";
        }
        // Android
        else if (userAgent.indexOf("Android") != -1) {
            platform = "Android";
        }
        // Linux
        else if (userAgent.indexOf("Linux") != -1) {
            platform = "Linux";
        }

        return platform;
    }
    static getOSVersion() { // 获取操作系统版本
        var OSVision = '1.0';
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //Android
        var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
            OSVision = navigator.userAgent.split(';')[1].match(/\d+\.\d+/g)[0];
        }
        if (isIOS) {
            OSVision = navigator.userAgent.split(';')[1].match(/(\d+)_(\d+)_?(\d+)?/)[0];
        }
        return OSVision;
    }
    static getOSVersion1() { // 获取操作系统版本
        const userAgent = navigator.userAgent;
        let osVersion = '';

        // 示例正则表达式，用于解析iOS和Android操作系统版本
        if (/(iPhone|iPad|iPod);|(Macintosh)/.test(userAgent)) {
            osVersion = (/OS ([0-9_]+)/.exec(userAgent) || [])[1];
        } else if (/Android/.test(userAgent)) {
            osVersion = (/Android ([0-9.]+)/.exec(userAgent) || [])[1];
        }

        return osVersion;
    }
    static getOrientationStatus() { // 获取横竖屏状态
        var orientationStatu;
        if (window.screen.orientation.angle == 180 || window.screen.orientation.angle == 0) { // 竖屏
            orientationStatu = '竖屏';
        }
        if (window.screen.orientation.angle == 90 || window.screen.orientation.angle == -90) { // 横屏
            orientationStatu = '横屏';
        }
        return orientationStatu;
    }
    static getDeviceType() { // 获取设备类型
        var deviceType;
        var sUserAgent = navigator.userAgent.toLowerCase();
        var bIsIpad = sUserAgent.match(/(ipad)/i) == "ipad";
        var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
        var bIsMidp = sUserAgent.match(/midp/i) == "midp";
        var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
        var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
        var bIsAndroid = sUserAgent.match(/android/i) == "android";
        var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
        var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";

        if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
            deviceType = 'PC'; //pc
        } else if (bIsIphoneOs) {
            deviceType = 'iphone'; //iphone
        } else if (bIsIpad) {
            deviceType = 'ipad'; //ipad
        } else if (bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
            deviceType = 'Android'; //Android
        } else if (bIsMidp || bIsUc7 || bIsUc  || bIsCE || bIsWM) {
            deviceType = '移动端'; //phone
        } else {
            deviceType = '未知';
        }
        return deviceType;
    }
}



