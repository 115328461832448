import actionType from "./actionType";
import {loginRequest} from '../request'
const startLogin = ()=>{
    return{
        type:actionType.START_LOGIN
    }
}
const loginSuccess = (userInfo)=>{
    return({
        type:actionType.LOGIN_SUCCESS,
        payload:{
            userInfo
        }
    })
}
const loginFailed = ()=>{
    return({
        type:actionType.LOGIN_FAILED
    })
}

export const login = (userInfo)=>{
    return dispatch=>{
        dispatch(startLogin())
        loginRequest(userInfo).then(resp=>{
            window.localStorage.setItem('LOGIN_TOKEN',1)
            dispatch(loginSuccess())
        })
    }
}
