import React, {Component} from 'react'
import {
    Collapse,
    Divider,
    Button, Table,Tabs
} from 'antd';
import {getNotificationsResultData,getNotificationsMsgResultData} from '../../request'
import ExportJsonExcel from "js-export-excel";

import './index.less'
const { Panel } = Collapse;
export default class NotificationsSurveyForm extends Component {
    tableRef = React.createRef()
    state = {
        dataSource:[],
        formParam:{
            messageUserId:this.props.match.params.id,
        },
        messageInfo:{},
    }

    setResultData = (res)=>{
        let columnS = []
        let columnsHeader = []
        let {dataObj, ...messageInfo} = res
        let dataType = res.dataType
        let dataInfoObj = dataObj?dataObj:{}
        let dataSource = dataType=='list'?dataInfoObj:[dataInfoObj]
        let setColumn = dataSource[0]||{}
        for(let key in setColumn){
            if(key.indexOf('sys_')<0&&key.indexOf('hidden_')<0){
                let keyObj = {'TOOLS_CREATE_TIME':'创建时间','TOOLS_CREATE_PERSON':'创建人','TOOLS_SHEET_NAME':'sheet名称'}
                columnS.push({
                    dataIndex:key,
                    key,
                    title:keyObj.hasOwnProperty(key)?keyObj[key]:key,
                    render:(text)=>{
                        return <div dangerouslySetInnerHTML={{ __html: text }} ></div>
                    }
                })
                columnsHeader.push(key)
            }

        }

        this.setState({
            dataSource:dataSource.map(item=>{
                let url = item.url.indexOf('http:')>-1?window.location.protocol+item.url.split('http:')[1]:item.url
                item.url = url
                return item
            }),
            columns:columnS,
            columnsHeader:columnsHeader,
            dataType,
            messageInfo
        })
    }
    downloadTable = ()=>{
        // console.log(this.state.columnsHeader)
        // console.log(this.state.dataSource)
        let {messageTitle,createTime} = this.state.messageInfo
        let options = {
            fileName:`${messageTitle}(${createTime})`,
            datas:[
                {
                    sheetData:this.state.dataSource,
                    sheetName:'sheet',
                    sheetFilter:this.state.columnsHeader,
                    sheetHeader:this.state.columnsHeader,
                }
            ]
        }
        const toExcel = new ExportJsonExcel(options)
        toExcel.saveExcel()
    }
    componentDidMount(){
        const  locationQuery = new URLSearchParams(this.props.location.search)
        let id = this.props.match.params.id
        if(locationQuery.get('from')=='message'){
            getNotificationsMsgResultData({messageId:id}).then(res=>{
                this.setResultData(res)
            })
        }else{
            getNotificationsResultData({messageUserId:id}).then(res=>{
                this.setResultData(res)
            })
        }
    }
    render() {
        const {dataSource, columns,loading,messageInfo} = this.state
        return (
            <div className={'notifications-survey-form'}>
                <div style={{padding: '16px 16px 16px 20px',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{}}>
                        <h4 style={{fontSize:'18px',lineHeight:1}}>{messageInfo.messageTitle || '详情'}</h4>
                        <p style={{fontSize:'12px',lineHeight:1.4,color:'#999999',marginBottom:0}}>{messageInfo.messageHead || ''}</p>
                        <p style={{fontSize:'12px',lineHeight:1.4,color:'#999999',marginBottom:0}}>数据来源时间：{messageInfo.createTime || ''}</p>
                    </div>
                </div>

                <Divider style={{margin:0}} />
                <Tabs
                    style={{height:'calc(100% - 100px)'}}
                    tabPosition={'left'}
                    items={this.state.dataSource.map((item, i) => {
                        const id = String(i + 1);
                        return {
                            label: item['员工姓名'],
                            key: id,
                            children: <div style={{boxSizing:'border-box',height:'100%'}} id='erp-container'>
                                    <iframe style={{width:'100%',height:'100%',border:'none'}} src={item.url}></iframe>
                                </div>,
                        };
                    })}
                />

            </div>

        )
    }
}