import React, {Component, lazy, useState} from 'react'
import {
    Page,
    Survey
} from 'gd-sdk'
import {gyBiIframeLogin} from '../../request'
const locationQuery = new URLSearchParams(window.location.search)

export default class GuanyuanPage extends Component {
    state = {
        url:'',
        ssoId:locationQuery.get('id')||''
    }
    getSystemData = ()=>{
        gyBiIframeLogin({ssoId:this.state.ssoId}).then((res) => {
            let publicObj = {
                host: 'https://lechun.guandatacloud.com', // BI 地址
                domain: res.provider, // BI 公司域
                ssoToken: res.ssoToken, // BI SSOToken
            }
            let param = this.setFormParam(res)
            if(res.type=='form'){
                new Survey(
                    publicObj,
                    {
                        ps: param.ps, // 内嵌参数
                        container: document.getElementById('gy-container'), // 页面渲染容器
                        surveyId: param.id, // form Id，（用于生成 iframe 的 src）
                        query:{}
                    }
                )
            }else{
                new Page(
                    publicObj,
                    {
                        ps: param.ps, // 内嵌参数
                        container: document.getElementById('gy-container'), // 页面渲染容器
                        pgId: param.id, // 页面 Id，（用于生成 iframe 的 src）
                        query:{}
                    }
                )
            }

        })
    }
    setFormParam = (param)=>{
        let showTypeObj = {
            0:'iframe2',
            1:'iframe3',
            2:'iframe',
            3:'',
        }
        let showType = showTypeObj[param.showType]||''
        let fullUrl = param.url
        let sid = ''
        if(fullUrl.indexOf('/page')>-1){
            sid = fullUrl.split('/page/')[1]
        }else if(fullUrl.indexOf('/form-data')){
            sid = fullUrl.split('/form-data/')[1]
        }
        return {ps:showType,id:sid}
    }
    componentDidMount(){
        this.getSystemData()
    }
    render() {
        return (
            <div style={{boxSizing:'border',height:'100%'}} id='gy-container'></div>

        )
    }
}
