import React, {Component, useState} from 'react'
import {
        Page,
    Survey
} from 'gd-sdk'
import {gyBiIframeLogin} from '../../request'
const locationQuery = new URLSearchParams(window.location.search)

export default class GuanyuanForm extends Component {
    state = {
        url:'',
        from:locationQuery.get('from')
    }
    getSystemData = ()=>{
        gyBiIframeLogin().then((res) => {
            let publicObj = {
                host: 'https://lechun.guandatacloud.com', // BI 地址
                domain: res.provider, // BI 公司域
                ssoToken: res.ssoToken, // BI SSOToken
            }
            if(this.state.from=='form'){
                new Survey(
                    publicObj,
                    {
                        ps: 'iframe', // 内嵌参数
                        container: document.getElementById('gy-container'), // 页面渲染容器
                        surveyId: 'c3401c34-7978-49f4-aeb6-efdb01e509af', // 页面 Id，（用于生成 iframe 的 src）
                        query:{}
                    }
                )
            }else{
                new Page(
                    publicObj,
                    {
                        ps: 'iframe', // 内嵌参数
                        container: document.getElementById('gy-container'), // 页面渲染容器
                        pgId: 'c3401c34-7978-49f4-aeb6-efdb01e509af', // 页面 Id，（用于生成 iframe 的 src）
                        query:{}
                    }
                )
            }

        })
    }
    componentDidMount(){
        this.getSystemData()
    }
    render() {
        return (
            <div style={{boxSizing:'border',height:'100%'}} id='gy-container'></div>

        )
    }
}
