import React, {Component, lazy, useState} from 'react'
import { Page } from 'gd-sdk'

import {gyBiSystemLogin} from '../../request'
export default class GuanyuanBi extends Component {
    state = {
        systemData:[]
    }
    getSystemData = ()=>{

        gyBiSystemLogin().then((res) => {
            new Page(
                {
                    host: 'https://lechun.guandatacloud.com', // BI 地址
                    domain: res.provider, // BI 公司域
                    ssoToken: res.ssoToken, // BI SSOToken
                },
                {
                    ps: '', // 内嵌参数
                    container: document.getElementById('gy-container'), // 页面渲染容器
                    pgId: '', // 页面 Id，（用于生成 iframe 的 src）
                    query:{}
                }
            )
        })
    }
    toSystemPage = (item)=>{
        console.log(item)
        if(item.serviceId=='LECHUN-BI'){

        }else if(item.serviceId=='GUANYUAN-BI'){

        }else{
            window.open(item.resourceHtmlUrl,'_blank')
        }

    }
    componentDidMount(){
        this.getSystemData()
    }
    render() {
        return (
            <div style={{boxSizing:'border-box',height:'100%',paddingBottom:'10px'}} id='gy-container'>

            </div>

        )
    }
}
