import React, {Component,useState} from 'react'
import {PageHeader,} from 'antd';

import CalendarComponent from '../../components/Calendar/Calendar'
export default class CalendarPage extends Component {
    state = {
    }
    componentDidMount(){

    }
    render() {
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    title="日历"
                    subTitle=""
                />
                <CalendarComponent />

            </div>

        )
    }
}
