import React, {Component,useState} from 'react'
import {Form, Input, Button, Card, Modal} from 'antd';
import { UserOutlined, LockOutlined,InstagramOutlined,CodepenOutlined } from '@ant-design/icons';
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {getmd5} from '../../http/globalMethod'
import {login} from '../../actions/users'
import {getLoginImageValidateCode} from '../../request'

import './index.less'


const mapState = state=>({
    isLogin:state.users.isLogin
})
@connect(mapState,{login})
class Login extends Component {
    editForm = React.createRef()
    state = {
        imgCode:'',
        isModalOpen:false,
    }
    getCaptcha = ()=>{
        getLoginImageValidateCode({}).then(res=>{
            this.setState({
                imgCode:res.image||''
            })

        })
    }
    componentDidMount(){
        // if(!this.props.isLogin){
        //     window.location.href = 'http://csmslocal.lechun.cc:8090/login?auth=0'
        // }
        this.getCaptcha()
    }
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        }
    }
    showModal = () => {
        this.setState({
            isModalOpen:true
        })
    };
    getCode = ()=>{
        this.$apiGet('lechun-baseservice/auth/sendMobileValidateCode',{mobile:this.form.mobile,imgValidateCode:this.form.imgValidateCode}).then(res=>{
            console.log(res)
            this.countDown()
        })
    }
    countDown = ()=>{
        this.codeText = '重新获取59s'
        this.codeAble =  false
        let n = 59
        let setIn = setInterval(()=>{
            n--
            console.log(n)
            this.codeText = `重新获取${n}s`
            if(n<=0){
                clearInterval(setIn)
                this.codeAble = true
                this.codeText = '重新获取'
            }
        },1000)
    }
    render() {
        const onFinish = values => {
            this.props.login({ userName:values.username, passWord:getmd5(values.password),imageValidateCode:values.imageValidateCode})
        };

        const editSubmitFinish = () => {
            console.log(this.editForm)
            this.editForm.current.validateFields()
            console.log(this.editForm.current.getFieldsValue())
            // this.setState({
            //     isModalOpen:false
            // })
        };

        const closeModal = () => {
            // this.setState({
            //     isModalOpen:false
            // })
        };
        return (
            this.props.isLogin
            ?
            <Redirect to="/admin" />
            :
            <div className="login-wrap">
                <Card title="乐纯AMS管理系统"
                      headStyle={{textAlign:"center"}}
                      className="login-form"
                      bordered={false}>
                <Form
                    name="normal_login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: '用户名不能为空!' }]}
                    >
                        <Input style={{height:'38px'}} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入用户名" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: '密码不能为空!' }]}
                    >
                        <Input  style={{height:'38px'}}
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="请输入密码"
                        />
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom:'5px'}}
                        name="imageValidateCode"
                        rules={[{ required: true, message: '验证码不能为空!' }]}
                    >
                            <Input  style={{height:'38px'}}
                                prefix={<InstagramOutlined className="site-form-item-icon" />}
                                placeholder="请输入验证码"
                                suffix={
                                    <img onClick={this.getCaptcha} style={{width: "auto",height: "100%",display: "block"}} src={this.state.imgCode}/>
                                }
                            />
                    </Form.Item>
                    <Form.Item  style={{marginBottom:'5px'}}>
                        <Button style={{float:'right'}} type="text" onClick={this.showModal}>忘记密码{this.state.isModalOpen}</Button>

                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
                </Card>
                <Modal title="修改密码" open={this.state.isModalOpen}
                       footer={[
                           <Button key="submit" type="primary" onClick={editSubmitFinish}>
                               Submit
                           </Button>
                       ]}
                       onCancel={closeModal}>
                    <Form name="edit_password"
                          ref={this.editForm}
                          onFinish={this.editFinish}
                          labelCol={{ span: 4 }}
                          wrapperCol={{ span: 20 }}>
                        <Form.Item
                            label="手机号"
                            name="mobile"
                            rules={[{ required: true, message: '手机号不能为空!' }]}
                        >
                            <Input style={{height:'38px'}} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入手机号" />
                        </Form.Item>
                        <Form.Item
                            label="图片验证"
                            name="imgValidateCode"
                            rules={[{ required: true, message: '图片验证不能为空!' }]}
                        >
                            <Input  style={{height:'38px'}}
                                    prefix={<InstagramOutlined className="site-form-item-icon" />}
                                    placeholder="请输入图片验证码"
                                    suffix={
                                        <img onClick={this.getCaptcha} style={{width: "auto",height: "100%",display: "block"}} src={this.state.imgCode}/>
                                    }
                            />
                        </Form.Item>
                        <Form.Item
                            label="验证码"
                            name="validateCode"
                            rules={[{ required: true, message: '验证码不能为空!' }]}
                        >
                            <Input  style={{height:'38px'}}
                                    prefix={<CodepenOutlined className="site-form-item-icon" />}
                                    placeholder="请输入验证码"
                                    addonAfter={
                                        <span onClick={this.getCaptcha} style={{width: "auto",height: "100%",display: "block"}} src={this.state.imgCode}>获取验证码</span>
                                    }
                            />
                        </Form.Item>
                        <Form.Item
                            label="新密码"
                            name="pwd"
                            rules={[{ required: true, message: '密码不能为空!' }]}
                        >
                            <Input  style={{height:'38px'}}
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="请输入密码"
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}
export default Login