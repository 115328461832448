import React, {Component} from 'react'
import CalendarComponent from '../../components/Calendar/Calendar'
import {
    Alert,
    Card,
    Empty,
    Row,
    Col,
    Statistic,
    Button,
    Typography,
    Tabs,
    List,
    Badge,
    Space,
    message,
    Tag
} from "antd";
import * as Icon from "@ant-design/icons";
import {getCalendarDataList, getNotificationsList, handleLinkNotifications, handleNotifications} from "../../request";

import * as Dayjs from 'dayjs'
import SuggestModal from "../../components/OA/suggestModal";
class OADashboard extends Component {
    suggestModalRef = React.createRef()
    state = {
        formParam: {
            messageSource: 'lechun-oa',
            pageSize: 2000,
            pageNum: 1
        },
        activeKey:1,
        total: 0,
        notificationsList: [],
        dateList: [],
        noticeList:[
            {
                state:'更新',
                title:'系统Dashboard模块更新'
            },
            {
                state:'系统',
                title:'OA系统上线啦！'
            },
        ],
        helpsList:[
            {
                type:'link',
                title:'如何快速上手OA系统',
                url:'https://alidocs.dingtalk.com/i/nodes/ZgpG2NdyVXrL7DZbCXoMmkQY8MwvDqPk?utm_scene=person_space'
            },{
                type:'modal',
                title:'反馈及建议',
                url:''
            },
        ]
    }
    initMessage = () => {
        let curDate = Dayjs(new Date()).format('YYYY-MM-DD')
        getCalendarDataList({date: curDate}).then(res => {
            res.map(item => {
                if (curDate == item.date) {
                    this.setState({
                        dateList: item.list
                    })
                }
            })
        })
        getNotificationsList({...this.state.formParam}).then(res => {
            console.log(res)
            this.setState({
                notificationsList: res.list,
                total: res.total
            })
        })
    }
    handleMessage = (item,nId)=>{
        console.log(item)
        handleNotifications({messageUserId:nId,actionId:item.id}).then(res=>{
            if(item.buttonAction==1){
                window.open(item.buttonUrl,'_blank')
            }else if(item.buttonAction==2){
                handleLinkNotifications(item.buttonUrl).then(res1=>{
                    message.success('success')
                })
            }

        })
    }
    handleOpenHelp = (item)=>{
        if(item.type=='link'){
            window.open(item.url,'_blank')
        }else if(item.type=='modal'){
            console.log(this.suggestModalRef)
            this.suggestModalRef.current.handleOpenModal()
        }

    }
    componentDidMount() {
        // console.log(Dayjs(new Date()).format('YYYY'))
        this.initMessage()
    }

    render() {
        return (
            <div>
                <Alert
                    style={{border: "none", background: '#1890ff', borderBottom: '10px solid #efefef'}}
                    message={<span style={{color: "#fff"}}>Hi，欢迎使用乐纯OA系统！</span>}
                    description={
                        <div style={{
                            color: "#fff",
                            marginTop: "15px"
                        }}>新人入职后，leader制定考核目标，试用期期间每月填写完成情况及自己评分&leader评分，该系统通过线上流程实现改善数据的可统计性，各个节点实时推送消息提醒。</div>
                    }
                    type="success"
                    icon={<Icon.SmileOutlined/>}
                    showIcon
                    closable
                />
                <Row gutter={10} style={{background:'#efefef'}}>
                    <Col xs={24} sm={18} style={{marginBottom: '10px',height: '100%'}}>
                        <Card style={{height: '100%',overflowY:'scroll'}} size={'small'} >
                            <Tabs
                                activeKey={this.state.activeKey}
                                defaultActiveKey="1"
                                onChange={(key)=>{
                                    this.setState({
                                        activeKey:key
                                    })
                                }}
                                items={
                                    [
                                        {
                                            key: 1,
                                            label: 'OA系统消息',
                                            children: <div style={{height: '500px', overflowY: 'scroll'}}>
                                                <List
                                                    size="small"
                                                    dataSource={this.state.notificationsList}
                                                    renderItem={(item) => (
                                                        <List.Item key={item.email}>
                                                            <List.Item.Meta
                                                                title={
                                                                    <Badge status="processing" color="green" text={
                                                                        <span style={{
                                                                            color: '#666',
                                                                            fontSize: '14px'
                                                                        }}>{item.messageHead}</span>
                                                                    }/>
                                                                }
                                                                description={
                                                                    <span style={{
                                                                        paddingLeft: '15px',
                                                                        color: '#999',
                                                                        fontSize: '12px'
                                                                    }}>时间：{item.messageTime}</span>
                                                                }
                                                            />
                                                            {
                                                                item.actions.length > 0 ?
                                                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                                        <Space>
                                                                            {
                                                                                item.actions.map(item1 => {
                                                                                    return <Button key={item1.messageUserId}
                                                                                                   onClick={() => this.handleMessage(item1, item.messageUserId)}
                                                                                                   size={'small'} type={'primary'}
                                                                                                   style={{fontSize: '12px'}}>{item1.buttonText}</Button>
                                                                                })
                                                                            }
                                                                        </Space>

                                                                    </div>
                                                                    : ''

                                                            }
                                                        </List.Item>
                                                    )}
                                                />
                                            </div>
                                        },
                                    ]
                                }
                            />

                        </Card>
                    </Col>

                    <Col xs={24} sm={6} style={{width: '200px', display:'flex',flexDirection:'column'}}>
                        <Card size={'small'} title="公告" style={{marginBottom:'10px',flex:1}}>
                            {
                                this.state.noticeList.map((item,index) => {
                                    return <p key={index} style={{display:'flex'}}><Tag>{item.state}</Tag>
                                        <Typography.Text
                                        ellipsis={{
                                            tooltip: item.title,
                                        }}>{item.title}</Typography.Text></p>
                                })
                            }
                        </Card>
                        <Card size={'small'} title="使用帮助" style={{flex:1,marginBottom:'10px'}}>
                            {
                                this.state.helpsList.map((item,index) => {
                                    return <p key={index} style={{cursor: 'pointer', color: '#1890ff'}} onClick={() => this.handleOpenHelp(item)}>{item.title}</p>
                                })
                            }
                        </Card>
                    </Col>
                </Row>
                <SuggestModal ref={this.suggestModalRef} />
            </div>
        )
    }
}
export default OADashboard